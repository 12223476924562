import React, { useState } from 'react';
import Upload from './upload';
import TrainingPlan from '../TrainingPlan/TrainingPlanGrid';
import AdminNavbar from '../AdminNavbar/AdminNavbar';


export const EmptyGrid = ({ tpCount }) => {
    const [upload, setUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editKey, setEditKey] = useState(1);
    const [kbId, setKbId] = useState(0);
    const [tpOpen, setTpOpen] = useState(false)

    const changeUpload = () => {
        setUpload(false);
    };

    const loadingTrue = () => {
        setLoading(true);
    };

    const loadingFalse = () => {
        setLoading(false);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="common-card-theme-bg px-4 py-4 mt-3 mb-3 rounded-3">
                            {/* <div class="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3">
                                <h5 class="font-20 font-semibold mb-0 primary-color">Training Plan (2)</h5>
                                <button
                                    class="btn primary-btn text-nowrap small-device-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4">
                                    <img src="images/upload-icon.svg" alt="upload-icon" />Upload
                                </button>
                            </div> */}
                            {upload ? (
                                <div className="alert-container alert-container-bg custom-border rounded-3 mb-3 p-3">
                                    <div className="d-flex">

                                        <div className="d-flex gap-3 align-items-start">
                                            <img
                                                src="images/exclamatory-icon-orange.svg"
                                                alt="folder-icon"
                                                className="pt-1"
                                            />
                                            <div>
                                                <p className="font-medium font-16 primary-color mb-2">
                                                    Upload Training Plan
                                                </p>
                                                <p className="font-regular font-14 secondary-color mb-3">
                                                    Please try to upload training plan before uploading the knowledge base.
                                                </p>
                                                <p className="font-regular fit-content font-14 mb-0">
                                                    <a href="#" className="link-text text-decoration-none" onClick={() => setTpOpen(true)}>
                                                        Upload Training Plan
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : <></>}
                            <div className="col-md-12 empty-container py-5 custom-border shadow-sm rounded-2 text-center">
                                <img
                                    src="images/no-record-icon.svg"
                                    alt="three-lines-icon"
                                    className="pb-4"
                                />
                                <p className="font-14 font-medium primary-color mb-1">
                                    No knowledge base uploaded!
                                </p>
                                <p className="font-12 font-medium secondary-color mb-2">
                                    No knowledge base found at the moment
                                </p>
                                <button
                                    type="button"
                                    className="border-0 common-bg btn-upload link-text font-12 font-medium cursor-pointer"
                                    onClick={() => {
                                        setUpload(true);
                                        setEditKey(1);
                                    }}
                                >
                                    Upload Knowledge Base
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AdminNavbar activity={activeTab} setActivity={setActiveTab}>  </AdminNavbar> */}
            {!tpCount &&
                upload && (
                    <Upload
                        setShowUpload={setUpload}
                        editKey={editKey}
                        kbId={kbId}
                        displayChange={changeUpload}
                        loadingUpload={loadingFalse}
                        loadChange={loadingTrue}
                    />
                )}
            {tpOpen ? <TrainingPlan></TrainingPlan> : <></>}
            {/* {tpOpen ? (activeTab['traingPlan'] == 'active' ? <TrainingPlan></TrainingPlan> : <></>) : <></>} */}
        </>
    );
};


