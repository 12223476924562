import { adminEndpoints } from '../../Constant/constant'
import { client } from '../../Client/client'

let loginEndpoint = adminEndpoints.loginEndpoint

export const getAdminJwt = async (body) => {
  const jwt = localStorage.getItem('jwt')
  console.log("getAdminjwt entered", body);
  const requestBody = {
    "jwt":jwt,
    "endpoint": adminEndpoints.loginEndpoint.getAdminJwt,
    "request_data": body
  }
  let response = await client(loginEndpoint.getAdminJwt, requestBody, 'POST', 'admin')
  console.log(response, "resposnse logn");
  return response
}

export const getUpdateJwt = async (body) => {
  const jwt = localStorage.getItem('jwt')
  const requestBody = {
    "jwt":jwt,
    "endpoint": adminEndpoints.LogoutEndpoints.getUpdateJwt,
    "request_data": body
  }
  let response = await client(loginEndpoint.getUpdateJwt, requestBody, 'POST', 'admin')
  console.log(response, "resposnse logn");
  return response
}