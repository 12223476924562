import { client } from '../../Client/client';
import { traineeEndpoints, traineeBaseUrl } from '../../Constant/constant';

export async function fetchTrainingList(body) {
  const jwt = localStorage.getItem('jwt')
  const requestBody = {
    "jwt": jwt,
    "endpoint": traineeEndpoints.TrainingPlanEndpoints.fetchTrainingList,
    "request_data": body
  }
  console.log(requestBody,"RequestBody");
  let response = await client(traineeBaseUrl, requestBody, "POST", 'intern')
  let result = (response)
  return result.data
}

export async function setUserSessionMapping(body) {
  const jwt = localStorage.getItem('jwt')
  const requestBody = {
    "jwt": jwt,
    "endpoint": traineeEndpoints.TrainingPlanEndpoints.insertUserSessionMapping,
    "request_data": body
  }
  console.log(requestBody,"RequestBody");
  let response = await client(traineeBaseUrl, requestBody, "POST", 'intern')
  let result = (response)
  return result.data
}

export async function getClass(body) {
  const jwt = localStorage.getItem('jwt')
  const requestBody = {
    "jwt": jwt,
    "endpoint": traineeEndpoints.TrainingPlanEndpoints.getClass,
    "request_data": body
  }
  console.log(requestBody,"RequestBody");
  let response = await client(traineeBaseUrl, requestBody, "POST", 'intern')
  let result = (response)
  return result.data

}

// Pseudo code number PC_LFS3_1.58 TO PC_LFS3_1.61
// This code defines an asynchronous function named getQuestionTemplate responsible for making a POST request to the Trainee.questionTemplate endpoint with the provided Body data. If the request is successful, it returns the response.
export async function getQuestionTemplate(Body) {
  try {  
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ChatBotEndpoints.questionTemplate,
      "request_data": Body
    }
    console.log(requestBody,"RequestBody");
    let response = await client(traineeBaseUrl, requestBody, "POST", 'intern');
    return response;
  } catch (error) {
    let reqBoby = {
      errorDescription: "",
      errorSource: "getQuestionTemplate Function (api.js)",
      errorType: error,
    };
    let response = await client(reqBoby);
    return response;
  }
}

// Pseudo code number PC_LFS3_1.40 TO PC_LFS3_1.44
// This function is responsible for making a POST request to the Trainee.userInteraction endpoint with the provided Body data. 
// It uses the client function to handle the request. If the request is successful, it returns the response. 
// If an error occurs during the request, it catches the error, constructs an error object containing details such as the error description, source, and type, and then sends this error object to another endpoint using the insertErrorData function. Finally, it returns the response received from the insertErrorData function.
export async function getUserInteraction(Body) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ChatBotEndpoints.userInteraction,
      "request_data": Body
    }
    console.log(requestBody,"RequestBody")
    let response = await client(traineeBaseUrl, requestBody, "POST", 'intern');
    return response;
  } catch (error) {
    let reqBoby = {
      errorDescription: "",
      errorSource: "getQuestionTemplate Function (api.js)",
      errorType: error,
    };
    let response = await client(reqBoby);
    return response;
  }
}

export async function insertErrorData(Body) {
  const jwt = localStorage.getItem('jwt')
  const requestBody = {
    "jwt": jwt,
    "endpoint": traineeEndpoints.ChatBotEndpoints.insertErrorData,
    "request_data": Body
  }
  console.log(requestBody,"RequestBody");
  await client(traineeBaseUrl, requestBody, "POST", 'intern');
}

export async function validateAnswerForReview(reqBoby) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ReviewComponentEndpoints.validateAnswerForReview,
      "request_data": reqBoby
    }
    console.log(requestBody,"RequestBody");

    let response = await client(traineeEndpoints.ReviewComponentEndpoints.validateAnswerForReview, requestBody, "POST", 'intern')
    return response;

  } catch (error) {
    let reqBoby = {
      errorDescription: "Front Review component",
      errorSource: "validateAnswerForReview Function (api.js)",
      errorType: error,
      status: 400
    };
    // let response = await client(reqBoby);
    return reqBoby;
  }
}

export async function validatePracticeAnswerForReview(reqBoby) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ReviewComponentEndpoints.validatePracticeAnswerForReview,
      "request_data": reqBoby
    }
    console.log(requestBody,"RequestBody");

    let response = await client(traineeBaseUrl, requestBody, "POST", 'intern')
    return response;

  } catch (error) {
    let reqBoby = {
      errorDescription: "Front Review component",
      errorSource: "validateAnswerForReview Function (api.js)",
      errorType: error,
      status: 400
    };
    // let response = await client(reqBoby);
    return reqBoby;
  }
}

export async function fetchQuestionForReview(reqBoby) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ReviewComponentEndpoints.fetchQuestionForReview,
      "request_data": reqBoby
    }
    console.log(requestBody,"RequestBody");

    let response = await client(traineeEndpoints.ReviewComponentEndpoints.fetchQuestionForReview, requestBody, "POST", 'intern')
    return response;

  } catch (error) {
    let reqBoby = {
      errorDescription: "Front Review component",
      errorSource: "fetchQuestionForReview Function (api.js)",
      errorType: error,
    };
    // let response = await client(reqBoby);
    return;
  }
}

export async function fetchPracticeQuestionForReview(reqBoby) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ReviewComponentEndpoints.fetchPracticeQuestionForReview,
      "request_data": reqBoby
    }
    console.log(requestBody,"RequestBody");

    let response = await client(traineeBaseUrl, requestBody, "POST", 'intern')
    return response;

  } catch (error) {
    let reqBoby = {
      errorDescription: "Front Review component",
      errorSource: "fetchQuestionForReview Function (api.js)",
      errorType: error,
    };
    // let response = await client(reqBoby);
    return;
  }
}

export async function storeReviewDetails(reqBoby) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt": jwt,
      "endpoint": traineeEndpoints.ReviewComponentEndpoints.storeReviewDetails,
      "request_data": reqBoby
    }
    console.log(requestBody,"RequestBody");

    let response = await client(traineeEndpoints.ReviewComponentEndpoints.storeReviewDetails, requestBody, "POST", 'intern')
    return response;

  } catch (error) {
    let reqBoby = {
      errorDescription: "Front Review component",
      errorSource: "getQuestionTemplate Function (api.js)",
      errorType: error,
    };
    // let response = await client(reqBoby);
    return;
  }
}

export async function getTraineeJwt(reqBoby) {
  try {
    const jwt = localStorage.getItem('jwt')
    const requestBody = {
      "jwt":jwt,
      "endpoint": traineeEndpoints.LoginEndpoints.generateTraineeJwt,
      "request_data": reqBoby
    }
    console.log(requestBody,"RequestBody");

    let response = await client(traineeEndpoints.LoginEndpoints.generateTraineeJwt, requestBody, "POST", 'intern')
    return response;

  } catch (error) {
    let reqBoby = {
      errorDescription: "Front Review component",
      errorSource: "getQuestionTemplate Function (api.js)",
      errorType: error,
    };
    // let response = await client(reqBoby);
    return;
  }
}

export const getUpdateJwt = async (body) => {
  const jwt = localStorage.getItem('jwt')
  const requestBody = {
    "jwt":jwt,
    "endpoint": traineeEndpoints.LogoutEndpoints.getUpdateJwt,
    "request_data": body
  }
  console.log(requestBody,"RequestBody");

  let response = await client(traineeEndpoints.LogoutEndpoints.getUpdateJwt, requestBody, 'POST', 'intern')
  return response
}