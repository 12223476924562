import {adminEndpoints} from '../../Constant/constant'
import {client} from '../../Client/client'

 
export const GetBatchNameData = async (body={}) =>{
  const jwt = localStorage.getItem('jwt')
  console.log(jwt,"dnwjnckjnvkjsfvnkfsvjf");
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.overviewEndpoints.GetBatchNameData,
        "request_data":body
      }
    let response = await client(adminEndpoints.overviewEndpoints.GetBatchNameData,requestBody ,'POST','admin')
    return response.data
}
 
export const GetBatchDashboardData= async (body={})=>{
  const jwt = localStorage.getItem('jwt')
console.log(jwt,"dnwjnckjnvkjsfvnkfsvjf");
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.overviewEndpoints.GetBatchDashboardData,
        "request_data":body
      }
    let response =await client(adminEndpoints.overviewEndpoints.GetBatchDashboardData,requestBody ,'POST','admin')
    return response.data
}
 
export const GetSubBatchDashboardData =async (body={})=>{
  const jwt = localStorage.getItem('jwt')
console.log(jwt,"dnwjnckjnvkjsfvnkfsvjf");

    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.overviewEndpoints.GetSubBatchDashboardData,
        "request_data":body
      }
    let response =await client(adminEndpoints.overviewEndpoints.GetSubBatchDashboardData,requestBody ,'POST','admin')
    return response.data
}