import React, { useState, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { Modal, Button } from 'react-bootstrap';
import { GetBatchNameData, GetBatchDashboardData, GetSubBatchDashboardData } from '../../../Service/AdminApi/overViewApi';
import Loader from '../KnowledgeBase/loader';
import ToastComponent from './ToastComponent';

// PC_LFS_DB_4 - PC_LFS_DB_109 : This Dashboard component manages various states related to batch and sub-batch data, recent grades, risked trainees, questions, and graphs.It maintains states for batch and sub-batch names, data, IDs, and selections.It fetches batch names on component mount and updates batch and sub-batch data based on user selections.
export const DashboardComponent = () => {
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [batchName, setBatchName] = useState([]);
  const [subBatchName, setSubBatchName] = useState([])
  const [batchData, setBatchData] = useState([]);
  const [subBatchData, setSubBatchData] = useState([]);
  const [batchId, setBatchId] = useState("0");
  const [subBatchId, setSubBatchId] = useState("0");
  const [batchSelect, setBatchSelect] = useState('')
  const [subBatchSelect, setSubBatchSelect] = useState('')
  const [recentGrade, setRecentGrade] = useState([])
  const [riskee, setRiskeee] = useState([])
  const [question, setQuestion] = useState([])
  const [graph, setGraph] = useState([])
  const [data, setData] = useState(graph)
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  let gradesRow = 0
  // this function is for calling Api for fetching the batchname it will be called in useEffect
  const fetchBatchNames = async () => {
    try {
      const result = await GetBatchNameData();
      setBatchName(result.message.BatchNameList)
      setBatchSelect(result.message.BatchNameList[0].batch_name)
      batchDropDownClick(result.message.BatchNameList[0].batch_id)
      setBatchId(result.message.BatchNameList[0].batch_id)
    }
    catch (e) {
      setLoading(false)
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  }

  // IN useEffect call the fetchBatchNames with empty dependency
  useEffect(() => {
    fetchBatchNames();
  }, [])

  // in useEffect call the displaySubbatchName in dependency set the batchName state variable
  // useEffect(() => {
  //   displaySubbatchName()
  // }, [batchName])

  /**PS_AG_15
      * This function is used to show and hide the toast message
      * hideToast state variable is passed as the dependency
      */
  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 2000);
  }, [hideToast]);

  // PC_LFS_DB_114 -PC_LFS_DB_117 This displayBatchName function generates JSX elements to render batch names as clickable spans. It maps through the batchName array and creates a span element for each batch name. When clicked, it sets the selected batch name using the setBatchSelect function.
  const displayBatchName = () => {
    if (!batchName || batchName.length === 0) {
      return <span className="mt-2 font-regular font-14 mandatory d-flex align-items-center"><img src="images/error-msg.svg" alt="Error" className="me-2 mb-1" ></img>No Sub batch found</span>;
    }
    return (
      <>
        {batchName?.map((element, index) => (
          <div id={element.batch_id} onClick={(e) => {
            setBatchSelect(element.batch_name)
            setBatchId(e.target.id)
            batchDropDownClick(e.target.id)
            setSubBatchSelect("")
          }} >
            <span id={element.batch_id} style={{ cursor: 'pointer' }} className="d-flex list cursor-pointer p-2 primary-color">{element.batch_name}</span>
          </div>
        ))}</>
    );
  };

  //  PC_LFS_DB_118 - PC_LFS_DB_121 this function is for display the subbatch name in dashboard dropdown it returns the HTML content
  // @@ call the subBatchDropdownClick for triggering when subbatch is clicked and passing the subbatchid as params @@
  const displaySubbatchName = () => {
    if (!subBatchName || subBatchName.length === 0) {
      return <span className="mt-2 font-regular font-14 mandatory d-flex align-items-center"><img src="images/error-msg.svg" alt="Error" className="me-2 mb-1" ></img>No Sub batch found</span>;
    }
    return (<>
      {subBatchName?.map((element, index) => (
        <div id={element.sub_batch_id} onClick={(e) => {
          setSubBatchSelect(element.sub_batch_name)
          setSubBatchId(e.target.id)
          subbatchDropDownClick(e.target.id)
        }} >
          <span id={element.sub_batch_id} style={{ cursor: 'pointer' }} className="d-flex list text-break cursor-pointer p-2 primary-color" >{element.sub_batch_name}</span>
        </div>
      ))}
    </>)


  }

  //PC_LFS_DB_118 - PC_LFS_DB_125  this function is called inside the batchDropdown click and it will send the requestbody of batchid to the API.js function  GetBatchDashboardData by passing reqBody
  // for fetching Batchdashboard data from backend
  // aftyer receiving the response set the subBatchName RecentGrade,Riskee trainee,Topquestion,ChartGrade in respective state variable

  const GetBatchDashboard = async (batchId) => {
    try {
      let reqBody = {
        "batchId": batchId
      }
      const result = await GetBatchDashboardData(reqBody)
      setLoading(false)
      setBatchData(result)
      setSubBatchName(result.message.subBatchName)
      setRecentGrade(result.message.recentGrade)
      setRiskeee(result.message.RiskeeTrainee)
      setGraph(() => (result.message.chartGrade))
      setQuestion(result.message.topQuestion)
    }
    catch (e) {
      setLoading(false)
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }


  }

  //PC_LFS_DB_126 - PC_LFS_DB_127  this function is called inside the displaybatchName by passing batchid as params in this call the GetBatchDashboard by passing batchid as arguments 
  const batchDropDownClick = (batchId) => {
    setLoading(true)
    GetBatchDashboard(batchId)
  }

  //PC_LFS_DB_128 - PC_LFS_DB_135 this function is called inside the subbatchDropdown click and it will send the requestbody of batchId and subbatchid to the API.js function  GetsubBatchDashboardData by passing reqBody
  // for fetching subBatchdashboard data from backend
  // after receiving the response set the  RecentGrade,Riskee trainee,Topquestion,ChartGrade in respective state variable

  const GetSubBatchDashboard = async (subBatchId) => {
    try {
      let reqBody = {
        "batchId": batchId,
        "subBatchId": subBatchId
      }
      const result = await GetSubBatchDashboardData(reqBody)
      setLoading(false)
      if (result.statuscode != 200) {
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
      }
      if (result.statuscode == 200) {
        setSubBatchData(result.message)
        setRecentGrade(result.message.recentGrade)
        setRiskeee(result.message.RiskeeTrainee)
        setGraph(result.message.chartGrade)
        setQuestion(result.message.topQuestion)
      }

    }
    catch (e) {
      setLoading(false)
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  }

  //PC_LFS_DB_136 -PC_LFS_DB_137 this function is called inside the displaybatchName by passing subbatchid as params in this call the GetsubBatchDashboard by passing subbatchid as arguments 
  const subbatchDropDownClick = (subBatchId) => {
    setLoading(true)
    GetSubBatchDashboard(subBatchId)
  }

  const displayrecentGrade = () => {
    const getGradeColorClass = (grade) => {
      switch (grade) {
        case 'A+':
        case 'A-':
          return 'custom-badge-green';
        case 'B+':
        case 'B-':
          return 'custom-badge-orange';
        case 'C+':
        case 'C-':
          return 'custom-badge-red';
        default:
          return '';
      }
    };
    if (recentGrade != undefined && recentGrade.length != 0) {
      return (
        <div className="row">
          {recentGrade?.map((item, index) => {
            if (index === 0 || index === 2 || index === 4) {
              return (
                <div className='col-md-4' key={index}>
                  <div className='row pe-4 mb-3 mb-md-0'>
                    <div className="col-6">
                      <span className={`custom-grade-badge ${getGradeColorClass(item.review_grade)}`}>
                        {item.review_grade}
                      </span>
                      <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                        {item.grade_count}
                      </p>
                    </div>
                    {recentGrade[index + 1] && (
                      <div className="col-6">
                        <span className={`custom-grade-badge ${getGradeColorClass(recentGrade[index + 1].review_grade)}`}>
                          {recentGrade[index + 1].review_grade}
                        </span>
                        <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                          {recentGrade[index + 1].grade_count}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-4">
            <div className="row pe-4 mb-3 mb-md-0">
              <div className="col-6">
                <span className="custom-grade-badge custom-badge-green font-12 font-medium">
                  A+
                </span>
                <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                  0
                </p>
              </div>
              <div className="col-6">
                <span className="custom-grade-badge custom-badge-green font-12 font-medium">
                  A-
                </span>
                <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row pe-4 mb-3 mb-md-0">
              <div className="col-6">
                <span className="custom-grade-badge custom-badge-orange font-12 font-medium">
                  B+
                </span>
                <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                  0
                </p>
              </div>
              <div className="col-6">
                <span className="custom-grade-badge custom-badge-orange font-12 font-medium">
                  B-
                </span>
                <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row pe-4">
              <div className="col-6">
                <span className="custom-grade-badge custom-badge-red font-12 font-medium">
                  C+
                </span>
                <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                  0
                </p>
              </div>
              <div className="col-6">
                <span className="custom-grade-badge custom-badge-red font-12 font-medium">
                  C-
                </span>
                <p className="mb-0 font-24 font-semibold mt-2 primary-color">
                  0
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  //PC_LFS_DB_149 - PC_LFS_DB_154 Map the wqeustions to the html using the state varibale question Within each list item, it displays the question number, user message, and topic.
  const displayTopQuestion = () => {
    if (question == undefined || question.length == 0 || question == "No Questions Found" || question == "Token Length Exceed") {
      return (
        <div className="questions-card-height d-flex flex-column align-items-center justify-content-center">
          <span>
            <img
              src="images/ques-icon-grey.svg"
              className="mb-3"
              alt="No-records-found"
            />
          </span>
          <p className="mb-0 font-medium font-18 primary-color">
            No questions asked !
          </p>
          <p className="mb-0 font-regular font-12 secondary-color">
            No questions were asked at the moment
          </p>
        </div>
      );
    }

    else {
      return (
        <ul className='ps-0 pe-3'>
          {question?.map((item) => (
            <li className="list-style-none d-flex align-items-start gap-3 py-3 pb-0">
              <span className="question-legend font-12 font-medium primary-color">
                {item.number}
              </span>
              <div className="d-flex dashboard-border-bottom justify-content-between pb-3 w-100">
                <p className="mb-0 font-14 custom-questions-width font-regular primary-color">
                  {item.user_message}
                </p>
                <span className="custom-grade-badge course-badge-pink font-12 font-medium d-inline-flex">{item.topic}
                </span>
              </div>
            </li>
          ))}
        </ul>
      );
    }
  }

  // PC_LFS_DB_155 - PC_LFS_DB_160 The displayRiskeeTrainee function generates HTML elements to display information about riskee trainees.It returns a tbody element containing multiple tr elements, each representing a trainee.Within each row, it displays the trainee's profile image, name, domain, and average grade.The information is extracted from the riskee array, which contains objects representing trainees and their details.
  const displayRiskeeTrainee = () => {
    if (riskee == 0 || riskee == null || riskee == []) {
      return (
        <div className="custom-cards-height d-flex flex-column align-items-center justify-content-center">
          <span>
            <img
              src="images/no-results-found-grey.svg"
              className="mb-3"
              alt="No-records-found"
            />
          </span>
          <p className="mb-0 font-medium font-18 primary-color">
            No records found
          </p>
          <p className="mb-0 font-regular font-12 secondary-color">
            No records were found at the moment
          </p>
        </div>
      );
    }

    return (
      <table className="table dashboard-table">
        <thead className="sticky-top">
          <tr className="border-bottom">
            <th className="font-12 font-semibold primary-color">
              Employee Info
            </th>
            <th className="font-12 font-semibold text-center primary-color">
              Overall Grade
            </th>
          </tr>
        </thead>
        <tbody>
          {riskee?.map((item) => (
            <tr>
              <td>
                <div className="d-flex align-items-center">
                  {/* <img
                    src="images/sample-profile.svg"
                    alt="profile-icon"
                    className="profile-img"
                  /> */}
                  <img
                    src={item.user_profile_url ? item.user_profile_url : "https://avaeuslfdblob.blob.core.windows.net/admin-upload/defaulticon.png"}
                    alt="profile-icon"
                    className="profile-img circular-image"
                      width= '42px' height= '42px'
            
                  />
                  <div className="ms-2 d-grid">
                    <p className="font-11 font-regular primary-color text-start mb-1">
                      {item.user_name}
                    </p>
                    <p className="font-10 font-regular secondary-color mb-0">
                      {item.user_domain}
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-center">
                <span className="custom-grade-badge custom-badge-red font-12 font-medium">
                  {item.average_grade}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // This function is for bind the chart in htm part in this it modifies the response in to object as {grades: A+ , class 1:10,class 2:20}
  const consolidatedFunction = () => {
    if (graph != undefined) {
      const grades = graph[0] ? Object.keys(graph[0]).filter((item) => {
        if (item == 'A+' || item == 'A-' || item == 'B+' || item == 'B-' || item == 'C-' || item == 'C+')
          return item
      }) : [];
      if (grades.length > 0) {
        // Transforming data
        const transformedData = grades?.map(grade => {
          const entry = { grade: grade };
          graph?.forEach((item, index) => {
            entry[item.class_name] = item[grade];
          });
          return entry;
        });
        // Add new entry for grade 0 at the beginning of the transformed data array
        const zeroGradeEntry = { ...transformedData[0], grade: 0 };
        transformedData?.unshift(zeroGradeEntry);

        //PC_LFS_DB_165 - PC_LFS_DB_167 The CustomizedAxisTick component is a custom tick for an axis in a chart. Here's what it does:It receives props x, y, and payload.If the value of payload.value is 0, it returns null. This is done to hide the label for 0 on the axis.If the value of payload.value is not 0, it returns a <g> element with a translation based on the x and y props.Inside the <g> element, it renders a <text> element with the value of payload.value. The textAnchor attribute is set to "middle" to horizontally center the text, and the fill attribute is set to "#666" to specify the text color.
        const CustomizedAxisTick = ({ x, y, payload }) => {
          if (payload.value === 0) {
            return null; // Hide the label for 0
          }
          return (
            <g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {payload.value}
              </text>
            </g>
          );
        };

        // debugger
        //bind this in graph HTML part
        return (

          <LineChart width={500} height={300} data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid horizontal={false} strokeDasharray="3" />
            <XAxis dataKey="grade" height={60} tick={<CustomizedAxisTick />} />
            <YAxis />
            <Legend verticalAlign="top" align="right" height={36} wrapperStyle={{ left: '70px', top: '-40px' }} />
            {graph && graph[0] && graph[0].class_name && (
              <Line
                type="monotone"
                dataKey={graph[0].class_name}
                stroke="#4E62F0"
                name={graph[0].class_name}
              />
            )}
            {graph && graph[1] && graph[1].class_name && (
              <Line
                type="monotone"
                dataKey={graph[1].class_name}
                stroke="#F04EC7"
                name={graph[1].class_name}
              />
            )}
          </LineChart>

        );
      }
      else {
        return (
          <div className="custom-cards-height d-flex flex-column align-items-center justify-content-center">
            <span>
              <img
                src="images/no-rec-found-grey.svg"
                className="mb-3"
                alt="No-records-found"
              />
            </span>
            <p className="mb-0 font-medium font-18 primary-color">
              No records found
            </p>
            <p className="mb-0 font-regular font-12 secondary-color">
              No records were found at the moment
            </p>
          </div>
        )
      }
    }
    else {
      return (
        <div className="custom-cards-height d-flex flex-column align-items-center justify-content-center">
          <span>
            <img
              src="images/no-rec-found-grey.svg"
              className="mb-3"
              alt="No-records-found"
            />
          </span>
          <p className="mb-0 font-medium font-18 primary-color">
            No records found
          </p>
          <p className="mb-0 font-regular font-12 secondary-color">
            No records were found at the moment
          </p>
        </div>
      )
    }
  }

  return (
    <>
      <div className="container-xxxl">
        {loading ? <Loader /> : (
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="dashboard-body-bg px-4 py-4 rounded-3">
                <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3">
                  <h5 className="font-20 font-semibold mb-0 primary-color">Overview</h5>
                  <div className="d-flex">
                    <div className="dropdown topics-dropdown me-3">
                      <span className="action-drop-down d-flex cust-dropdown-width">
                        <button id="batches"
                          className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                          type="button" data-bs-toggle="dropdown" aria-expanded="true">
                          <span className="font-medium font-14 primary-color text-truncate" >{batchSelect || 'Select'}</span>
                          <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                        </button>

                        <span key='' className="dropdown-menu common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow">
                          {displayBatchName()}
                        </span>
                      </span>
                    </div>
                    <div className="dropdown topics-dropdown w-100">
                      <span className="action-drop-down d-flex cust-dropdown-width">
                        <button id="role"
                          className="btn topics-dropdown-btn common-card-theme-bg py-2 custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                          type="button" data-bs-toggle="dropdown" aria-expanded="true">
                          <span className="font-medium font-14 primary-color text-truncate" >{subBatchSelect || 'Select'} </span>
                          <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                        </button>
                        <span className="dropdown-menu common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow">
                          {displaySubbatchName()}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8">
                    <div className="p-4 shadow-sm rounded-2 bg-white mb-3 dashboard-style">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="mb-0 font-regular font-14 primary-color">Recent Grades </p>
                        <span className="dashboard-style icon-style rounded-3 custom-border common-card-theme-bg dashboard-card-icon"><img src="images/recent-grades-icon-white.svg" alt="Chart" className="custom-arrow-color" /></span>
                      </div>
                      <div className="row">
                        {displayrecentGrade()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-7">
                        <div className="p-3 shadow-sm rounded-2 bg-white dashboard-style mb-3 mb-xl-0">
                          <div className="d-md-flex justify-content-between align-items-center mb-3">
                            <p className="mb-0 font-regular font-14 mb-2 mb-md-0 primary-color">
                              Feedback Distribution
                            </p>
                          </div>
                          <div className="cust-feedback-section">
                            {consolidatedFunction()}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5">
                        <div className="p-3 shadow-sm rounded-2 bg-white dashboard-style mb-3 mb-xl-0">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className="mb-0 font-regular font-14 primary-color">
                              Risky Trainees
                            </p>
                          </div>
                          <div className="cust-dashboard-widget-scroll">
                            {displayRiskeeTrainee()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 ps-2 ps-xl-0">
                    <div className="p-4 shadow-sm rounded-2 bg-white dashboard-style">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="mb-0 font-regular font-14 primary-color">
                          Top Questions
                        </p>
                        <span className="dashboard-style icon-style rounded-3 custom-border common-card-theme-bg dashboard-card-icon">
                          <img
                            src="images/questions-icon-white.svg"
                            alt="Questions"
                            className="custom-arrow-color"
                          />
                        </span>
                      </div>
                      <div className="questions-card-height cust-dashboard-widget-scroll">
                        {displayTopQuestion()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp} />
      }
    </>
  )
}