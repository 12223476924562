import axios from 'axios'
import React, { useState, useEffect ,useNavigate} from "react";
import { adminBaseUrl, traineeBaseUrl } from '../Constant/constant'
import { decrypt, encrypt } from '../Helper/handleSecurity'
import ErrorPage from '../Components/Login/ErrorPage';
import { jwtDecode } from 'jwt-decode';

// The client function handles API requests by encrypting the request body, selecting the appropriate base URL based on user role, and making an HTTP request using axios. It decrypts the response data and checks if the JWT has expired, clearing storage and redirecting the user if necessary. The apiEndpoint, requestBody, requestType, and role parameters define the API endpoint, data to send, HTTP method, and user role, respectively.
export async function client(apiEndpoint, requestBody, requestType, role) {
    try {
        const excludedEndpoints = ['/login', '/validateAnswerForReview', '/fetchQuestionForReview', '/storeReviewDetails',"/insertUsers"];        if (!excludedEndpoints.includes(apiEndpoint)) {
            const token = localStorage.getItem('jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000);
                if (decodedToken.exp <= currentTime) {
                    // Token has expired
                    window.location.href = "/";
                    setTimeout(() => {
                        localStorage.clear();
                        sessionStorage.clear();
                    }, 0);
                    return;
                }
            }
        }
        let req_body = encrypt(requestBody)
        let baseUrl = ''
        if (role == 'admin') {
            baseUrl = adminBaseUrl
        }
        if (role == 'intern') {
            baseUrl = traineeBaseUrl
        }
        let url = baseUrl 
        let requestConfig = {
            method: requestType, 
            url: url,
            data: req_body,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let response = await axios(requestConfig)
        response.data = JSON.parse(decrypt(response.data["data"]))
        return response;
    } catch (error) {
        console.log(error)
    }
}