import { useState } from "react";
import AdminNavbar from "./AdminNavbar/AdminNavbar";
import KnowledgeBase from "./KnowledgeBase/KnowledgeBase";
import { DashboardComponent } from "./Overview/DashboardComponent";
import TrainingPlanGrid from "./TrainingPlan/TrainingPlanGrid";
import UserManagement from "./UserManagement/userManagementGrid"

const AdminComponent = () => {
    
    let activityObj={
        overview: 'active',
        kb: '',
        traingPlan: '',
        userManagement: ''
    }
    const active = sessionStorage.getItem('previousTab')
    if(active == 'overview'){
        activityObj={
            overview: 'active',
            kb: '',
            traingPlan: '',
            userManagement: ''
        }
    }
    else if(active == 'kb'){
        activityObj={
            overview: '',
            kb: 'active',
            traingPlan: '',
            userManagement: ''
        }
    }
    else if(active == 'trainingPlan'){
        activityObj={
            overview: '',
            kb: '',
            traingPlan: 'active',
            userManagement: ''
        }    }
    else if(active == 'userManagement'){
        activityObj={
            overview: '',
            kb: '',
            traingPlan: '',
            userManagement: 'active'
        }    }

    const [activeTab, setActiveTab] = useState(activityObj)


    return (
        <>
            <AdminNavbar activity={activeTab} setActivity={setActiveTab}>  </AdminNavbar>
            <>
                {activeTab['kb'] == 'active' ? <KnowledgeBase></KnowledgeBase> : <></>}
                {activeTab['overview'] == 'active' ? <DashboardComponent></DashboardComponent> : <></>}
                {activeTab['traingPlan'] == 'active' ? <TrainingPlanGrid></TrainingPlanGrid> : <></>}
                {activeTab['userManagement'] == 'active' ? <UserManagement></UserManagement> : <></>}
            </>
        </>


    )
}

export default AdminComponent;