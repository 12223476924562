import {client} from '../../Client/client'
import { adminEndpoints } from '../../Constant/constant';
 
let trainingPlanEndpoints = adminEndpoints.trainingPlanEndpoints;
// const jwt = localStorage.getItem('jwt')

export async function getTrainingPlan(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.getTrainingPlan,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.getTrainingPlan,requestBody,"POST",'admin')
    let result=(response)
    console.log(result.data);
    return result.data
}
 
export async function getBatch(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.getBatch,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.getBatch,requestBody,"POST",'admin')
    let result=(response)
    console.log("batch result ",result);
    return result.data
}
export async function getSubBatch(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.getSubBatch,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.getSubBatch,requestBody,"POST",'admin')
    let result=(response)
 
    console.log("subbatch result",result.data);
    return result.data.subbatch
}
export async function getClass(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.getClass,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.getClass,requestBody,"POST",'admin')
    let result=(response)
    console.log(result.data);
    return result.data
 
}
 
export async function deleteTrainingPlan(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.deleteTrainingPlan,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.deleteTrainingPlan,requestBody,"POST",'admin')
    let result=(response)
    console.log("deleteresult",result.data[0].message);
    return result.data[0].message
}
 
export async function UploadTrainingPLan(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.UploadTrainingPLan,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.UploadTrainingPLan,requestBody,"POST",'admin')
    let result=(response)
    console.log("uploadresult",result);
    return result.data;
}
 
export async function setEdit(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.setEdit,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.setEdit,requestBody,"POST",'admin')
    let result=(response)
    console.log(result);
    return result.status
}
 
export async function validateUploadfile(body){
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.trainingPlanEndpoints.validateUploadfile,
        "request_data":body
      }
    let response=await client(adminEndpoints.trainingPlanEndpoints.validateUploadfile,requestBody,"POST",'admin')
    let result=(response)
    console.log(result);
    return result.data
}