import { useState } from "react";
import Upload from "./uploadTP";

function EmptyGrid(props) {
  if (props.message === "Segregate Batch. Please try to assign batches before uploading the training plan.") {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="common-card-theme-bg px-4 py-4 mt-3 mb-3 rounded-3">
                <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3">
                </div>
                <div className="alert-container alert-container-bg custom-border rounded-3 mb-3 p-3">
                  <div className="d-flex">
                    <div className="d-flex gap-3 align-items-start">
                      <img
                        src="images/exclamatory-icon-orange.svg"
                        alt="folder-icon"
                        className="pt-1"
                      />
                      <div>
                        <p className="font-medium font-16 primary-color mb-2">
                          Segregate Batch
                        </p>
                        <p className="font-regular font-14 secondary-color mb-3">
                          Please try to assign batches before uploading the training
                          plan here.
                        </p>
                        
                          <p className="font-regular fit-content font-14 mb-0">
                            <a href="#" className="link-text text-decoration-none">
                              Assign Batch
                            </a>
                          </p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 empty-container py-5 custom-border shadow-sm rounded-2 text-center">
                  <img
                    src="images/no-record-icon.svg"
                    alt="three-lines-icon"
                    className="pb-4"
                  />
                  <p className="font-14 font-medium primary-color mb-1">
                    No training plan uploaded !
                  </p>
                  <p className="font-12 font-medium secondary-color mb-2">
                    No knowledge base found at the moment
                  </p>
                  <div className="file-input-wrapper cursor-pointer">
                    <input type="file" name="fileToUpload" id="fileToUpload" />
                    <label
                      htmlFor="fileToUpload"
                      className="btn-upload link-text font-12 font-medium cursor-pointer" 
                    >
                      Upload Training Plan
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (props.message === "No training plan base uploaded! No knowledge base found at the moment.") {
    return (
      <>
        <div className="col-md-12 empty-container py-5 custom-border shadow-sm rounded-2 text-center">
          <img
            src="images/no-record-icon.svg"
            alt="three-lines-icon"
            className="pb-4"
          />
          <p className="font-14 font-medium primary-color mb-1">
            No training plan uploaded !
          </p>
          <p className="font-12 font-medium secondary-color mb-2">
            No knowledge base found at the moment
          </p>
          <div className="file-input-wrapper cursor-pointer">
            <input type="file" name="fileToUpload" id="fileToUpload" />
            <label
              htmlFor="fileToUpload"
              className="btn-upload link-text font-12 font-medium cursor-pointer"
            >
              Upload Training Plan
            </label>
          </div>
        </div>
      </>
    );
  }
}

export default EmptyGrid;