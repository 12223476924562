import { useEffect } from 'react';
import Admin from './Components/Admin/AdminComponent';
import LoginComponent from './Components/Login/LoginComponent';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReviewComponent from './Components/Trainee/Review/ReviewComponent';
import ChatBot from './Components/Trainee/ChatBot/ChatBot';
import ErrorPage from './Components/Login/ErrorPage';
import Traininglist from './Components/Trainee/TrainingList/TraineeComponent'
import PracticeComponent from './Components/Trainee/Review/practiceTest';

function App() {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const access = localStorage.getItem('access')
    if (access && access == 'Admin') {
      setIsAdmin(true)
    }
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LoginComponent setShowAdmin={setIsAdmin} setLogginStatus={setIsLogged} />} />
          {isAdmin && (
            <>
              <Route path="/adminlanding" element={<Admin />} />
            </>
          )}
          {!isAdmin && (
            <>
              <Route path="/Trainee" element={<Traininglist></Traininglist>} />
              <Route path="/review" element={<ReviewComponent></ReviewComponent>}></Route>
              <Route path='/chatbot' element={<ChatBot></ChatBot>} />
              <Route path='/practice' element={<PracticeComponent></PracticeComponent>} />
            </>
          )}
          <Route path="*" element={<ErrorPage isAdmin = {isAdmin} ></ErrorPage>} />
          {isAdmin &&(<Route path="/adminLanding/*" element={<ErrorPage isAdmin = {isAdmin} ></ErrorPage>} />)}
        </Routes>
      </Router>
    </>
  );
}

export default App;
