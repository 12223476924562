import {client} from '../../Client/client';
import { adminEndpoints } from '../../Constant/constant';

//PC-FGC-20 - PC-FGC-34
//Function to get the user details by calling the client function
// const jwt = localStorage.getItem('jwt')

export async function getUsersDetails(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getUsersDetails,
        "request_data":requestbody
      }
    let response= await client(adminEndpoints.userManagement.getUsersDetails,requestBody,'POST','admin');
    console.log("Users Details:",response.data[0])
    return  response.data[0];
}
//PC-FPP-22- PC-FPP-38
// Function to assign the batch for the selected users by calling the api in the backend
export async function getAssignBatch(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getAssignBatch,
        "request_data":requestbody
      }
    let response= await client(adminEndpoints.userManagement.getAssignBatch,requestBody,'POST','admin');
    console.log(requestBody)
    console.log(response)
    return  response.data;
}
//PC-FPP-62 - PC-FPP-76
//Function perform the edit functionalities
export async function getEdit(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getEdit,
        "request_data":requestbody
      }
    let response = await client(adminEndpoints.userManagement.getEdit,requestBody,'POST','admin');
    console.log(requestBody)
    console.log(response)
    return response.data;
}
//PC-FPP-92 - PC-FPP-105
//Function to edit sub batch data
export async function getEditSubBatch(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getEditSubBatch,
        "request_data":requestbody
    }
    let response = await client(adminEndpoints.userManagement.getEditSubBatch,requestBody,'POST','admin');
    console.log(requestBody)
    console.log(response)
    return response.data;
}
//PC-FGC-183 -PC-FGC-198
//Function to fetch the sub batch data from the backend
export async function getSubBatch(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getSubBatch,
        "request_data":requestbody
      }
    let response = await client(adminEndpoints.userManagement.getSubBatch,requestBody,'POST','admin');
    console.log(response)
    return response.data;
}
//PC-FGC-149- PC-FGC-171
//Function to fetch the batch data from the backend
export async function getBatch(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getBatch,
        "request_data":requestbody
      }
    let response = await client(adminEndpoints.userManagement.getBatch,requestBody,'POST','admin');
    console.log(response.data)
    return response.data;
}
//PC-FGC-210 - PC-FGC-225
// Function to fetch the domain data from the backend
export async function getDomain(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getDomain,
        "request_data":requestbody
      }
    let response = await client(adminEndpoints.userManagement.getDomain,requestBody,'POST','admin');
    console.log(response)
    return response.data;
}

export async function getInsert(requestbody)
{
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userManagement.getInsert,
        "request_data":requestbody
      }
    let response= await client(adminEndpoints.userManagement.getInsert,requestBody,'POST','admin');
    console.log("Insert:",response)
    return response.data;
}