import React, { useState } from 'react';
import Popup from './Popup';
import { EditBatchPopup } from './editPopup';
import { EditSubBatchPopup } from './editSubPopup';

export default function Toast({
  setSelectedUsers,
  setSelectAll,
  selectedUsers,
  isAssignedToBatch,
  isAssignedToSubBatch,
  selectedBatchName,
  setRefreshGrid,
  setPopup,
  handleCheckboxChange,
  togglePopup
}) {
  const [showEditBatchPopup, setShowEditBatchPopup] = useState(false);
  const [showEditSubBatchPopup, setShowEditSubBatchPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showToast, setShowToastLocal] = useState(true); // Changed the name to avoid conflict

  const handleUnselectAll = () => {
    setSelectedUsers([]);
    setSelectAll(false);
    setPopup(false);
    setShowPopup(true);
    setShowToastLocal(false); // Changed the setter name here
  };

  const handleEditBatchClick = () => {
    setShowEditBatchPopup(true);
    setRefreshGrid(true);
    setShowToastLocal(false); // Changed the setter name here
  };

  const handleAssignBatchClick = () => {
    setShowPopup(true);
    setPopup(true);
    setShowEditBatchPopup(false);
    setShowEditSubBatchPopup(false);
    setRefreshGrid(true);
    setShowToastLocal(false); // Changed the setter name here
  };

  const handleAssignSubgroupClick = () => {
    setShowPopup(true);
    setPopup(true);
    setShowEditSubBatchPopup(true);
    setRefreshGrid(true);
    setShowToastLocal(false); // Changed the setter name here
  };

  const handleEditSubgroupClick = () => {
    setShowEditSubBatchPopup(true);
    setRefreshGrid(true);
    setPopup(false);
    setShowPopup(true);
    setShowToastLocal(false); // Changed the setter name here
  };

  const selectedUsersCount = selectedUsers.length;

  return (
    <div>
      {showToast && (
        <div className="position-fixed bottom-0 start-50 translate-middle-x">
          <div
            id="liveToast"
            className="toast show top-0 w-100 border-0"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body common-card-theme-bg custom-border rounded-2 d-flex align-items-center px-3">
              <span className="font-14 font-semibold me-5 primary-color">
                {selectedUsersCount} Trainee{selectedUsersCount !== 1 && 's'} Selected
              </span>
              <button
                type="button"
                className="btn secondary-btn color-white common-card-theme-bg custom-border font-14 font-semibold m-0 me-2 py-2"
                onClick={handleUnselectAll}
              >
                Unselect all
              </button>
              {isAssignedToBatch && (
                <>
                  {isAssignedToSubBatch ? (
                    <button
                      type="button"
                      className="btn secondary-btn color-white common-card-theme-bg custom-border font-14 font-semibold m-0 me-2 py-2"
                      onClick={handleEditSubgroupClick}
                      data-bs-toggle="modal" 
                      data-bs-target="#EditSubGroup"
                    >
                      Edit Subgroup
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn primary-btn font-14 font-semibold py-2 m-0"
                      onClick={handleAssignSubgroupClick}
                      data-bs-toggle="modal" 
                      data-bs-target="#EditSubGroup"
                    >
                      Assign Subgroup
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn secondary-btn color-white common-card-theme-bg custom-border font-14 font-semibold m-0 me-2 py-2"
                    onClick={handleEditBatchClick}
                    data-bs-toggle="modal" 
                    data-bs-target="#editBatch"
                  >
                    Edit Batch
                  </button>
                </>
              )}
              {!isAssignedToBatch && (
                <button
                  type="button"
                  className="btn primary-btn font-14 font-semibold py-2 m-0"
                  onClick={handleAssignBatchClick}
                  data-bs-toggle="modal" 
                  data-bs-target="#assign"
                >
                  Assign batch
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <Popup
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        setRefreshGrid={setRefreshGrid}
        show={setPopup} // Make sure setPopup is correct here
        togglePopup={togglePopup}
        showPopup={setShowPopup}
      />

      <EditBatchPopup
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        selectedBatchName={selectedBatchName}
        setRefreshGrid={setRefreshGrid}
        show={showEditBatchPopup}
        togglePopup={() => setShowEditBatchPopup(false)}
        isAssignedToBatch={isAssignedToBatch}
        isAssignedToSubBatch={isAssignedToSubBatch}
      />
      <EditSubBatchPopup
        isAssignedToBatch={isAssignedToBatch}
        isAssignedToSubBatch={isAssignedToSubBatch}
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        setRefreshGrid={setRefreshGrid}
        show={showEditSubBatchPopup}
        togglePopup={() => setShowEditSubBatchPopup(false)}
        selectedBatchName={selectedBatchName}
      />
    </div>
  );
}