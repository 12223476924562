import React from 'react';
import { Modal, Button, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { getClass, getUpload } from '../../../Service/AdminApi/knowledgeBaseApi';
import { Document, Page, pdfjs } from 'react-pdf';
import Loader from './loader';
import ToastComponent from './toast';
import { jwtDecode } from 'jwt-decode';


//PC_PC_02 - PC_PC_68 The Upload component in the provided code is a React component that allows users to upload XLSX (Excel) or PDF files to a knowledge base (KB)setShowUpload: A function that controls the visibility of the upload modal.editKey: A key that determines whether the component is in edit mode (1 or 2) or not.kbId: The ID of the knowledge base associated with the upload.displayChange: A function that triggers a display change.loadingUpload: A state that manages the loading state of the upload process.loadChange: A function that handles changes in the loading state.

const Upload = ({ setShowUpload, editKey, kbId, displayChange, loadingUpload, loadChange, editObj ,uploadToast ,onUploadSucess}) => {

    const [selectClass, setSelectClass] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [editFile, seteditFile] = useState([])
    const [inputFile, setinputFile] = useState([])
    const [excelInputFile, setexcelInputFile] = useState([])
    const [classTable, setClassTable] = useState([])
    const [fileUpload, setFileUpload] = useState(false)
    const [uploadclass, setUploadClass] = useState('')
    const [editClass, setEditClass] = useState('')

    const [filename, setFileName] = useState('')
    const [fileSize, setFileSize] = useState('')
    const [fileType, setFiletype] = useState('')

    const [uploadResult, setUploadResult] = useState()
    const [uploadSuccess, setuploadSucess] = useState(false)
    const [inputFileType, setinputFiletype] = useState('')

    const [statuscode, setStatuscode] = useState(200)
    const tempState = localStorage.getItem('jwt')
    const [state, setState] = useState(jwtDecode(tempState))

    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
      };

    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState(toastData);

    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 15000);
    }, [hideToast]);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    // PC_PC_02 getPageAsBase64 that takes a PDF document and a page number as input, and returns the base64-encoded representation of the specified page as a PNG image.It converts the image it to base64
    const getPageAsBase64 = async (pdf, pageNumber) => {
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 2 });
        const canvas = document.createElement('canvas');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const context = canvas.getContext('2d');
        await page.render({ canvasContext: context, viewport }).promise;
        return canvas.toDataURL('image/png');
    };

    // Converts the specified PDF page to a base64 image 
    const handleAllPagesBase64 = async (file) => {
        const pdf = await pdfjs.getDocument({ url: URL.createObjectURL(file) }).promise;
        const numPages = pdf.numPages;
        const base64Images = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            const base64Image = await getPageAsBase64(pdf, pageNum);
            base64Images.push(base64Image.split(",")[1]);
        }
        return base64Images
    };

    // PC_PC_156 - PC_PC_158 The handlefileClose function sets the selectedFile state to null, indicating that no file is currently selected.It sets the fileUpload state to true, potentially triggering a file upload operation
    const handlefileClose = () => {
        setSelectedFile(null)
        setFileUpload(true)
    }

    // PC_PC_14 - PC_PC_36 an asynchronous function called fetchClass that fetches the class to bind to the dropdown .It initializes a variable reqBody with a JSON object containing a data key set to null.Upon receiving the result, it sets the data from the result into classTable using setClassTable(result.data).
    const fetchClass = async () => {
        try {
            let reqBody = {
                "data": null
            }
            let result = await getClass(reqBody)
            if (result.data.data != "No Class found"){
                setClassTable(result.data.data)
            }
            

        }
        catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            uploadToast(toastData);
        }
    }

    useEffect(() => {
        fetchClass();
    }, [])

    useEffect(() => {
        if (editKey === 1) {
            setFileUpload(true);
        }
    }, [editKey]);


    // PC_PC_02 - a function called readFileAsBase64 that takes a file object as input and returns a Promise.It creates a new Promise that takes two parameters: resolve and reject, which are used to handle the success and failure of the asynchronous operation.Inside the Promise, a new FileReader object is created.An onload event listener is set on the FileReader, which triggers when the file has been successfully read.
    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result.split(",")[1];
                resolve({ base64Data, fileName: file.name, fileType: file.type });
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    // PC_PC_159 - PC_PC_165 he handleCancel function sets the fileUpload state to true, potentially indicating that a file upload operation should be initiated or reset.It sets the selectedFile state to null, indicating that no file is currently selected.It calls the displayChange function, which seems to be related to updating the display in some way.It resets the uploadClass state to 'Select', potentially resetting or clearing any previously selected upload classes.It resets the editClass state to 'Select', potentially resetting or clearing any previously selected edit classes.
    const handleCancel = () => {
        setFileUpload(true)
        setSelectedFile(null)
        displayChange()
        setUploadClass('Select')
        setEditClass('Select'); // Reset Class dropdown
        setSelectedFile(null)
    };

    //PC_PC_136 - PC_PC_150 handleSave seems to handle file uploads by converting files to base64 and setting them for upload, depending on the file type and whether the operation is a new upload or an edit.
    let jwt = localStorage.getItem('jwt')
    const payload = jwtDecode(jwt);
    const userName = payload.userName;
    const handleSave = async () => {
        try {
            if (selectClass && selectedFile) {
                let allowedFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf"];
                if (!allowedFileTypes.includes(fileType)) {
                    setErrorMessage("It seems that only Excel or PDF files are currently supported for upload.");
                    setShowError(true);
                }
                if (selectedFile && (editKey === 1 || editKey === 2)) {
                    if (fileType === 'application/pdf') {
                        setinputFiletype(".jpg");
                        let tempfiletype = '.jpg'
                        let imgBase = await handleAllPagesBase64(selectedFile);
                        if (editKey === 1) {
                            setinputFile(imgBase);
                            uploadFiles(imgBase, tempfiletype)
                        } else if (editKey === 2) {
                            seteditFile(imgBase);
                            uploadFiles(imgBase, tempfiletype)

                        }
                    }
                    else if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                        setinputFiletype(".xlsx");
                        let tempfiletype = ".xlsx"
                        var exlbase = await readFileAsBase64(selectedFile);
                        var excelBase64 = exlbase.base64Data;
                        if (editKey === 1) {
                            setexcelInputFile(prevExcelInputFile => [...prevExcelInputFile, excelBase64]);
                            uploadFiles(excelBase64, tempfiletype)
                        } else if (editKey === 2) {
                            uploadFiles(excelBase64, tempfiletype)
                        }
                    }
                }
            }
            else if (!selectClass) {
                setShowError(true);
                setErrorMessage("Please Select class")
            }
            else if (!selectedFile) {
                setShowError(true);
                setErrorMessage("Please upload File")
            }
        }
        catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    };

    // PC_PC_09 - PC_PC_51 The uploadFiles function manages file uploads to the server, utilizing getUpload to send requests. It handles loading states, constructs request bodies based on conditions, and sets states according to response statuses. If successful, it triggers success notifications. If file types aren't supported, it sets an error flag.
    const uploadFiles = async (data, tempfiletype) => {
        try {
            loadChange()
            if (editKey === 1) {
                var reqBody = {
                    "inputFile": data,
                    "editClass": editClass,
                    "editInputFile": [],
                    "kbId": kbId,
                    "file_type": tempfiletype,
                    "class": uploadclass,
                    "filename": filename,
                    "filesize": fileSize,
                    "created_by" : userName
                }
            }
            else if (editKey === 2) {
                var reqBody = {
                    "inputFile": [],
                    "editClass": editClass,
                    "editInputFile": data,
                    "kbId": kbId,
                    "file_type": tempfiletype,
                    "class": uploadclass,
                    "filename": filename,
                    "filesize": fileSize,
                    "created_by" : userName
                }
            }
            let result = await getUpload(reqBody)
            loadingUpload()
            if (result.data.message === "Uploaded successfully") {
                let toastData = {
                    toastType: "Positive",
                    toastHeaderMessage: "Positive",
                    toastBodyMessage: "Knowledge Base Uploaded Successfully !"
                }
                uploadToast(toastData);
            }
            else if (result.data.message === "Already Uploaded") {
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: `The knowledge base for the ${selectClass} has already been uploaded !`
                }
                uploadToast(toastData);;
            }
            else if (result.data.message === "Invalid Excel format"){
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: 'Invalid format: Add "Url" column to the Excel file !'               
                }                
                uploadToast(toastData);
            }
            else if (result.data.message === "Upload Training Plan"){
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: `Please Upload Training Plan for the ${selectClass} !`               
                }                
                uploadToast(toastData);
            }
            else{
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: "Upload Failed !"
                }
                uploadToast(toastData);
            }
            onUploadSucess()
            handleCancel()
            setUploadResult(result)
        } catch (error) {
            loadingUpload()
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Upload Failed !"
            }
            uploadToast(toastData);
        }
    }
    // set the state variable uploadSuccess as false 
    const changeToast = () => {
        setuploadSucess(false)
    }

    const handleClose = () => {
        displayChange()
    }

    const ErrorMessage = ({ errorMessage, showError }) => {
        if (!showError) {
            return null;
        }
        return (
            <>
                <span className="mt-2 font-regular font-14 mandatory d-flex align-items-center"><img src="images/error-msg.svg" alt="Error" className="me-2 mb-1" />{errorMessage}</span>
            </>
        );
    };

    return (
        <>
            {/* {loading ? <Loader /> : ( */}
            <div className="modal-content body-bg custom-padding">
                <Modal show onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        {editKey === 1 ? <Modal.Title>
                            <h5 className='mb-0 d-flex align-items-center'>
                                <span className="upload-icon-style me-3">
                                    <img src="images/upload-white-icon.svg" alt="upload-icon" className="custom-arrow-color" /></span>
                                <span className='font-22 font-semibold'>Upload</span></h5></Modal.Title> : <Modal.Title>
                            <h5 className='mb-0 d-flex align-items-center'>
                                <span className="upload-icon-style me-3">
                                    <img src="images/upload-white-icon.svg" alt="upload-icon" className="custom-arrow-color" /></span>
                                <span className='font-22 font-semibold'>Edit</span></h5></Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <label className="font-semibold font-16 mb-2 primary-color">Class</label>
                                <div className="dropdown topics-dropdown w-100">
                                    <span className="action-drop-down ">
                                        <button id="teams"
                                            className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                            {editKey === 1 ? (
                                                <span className="font-medium font-14 primary-color">
                                                    {selectClass || 'Select'}
                                                </span>
                                            ) : (
                                                <span className="font-medium font-14 primary-color">
                                                    {selectClass || setSelectClass(editObj.editclass)}
                                                </span>
                                            )}

                                            <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                        </button>
                                        <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                            {classTable?.map((element) => (
                                                <span key={element.class_id} className="d-flex list cursor-pointer p-2 primary-color" onClick={() => {
                                                    setSelectClass(element.class_name)
                                                    if (editKey === 1) {
                                                        setUploadClass(element.class_id);
                                                    } else {
                                                        setEditClass(element.class_id)
                                                }}}>
                                                    {element.class_name}
                                                </span>
                                            ))}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Upload</label>
                            {/* {editKey === 1 && setFileUpload(true)} */}
                            {fileUpload &&
                                <button className="upload-files-container p-4 w-100 border-0 common-bg  mb-2">
                                    <label
                                        htmlFor="fileUpload"
                                        className="upload-btn-wrapper upload-wrapper d-block text-center"
                                    >
                                        <img
                                            src="images/upload-icon-black.svg"
                                            alt="dropfiles"
                                            className="drop-file text-center mb-3"
                                        />
                                        <span className="font-medium font-14 d-block text-center primary-color mb-1">
                                            Upload files from computer
                                        </span>
                                        <span className="font-regular font-12 d-block text-center position-relative secondary-color mb-2">
                                            or drag and drop here
                                        </span>
                                        <input id="fileUpload" type="file" className=" d-none " accept=".xls,.xlsx,application/vnd.ms-excel,.pdf,application/pdf" onChange={(e) => {

                                            let allowedFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf"];
                                            if (!allowedFileTypes.includes(e.target.files[0].type)) {
                                                setErrorMessage("It seems that only Excel or PDF files are currently supported for upload.");
                                                setShowError(true);

                                            }
                                            else {

                                                setSelectedFile(e.target.files[0]);

                                                let name = e.target.files[0].name.split(".")[0];
                                                let fileSize = (e.target.files[0].size / 1024).toFixed(1) + "KB";
                                                let fileType = e.target.files[0].type;

                                                setFileName(name);
                                                setFileSize(fileSize);
                                                setFiletype(fileType);

                                                if (editKey === 1) {
                                                    setinputFile(selectedFile);
                                                } else if (editKey === 2) {
                                                    seteditFile(selectedFile);
                                                }

                                                setFileUpload(false);
                                                setShowError(false)
                                                setErrorMessage("")
                                            }

                                        }
                                        }></input>
                                        {/* {showError && <ErrorMessage errorMessage={errorMessage} showError={showError}></ErrorMessage>} */}

                                    </label>
                                </button>

                            }
                            {!fileUpload && (selectedFile || editKey === 2) && (
                                <div className="upload-container common-card-theme-bg custom-border">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex gap-4">
                                            <img src="images/folder-icon-white.svg" alt="folder-icon" className="custom-arrow-color" />
                                            <div>
                                                {editKey === 1 ? (
                                                    <div>
                                                        <p className="font-medium font-14 primary-color mb-1">
                                                            {filename}
                                                        </p>
                                                        <p className="font-regular font-12 secondary-color mb-0">
                                                            {fileSize}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p className="font-medium font-14 primary-color mb-1">
                                                            {filename || editObj.editfile}
                                                        </p>
                                                        <p className="font-regular font-12 secondary-color mb-0">
                                                            {fileSize || editObj.filesize}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <img src="images/popup-close-icon.svg" alt="delete-icon" className="close-icon cursor-pointer" onClick={() => {
                                                handlefileClose();
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <ErrorMessage errorMessage={errorMessage} showError={showError} />
                        <div className="modal-footer border-0 mt-2 mb-3 px-4 gap-2">
                            <button type="button" className="btn secondary-btn color-white custom-border font-20 font-semibold m-0" onClick={() => { displayChange() }}>Cancel</button>
                            <button type="button" className="btn primary-btn font-20 font-semibold m-0" onClick={() => {
                                handleSave()
                                // handleCancel()
                            }}>Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
            {hideToast ? <></> :
                <ToastComponent toastObj={toastProp} />
                
            }
        </>
    );
};

export default Upload;