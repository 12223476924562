// Pseudo code number PC_LFS3_1.0
import React, { useEffect, useState, useRef } from 'react';
import { getQuestionTemplate, getUserInteraction, insertErrorData } from '../../../Service/InternApi/internApi';
import { formatDateTime, getCurrentTimestamp, getTimeDifferenceInSeconds, extractNumberFromString, extractString, readFileAsBase64 } from '../../../Helper/handleFunction';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import { useNavigate, useLocation } from "react-router-dom";
import NavBarComponent from '../NavBar/NavBarComponent';
import { jwtDecode } from 'jwt-decode';
import ToastComponent from '../Toast/ToasatComponent';
import Loader from '../Review/Loader';
import { logDOM } from '@testing-library/react';


// Pseudo code number  PC_LFS3_1.0 TO PC_LFS3_1.105
function ChatBot() {
    const location = useLocation();
    const { state } = location;

    // props value
    const [trainingPlannerId] = useState(1);
    const [classId] = useState(1);
    const tempState = localStorage.getItem('jwt')
    const [state1, setState] = useState(jwtDecode(tempState))
    const userId = state1.userId
    const trainingSessionId = state ? state.training_session_id : null;
    const topic = state ? state.training_session_name : null;
    const [question, setQuestion] = useState([]);
    const [key, setKey] = useState(0);
    const [textInput, setTextInput] = useState("");
    const [regenrateres, setregenerateres] = useState("")
    const [regenerated, setRegenerated] = useState(false)
    // toast component
    const [loader, setLoader] = useState(true)

    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState(toastData);

    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 15000);
    }, [hideToast]);

    useEffect(() => {
        const selectedTheme = localStorage.getItem("selectedTheme");
        if (selectedTheme) {
            // setIsChecked(selectedTheme === "dark");
            // setColorTheme(selectedTheme);
            document.documentElement.setAttribute("data-theme", `${selectedTheme}-theme`);
        }
    }, []);

    // user history
    const [botmessage, setBotmessage] = useState([]);
    const [usermessage, setUsermessage] = useState([]);
    const [fileName, setFileName] = useState([]);
    const [fileUrl, setfileUrl] = useState([]);
    const [audioName, setAudioName] = useState([]);
    const [audioUrl, setAudioUrl] = useState([]);
    const [audioBase64, setaudioBAse64] = useState([]);

    // user Details
    const [username, setUsername] = useState("");
    const [userRole, setUserrole] = useState("");
    const [userProfile, setUserProfile] = useState(null);

    // categorize Messages user and bot
    const [todayMergeMessages, setTodayMergedUserMessages] = useState([]);
    const [yesterdayMergeMessages, setYesterdayMergeMessages] = useState([]);
    const [lastWeekMergeMessages, setlastWeekMergeMessages] = useState([]);
    const [oneMonthAgoMergeMessages, setoneMonthAgoMergeMessages] = useState([]);
    const [files, SetFiles] = useState([]);
    const [uploadfileSize, setuploadfileSize] = useState(0);
    const [uploadFileName, setuploadFileName] = useState("");
    const [base64Data, setbaseData] = useState([]);
    const [show, setShow] = useState(false);
    const [bindAgain, setBindAgain] = useState(true)
    const wavesurferRefs = useRef([]);

    //handle Regenerate 
    const [canBind, setCanBind] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [showEditIcon, setshowEditIcon] = useState(true)

    // handle File stauts
    const [fileStatus, setFileStatus] = useState(false)
    const [inlineEditText, setInlineEditText] = useState("")
    const [inlineEditObj, setInlineEditObj] = useState([{ lastWeek: [] }, { yesterday: [] }, { oneMonth: [] }, { today: [] }])
    const [editIndex, setEditIndex] = useState(0)

    // audio state varaible   
    const [liveTranscript, setLiveTranscript] = useState("")
    const [audioStatus, setAudioStatus] = useState(false)
    const [recoderaudioBase64, setRecoderaudioBase64] = useState("")
    const recognitionRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const transcriptRef = useRef([]);
    const [currentTimeOne, setcurentTimeOne] = useState(getCurrentTimestamp())
    const [curentTimeTwo, setcurentTimeTwo] = useState(getCurrentTimestamp())
    const [audioDuration, setaudioDuration] = useState("")
    const [pasue, setPause] = useState(false)
    const [recordPause, setRecordPause] = useState(false)
    const [audioButton, setAudioButton] = useState([])
    const [audioDurations, setAudioDurations] = useState({});
    const innerChatContainerRef = useRef(null)
    // state variable for hide input
    const [hideInput, setHideInput] = useState(true)
    const [responseLoader, setresponseloader] = useState(false)
    const [regeneratingIndex, setRegeneratingIndex] = useState(null);
    const [audioBind, setAudioBind] = useState('')
    const [errorShow , setErrorShow] = useState(false)
    let routing = useNavigate()
    

    useEffect(() => {
        if (innerChatContainerRef.current) {
            scrollToBottom();
        }
    }, [todayMergeMessages]);


    function scrollToBottom() {
        if (innerChatContainerRef.current) {
            innerChatContainerRef.current.scrollTop =
                innerChatContainerRef.current.scrollHeight;
            innerChatContainerRef.current.scrollTo({
                top: innerChatContainerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }


    // This effect runs once when the component mounts (due to the empty dependency array []). It calls the QuestionTemplate 
    useEffect(() => {
        QuestionTemplate();
    }, []);

    //  This effect runs whenever usermessage, uploadfileSize, uploadFileName, fileStatus, or bindAgain changes. It calls the categorizeMessages function, possibly to categorize and process messages received from the bot and user.
    useEffect(() => {
        categorizeMessages(botmessage, usermessage);
    }, [usermessage, uploadfileSize, uploadFileName, fileStatus, bindAgain, key]);

    // This effect runs whenever botmessage changes. 
    // It also calls the categorizeMessages function, possibly to categorize and process messages received from the bot and user.
    useEffect(() => {
        categorizeMessages(botmessage, usermessage);
    }, [botmessage]);

    // This effect runs whenever audioBase64 changes. It calls the bindAudio function, likely to bind audio files represented in Base64 format.
    useEffect(() => {
        bindAudio();
    }, [audioBase64, audioName]);

    // This effect runs whenever inlineEditObj changes. It checks the length of each array within inlineEditObj and conditionally calls display functions based on the length of each array. This likely involves rendering messages with inline edit options based on the presence of certain data.
    useEffect(() => {
        if (inlineEditObj[2].length > 0) {
            DisplayoneMonthAgeMessage()
        }
        if (inlineEditObj[0].length > 0) {
            DisplayLastWeekMessage()
        }
        if (inlineEditObj[1].length > 0) {
            DisplayYesterdayMessage()
        }
        if (inlineEditObj[3].length > 0) {
            DisplayTodayMessage()
        }
    }, [inlineEditObj])

    // This effect runs whenever bindAgain changes. It calls the DisplayTodayMessage function, likely to display today's messages again after some action (bindAgain) has occurred.
    useEffect(() => {
        DisplayTodayMessage()
    }, [bindAgain, hideInput, todayMergeMessages])

    // Pseudo code number PC_LFS3_1.5
    // The function is used to fetch question templates or user chat history based on certain parameters. 
    // Depending on the result obtained, it updates various state variables accordingly
    const QuestionTemplate = async () => {
        try {
            let reqBody = {
                "trainingPlannerId": trainingPlannerId,
                "classId": classId,
                "userId": userId,
                "trainingSessionId": trainingSessionId,
                "topic": topic
            };
            let result = await getQuestionTemplate(reqBody);
            const { status, data } = result;

            if (status === 200) {
                const message = data[0]?.message;

                if (message === "No Knowledge Base found") {
                    setLoader(false);
                    let toastData = {
                        toastType: "Negative",
                        toastHeaderMessage: "Negative",
                        toastBodyMessage: "No knowledge base uploaded!"
                    };
                    setToastProp(toastData);
                    setHideToast(false);
                    // Delay the redirection to allow the toast message to be shown
                    setTimeout(() => {
                        routing('/Trainee', { state: { errorMessage: toastData } });
                    }, 3000); // Adjust the delay time as needed (3 seconds in this case)
                } else {
                    setKey(data.key);
                    setLoader(false);

                    if (data.key === 0) {
                        // update the state variables for Question Template
                        setQuestion(data.message);
                        setUsername(data.userName);
                        setUserrole(data.userDomain);
                        setUserProfile(data.userUrl);
                    } else if (data.key === 1) {
                        // update the state variables for User Chat History
                        setUsername(data.userName);
                        setUserrole(data.userDomain);
                        setUserProfile(data.userProfileUrl);
                        setUsermessage(data.userMessage);
                        setBotmessage(data.botMessage);
                        setFileName(data.fileName);
                        setfileUrl(data.fileUrl);
                        setAudioName(data.audioName);
                        setAudioUrl(data.audioUrl);
                        setaudioBAse64(data.audioBase64);

                        // call the categorizeMessages to categorize the bot and user messages
                        categorizeMessages(data.botMessage, data.userMessage);
                    } else {
                        setLoader(false);
                        let toastData = {
                            toastType: "Negative",
                            toastHeaderMessage: "Negative",
                            toastBodyMessage: "Something Went Wrong!"
                        };
                        setToastProp(toastData);
                        setHideToast(false);
                    }
                }
            } else {
                handleUnexpectedResponse();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleUnexpectedResponse = () => {
        setLoader(false);
        let toastData = {
            toastType: "Negative",
            toastHeaderMessage: "Negative",
            toastBodyMessage: "Something Went Wrong!"
        };
        setToastProp(toastData);
        setHideToast(false);
    };

    const handleError = (error) => {
        setLoader(false);
        let toastData = {
            toastType: "Negative",
            toastHeaderMessage: "Negative",
            toastBodyMessage: "Something Went Wrong!"
        };
        setToastProp(toastData);
        setHideToast(false);
        // insertErrorData(reqBody) - uncomment and define if needed
    };
    // Pseudo code number PC_LFS3_1.6
    // The function is used to handle user interactions with question templates. It sends a request to the server with the user's question and retrieves a response. Depending on the response status, it updates the state variables accordingly, adding the user's message to the chat history and displaying the bot's response. If an error occurs during the process, it logs the error details for debugging purposes.
    // Method Parameters:question: The user's question to be processed.
    const handleQuestionTemplate = async (question) => {
        try {
            setresponseloader(true)
            let CurrentTimestamp = getCurrentTimestamp();
            var value = { "question": question, "date": CurrentTimestamp, "fileKey": 0, "audioKey": 0 };
            setUsermessage([...usermessage, value]);
            setKey(1)
            let reqBody = {
                "topic": topic,
                "trainingPlannerId": trainingPlannerId,
                "classId": classId,
                "userId": userId,
                "trainingSessionId": trainingSessionId,
                "regenerateKey": 0,
                "editKey": 0,
                "questionKey": 1,
                "question": value
            };
            let result = await getUserInteraction(reqBody);
            { result && setLoader(false) }
            setresponseloader(false)
            if (result.data.status === 200) {
                setBotmessage([...botmessage, result.data.message]);
                setKey(1);
            }
            else if (result.data.status === 400) {
                let CurrentTimestamp = getCurrentTimestamp();
                let value = { "question": result.data.message, "date": CurrentTimestamp, "regenerate": 0 };
                setBotmessage([...botmessage, value]);
                setKey(1);
            }
        }
        catch (error) {
            setLoader(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.7
    // The function handleChange is responsible for updating the text input value in response to user input events. It sets the value of textInput state variable to the value entered by the user through the event's target.
    // Method Parameters: event: The event object representing the input change event.
    const handleChange = (event) => {
        try {
            setTextInput(event.target.value);
        }
        catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "handleChange Fucntion",
                "errorType": "Front End"
            }
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.8
    // The handleKeyDown function is responsible for detecting the Enter key press event in a text input field. If the Enter key is pressed, it prevents the default behavior (typically, submitting a form) and calls the handleSubmit function.
    // Method Parameters: event: The event object representing the key down event.
    const handleKeyDown = (event) => {
        try {
            if (textInput.length != 0 && !errorShow) {
                setHideToast(true);
                if (event.key === 'Enter') {
                    event.preventDefault();
                    if (fileStatus) {
                        handleFileSubmit();
                    } else {
                        handleTextSubmit();
                    }
                }
            } else {
                console.log("Input cannot be empty");
            }
        } catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "handleKeyDown Function",
                "errorType": "Front End"
            };
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.9
    // The handleSubmit function is responsible for handling user submissions. Depending on the fileStatus variable, it constructs the request body accordingly and sends a request to the server to process the user's input.
    // If the fileStatus is false, indicating that no file is being uploaded, it constructs the request body with basic question information such as the text input, timestamp, and relevant identifiers. It then updates the user message state and sends the request.
    // If the fileStatus is true, indicating that a file is being uploaded, it includes additional information about the uploaded file such as its name, size, and base64 data in the request body. After updating the user message state, it sends the request to the server.
    // Upon receiving a response, it updates the bot's message state based on the response status.
    const handleTextSubmit = async () => {
        try {
            setresponseloader(true);
            var CurrentTimestamp = getCurrentTimestamp();
            let value = { "question": textInput, "date": CurrentTimestamp, "fileKey": 0, "audioKey": 0 };
            setKey(1);
            setUsermessage([...usermessage, value]);
            setHideInput(false);
            setTextInput('');
            var reqBody = {
                "topic": topic,
                "trainingPlannerId": trainingPlannerId,
                "classId": classId,
                "userId": userId,
                "trainingSessionId": trainingSessionId,
                "regenerateKey": 0,
                "editKey": 0,
                "questionKey": 1,
                "question": value
            };

            let result = await getUserInteraction(reqBody);
            { result && setresponseloader(false) }
            if (result.data.status === 200) {
                setBotmessage([...botmessage, result.data.message]);
                setHideInput(true);
                setKey(1);
            }
            else if (result.data.status === 400) {
                let CurrentTimestamp = getCurrentTimestamp();
                let value = { "question": result.data.message, "date": CurrentTimestamp, "regenerate": 0 };
                setBotmessage([...botmessage, value]);
                setHideInput(true);
                setKey(1);
            }
        } catch (error) {
            setLoader(false);
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            };
            setToastProp(toastData);
            setHideToast(false);
            // insertErrorData(reqBody)
        }
    };

    const handleFileSubmit = async () => {
        try {
            setresponseloader(true);
            var CurrentTimestamp = getCurrentTimestamp();
            let value = { "question": textInput, "date": CurrentTimestamp, "fileKey": 1, "fileName": uploadFileName, "audioKey": 0, "filesize": (uploadfileSize / 1024).toFixed(1), "Edit": true };
            setUsermessage([...usermessage, value]);
            setHideInput(false);
            setTextInput('');
            SetFiles([]);
            setShow(false);
            var reqBody = {
                "questionKey": 2,
                "regenerateKey": 0,
                "editKey": 0,
                "trainingPlannerId": trainingPlannerId,
                "userId": userId,
                "classId": classId,
                "trainingSessionId": trainingSessionId,
                "question": value,
                "topic": topic,
                "fileName": uploadFileName,
                "baseValue": base64Data
            };

            let result = await getUserInteraction(reqBody);
            { result && setresponseloader(false) }
            if (result.data.status === 200) {
                setBotmessage([...botmessage, result.data.message]);
                setHideInput(true);
                setKey(1);
                window.location.reload()
            }
            else if (result.data.status === 400) {
                let CurrentTimestamp = getCurrentTimestamp();
                let value = { "question": result.data.message, "date": CurrentTimestamp, "regenerate": 0 };
                setBotmessage([...botmessage, value]);
                setHideInput(true);
                setKey(1);
                window.location.reload()
            }
        } catch (error) {
            setLoader(false);
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            };
            setToastProp(toastData);
            setHideToast(false);
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.10
    // The handleRegenerate function facilitates the regeneration of a question by sending a request to a server endpoint.
    //  Upon invocation, it constructs a request body containing relevant data such as the question to be regenerated, along with identifiers like userId, classId, and trainingSessionId.
    // This request body is then sent to the server using the getUserInteraction function. Upon receiving a response, it updates state variables like botmessage and triggers a re-categorization of messages using the categorizeMessages function.
    // response to regenerate
    const handleRegenerate = async (question, index) => {
        try {
            setRegeneratingIndex(index);
            setHideInput(false)
            setRegenerated(true)
            let reqBody = {
                "questionKey": 1,
                "regenerateKey": 1,
                "editKey": 0,
                "trainingPlannerId": trainingPlannerId,
                "userId": userId,
                "classId": classId,
                "trainingSessionId": trainingSessionId,
                "topic": topic,
                "question": question
            };
            let result = await getUserInteraction(reqBody);
            if (result) {
                setHideInput(true);
                setBotmessage(result.data);
                setKey(1);
                categorizeMessages(result.data, usermessage);
            }
            setRegeneratingIndex(null);
        }
        catch (error) {
            setLoader(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            // insertErrorData(reqBody)
        }
    };


    // Pseudo code number PC_LFS3_1.11
    // updateIndexToTrue for Inline and enableInlineEdit
    // This function updates a specific index within an array of objects by setting a property to true. .
    // It then synchronizes this update with the state variable inlineEditObj, replacing the corresponding object. 
    // The parameters are updateArray (array to update), index (index to modify), and arrState (state variable holding the array).
    const updateIndexToTrue = (updateArray, index, arrState) => {
        if (updateArray && Array.isArray(updateArray)) {
            if (index >= 0 && index < updateArray.length) {
                const key = `index_${index}`;
                updateArray[index][key] = true;
                const foundIndex = inlineEditObj.findIndex(item => item.hasOwnProperty(arrState));
                setInlineEditObj(prev => [...prev.slice(0, foundIndex),
                { ...prev[foundIndex], arrState: updateArray },
                ...prev.slice(foundIndex + 1)])
            }
        }
    }

    // Pseudo code number PC_LFS3_1.12
    // This enableInlineEdit function facilitates enabling inline editing for an element within an array. 
    // It calls updateIndexToTrue to set a specific index within the array to true, thus enabling inline editing. 
    // The parameters are arr (the array to modify), index (the index of the element to enable editing), and arrState (the state variable holding the array).    
    const enableInlineEdit = (arr, index, arrState) => {
        updateIndexToTrue(arr, index, arrState)
    }

    // Pseudo code number PC_LFS3_1.13
    // The handleFileUpload function manages file uploads in the frontend. It captures the uploaded files from the event, adds them to the existing files array, updates the file size and name states accordingly, toggles the display of the file upload component, and then triggers further handling of file input changes.
    // Method Parameters:event: The event object representing the file upload event.
    const handleFileUpload = (event) => {
        try {
            let newFiles = [...event.target.files];
            SetFiles([...files, ...newFiles]);
            setuploadfileSize(newFiles[0]["size"]);
            let FileSize = newFiles[0]["size"] / (1024 * 1024)
            if (FileSize> 23){
                setErrorShow(true)
            }
            setuploadFileName(...uploadFileName, newFiles[0]["name"]);
            setShow(!show);
            handleFileInputChange(event);
        }
        catch (error) {
            // setLoader(false)
            // let toastData = {
            //     toastType: "Negative",
            //     toastHeaderMessage: "Negative",
            //     toastBodyMessage: "handleFileUpload"
            // }
            // setToastProp(toastData)
            // setHideToast(false);
            // insertErrorData(reqBody)
            console.log(error, "error in handleFileUpload");
        }
    };


    // Pseudo code number PC_LFS3_1.14
    // The handleFileUpload function is responsible for managing file uploads
    // It captures the uploaded files from the event and creates a new array containing these files.
    // It concatenates the new files array with the existing files array and updates the state variable files with the combined array.
    // It sets the file size state variable (uploadfileSize) to the size of the first file in the uploaded files array.
    // It sets the file name state variable (uploadFileName) to the name of the first file in the uploaded files array.
    // It toggles the display of the file upload component by toggling the show state variable.
    // It triggers further handling of file input changes by calling the handleFileInputChange function, passing the event object as a parameter.
    // If an error occurs during execution, it catches the error and logs the details for debugging purposes.
    // Method Parameters:event: The event object representing the file upload event.
    const handleFileInputChange = async (event) => {
        try {
            const file = event.target.files[0];
            const { base64Data, fileName, fileType } = await readFileAsBase64(file);
            setbaseData(base64Data);
            setuploadFileName(fileName);
        }
        catch (error) {
            // setLoader(false)
            // let toastData = {
            //     toastType: "Negative",
            //     toastHeaderMessage: "Negative",
            //     toastBodyMessage: "handleFileInputChange Failed"
            // }
            // setToastProp(toastData)
            // setHideToast(false);
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.96
    // This function dynamically binds audio files, represented in Base64 format, to HTML elements using the WaveSurfer library. 
    // It iterates through an array of Base64 encoded audio files, creates WaveSurfer instances for each, and attaches them to specified HTML elements. 
    // The function also displays the duration of each audio file.
    const bindAudio = () => {
        try {
            let tempAudioObj = {}
            audioBase64?.map((element, index) => {
                const currentAudioName = audioName[index];
                tempAudioObj[audioName[index]] = false
                const byteString = atob(element);
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const audioBlob = new Blob([ab], { type: 'audio/mp3' });

                const url = URL.createObjectURL(audioBlob);
                setAudioBind(url)

                const wavesurfer = WaveSurfer.create({
                    container: document.getElementById(`${audioName[index]}`),
                    // src : audioBlob,
                    waveColor: "rgb(200, 0, 200)",
                    progressColor: "rgb(100, 0, 100)",
                    backend: 'MediaElement', // Added to enable the use of the MediaElement backend.
                    barWidth: 2,
                    barHeight: 0,
                    barGap: 1,
                    barRadius: 2,
                    height: 20,
                });
                const newObj = {};
                wavesurfer.load(url)
                wavesurfer.on('ready', function () {
                    const duration = wavesurfer.getDuration();
                    const formattedDuration = formatDuration(duration);
                    const durationDisplayElementId = `duration-${currentAudioName}`;
                    const durationDisplayElement = document.getElementById(durationDisplayElementId);
                    if (durationDisplayElement) {
                        durationDisplayElement.textContent = formattedDuration;
                    }
                    setAudioDurations((prevDurations) => ({
                        ...prevDurations,
                        [currentAudioName]: formattedDuration,
                    }));
                    // Store the WaveSurfer instance with the corresponding audio name
                    wavesurferRefs[currentAudioName] = wavesurfer;
                });
                newObj[audioName[index]] = wavesurfer;
                wavesurferRefs.current = [...wavesurferRefs.current, newObj];
                // return (
                //     <>
                //         <div>
                //             <p className="secondary-color mb-0 font-14">{audioDuration}</p>
                //         </div>
                //     </>
                // );
            });
            setAudioButton(tempAudioObj)
        }
        catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "playAudio Fucntion",
                "errorType": "Front End"
            }
            // insertErrorData(reqBody)
        }
    };

    // Helper function to format duration in a readable format (e.g., "mm:ss")
    const formatDuration = (durationInSeconds) => {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    // Pseudo code number PC_LFS3_1.15
    // This function enables the playback of audio using the WaveSurfer library. 
    // It takes a reference to the audio element (audioRefer) as input. Within a try block, it attempts to fetch the corresponding WaveSurfer instance from the wavesurferRefs.current array using the getValuesByKey function. Once retrieved, it invokes the playPause() method on the WaveSurfer instance to toggle between playing and pausing the audio.
    const playAudio = (audioRefer) => {
        try {
            const obj = getValuesByKey(wavesurferRefs.current, audioRefer);
            { audioButton[audioRefer] ? obj.pause() : obj.play() }
            obj.on('finish', () => {
                setAudioButton({ ...audioButton, [audioRefer]: false });
            });
        }
        catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "playAudio Fucntion",
                "errorType": "Front End"
            }
        }
    };

    // Pseudo code number PC_LFS3_1.16
    // The getValuesByKey function retrieves the value associated with a specified key within an array of objects. .
    // It takes two parameters: array, which is the array of objects to search through, and key, which is the property key to look for within each object. 
    // If the key is found in any object within the array, the corresponding value is returned. 
    function getValuesByKey(array, key) {
        try {
            for (let i = 0; i < array.length; i++) {
                if (key in array[i]) {
                    return array[i][key];
                }
            }
        }
        catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "getValuesByKey Fucntion",
                "errorType": "Front End"
            }
            // insertErrorData(reqBody)
        }
    }

    // Pseudo code number PC_LFS3_1.17
    // The handleCancel function is a callback triggered when canceling an edit action. 
    // It sets the isEditing state to false, indicating that editing mode is no longer active. 
    // Additionally, it calls the categorizeMessages function, passing botmessage and usermessage as parameters to potentially categorize or handle these messages in some way.  
    const handleCancel = () => {
        setIsEditing(false);
        categorizeMessages(botmessage, usermessage)
    };

    // Pseudo code number PC_LFS3_1.18
    // The handleSave function is an asynchronous function responsible for handling the save action in an edit scenario. 
    // It retrieves the previous question based on the edit index, updates state variables accordingly, and then invokes the categorizeMessages function to potentially categorize bot and user messages. 
    // Following this, it prepares a request body containing the edited question along with other relevant data, sends it to a server endpoint using getUserInteraction, and updates the state with the resulting data.
    const handleSave = async () => {
        try {
            // setresponseloader(true)
            setLoader(true)
            let editIndexvalue = extractNumberFromString(editIndex)
            var editStateValue = extractString(editIndex)
            if (editStateValue === 'onemonth') {
                let data = oneMonthAgoMergeMessages[editIndexvalue - 1]
                if (data === undefined && editIndexvalue == 0) {
                    let lastIndex = oneMonthAgoMergeMessages.length - 1;
                    let lastElement = oneMonthAgoMergeMessages[lastIndex];
                    previousQuestion = lastElement.question
                    setlastWeekMergeMessages([])
                }
                else {
                    var previousQuestion = data.question
                }
            }
            else if (editStateValue === 'lastWeek') {
                let data = lastWeekMergeMessages[editIndexvalue - 1]
                if (data === undefined && editIndexvalue == 0) {
                    let lastIndex = oneMonthAgoMergeMessages.length - 1;
                    let lastElement = oneMonthAgoMergeMessages[lastIndex];
                    previousQuestion = lastElement.question
                    setYesterdayMergeMessages([])
                }
                else {
                    var previousQuestion = data.question
                }
            }
            else if (editStateValue === 'yesterday') {
                let data = yesterdayMergeMessages[editIndexvalue - 1]
                if (data === undefined && editIndexvalue == 0) {
                    let lastIndex = lastWeekMergeMessages.length - 1;
                    let lastElement = lastWeekMergeMessages[lastIndex];
                    previousQuestion = lastElement.question
                    setTodayMergedUserMessages([])
                }
                else {
                    var previousQuestion = data.question
                }
            }

            else if (editStateValue === 'today') {
                if (todayMergeMessages.length > 0) {
                    todayMergeMessages.pop(editIndex);
                }
            
                let data = todayMergeMessages[editIndexvalue - 1];
            
                var previousQuestion;
            
                if (todayMergeMessages.length === 0 && yesterdayMergeMessages.length > 0) {
                    // If todayMergeMessages is empty but yesterdayMergeMessages is not
                    previousQuestion = yesterdayMergeMessages[yesterdayMergeMessages.length - 1].question;
                } else if (yesterdayMergeMessages.length === 0) {
                    // If yesterdayMergeMessages is empty
                    if (editIndexvalue === 0) {
                        let removeElement = todayMergeMessages.length - (editIndexvalue + 1);
                        previousQuestion = data ? data.question : "No Question";
                    } else {
                        previousQuestion = data ? data.question : "No Question";
                    }
                } else {
                    // If both arrays have elements
                    if (data === undefined && editIndexvalue == 0) {
                        let lastIndex = yesterdayMergeMessages.length - 1;
                        let lastElement = yesterdayMergeMessages[lastIndex];
                        let removeElement = todayMergeMessages.length - (editIndex + 1);
                        previousQuestion = todayMergeMessages[1] ? todayMergeMessages[1].question : yesterdayMergeMessages[lastIndex].question;
                    } else {
                        previousQuestion = data ? data.question : "No Question";
                    }
                }
            
            }

            setIsEditing(false);
            categorizeMessages(botmessage, usermessage)
            let CurrentTimestamp = getCurrentTimestamp();
            let Questionvalue = { "question": inlineEditText, "date": CurrentTimestamp, "fileKey": 0, "audioKey": 0 };
            let reqBody = {
                "questionKey": 1,
                "regenerateKey": 0,
                "editKey": 1,
                "trainingPlannerId": 1,
                "userId": userId,
                "classId": classId,
                "trainingSessionId": trainingSessionId,
                "question": Questionvalue,
                "topic": topic,
                "previousQuestion": previousQuestion
            }
            let result = await getUserInteraction(reqBody);
            { result && setLoader(false) }
            setUsermessage(result.data.usermessage);
            setBotmessage(result.data.botmessage);
            setFileName(result.data.fileUrlValue);
            setfileUrl(result.data.fileName);
            setAudioName(result.data.audioName);
            setAudioUrl(result.data.audioUrl);
            setaudioBAse64(result.data.audioBase64);
            setKey(1);
            categorizeMessages(botmessage, usermessage);
        } catch (error) {
            setLoader(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.19
    // The handleEditOnChange function is a callback used to update the state variable inlineEditText with the value of the input field when editing a message. 
    // It takes an event object as a parameter, representing the change event triggered by user input. 
    // Inside a try block, it sets the inlineEditText state with the new value extracted from the event target.
    const handleEditOnchange = (event) => {
        try {
            setInlineEditText(event.target.value);
        }
        catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "handleChange Fucntion",
                "errorType": "Front End"
            }
            // insertErrorData(reqBody)
        }
    }

    // Pseudo code number PC_LFS3_1.20
    // The startRecording function is an asynchronous function responsible for initiating audio recording. 
    // It first sets the audioStatus state to true to indicate that recording has started. 
    // Then, it initializes variables for storing audio chunks and speech recognition using the Web Speech API. 
    // As speech is recognized, it updates the live transcript state accordingly.
    // Subsequently, it accesses the user's microphone using the getUserMedia API to capture audio input. 
    // Upon successful access, it sets up a MediaRecorder instance to record audio data. 
    // When recording stops, it processes the recorded audio into a base64 string format, updates relevant state variables, and invokes the handleAudio function with the recorded audio data.
    const startRecording = async () => {
        try {
            setAudioStatus(true)
            audioChunksRef.current = [];
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;
            recognitionRef.current.start();
            recognitionRef.current.onresult = (event) => {
                const fullTranscript = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');
                transcriptRef.current = fullTranscript;
                setLiveTranscript(fullTranscript);
            };

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    mediaRecorderRef.current = new MediaRecorder(stream);
                    mediaRecorderRef.current.ondataavailable = (e) => {
                        audioChunksRef.current.push(e.data);
                    };
                    mediaRecorderRef.current.onstop = async () => {
                        const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                        setLoader(true)
                        const base64String = await blobToAudioBase64(blob);
                        setaudioBAse64([...audioBase64, base64String])

                        setRecoderaudioBase64((prev) => ({ ...prev, base64String }))
                        setAudioStatus(false)
                        handleAudio(base64String)
                    };
                    mediaRecorderRef.current.start();
                })
                .catch((error) => {
                    const data = {
                        error_type: "review component",
                        error_source: "start recording",
                        error_description: error
                    };
                    console.error('Error accessing microphone:', data);
                });
        } catch (error) {
            setLoader(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    }

    // Pseudo code number PC_LFS3_1.21
    // The blobToAudioBase64 function converts a Blob object containing audio data into a base64-encoded string. 
    // It returns a Promise that resolves with the base64 string representation of the audio data.
    // Inside the function, it sets up a FileReader to read the contents of the Blob asynchronously. 
    // Upon successful completion of reading, it extracts the base64 string representation of the data from the FileReader's result and resolves the Promise with it.
    const blobToAudioBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            } catch (e) {
                const errorData = {
                    error_type: "review component",
                    error_source: "blobToAudioBase64",
                    error_description: e
                };
            }
        });
    };

    // Pseudo code number PC_LFS3_1.22
    // The stopRecording function is responsible for stopping the audio recording and speech recognition processes. 
    // It first checks if a MediaRecorder instance exists and is currently recording. If so, it stops the recording.
    //  Additionally, it stops the speech recognition process if it is active.
    const stopRecording = () => {
        try {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
                mediaRecorderRef.current.stop();
            }
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        } catch (e) {
            setLoader(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    }

    // Pseudo code number PC_LFS3_1.23
    // The handleAudio function is an asynchronous function responsible for processing audio data and initiating interactions based on it. 
    // Upon invocation, it resets the live transcript state, generates a unique name for the recorded audio file, and constructs a request body containing relevant data such as the audio file name and its base64 representation.
    // This request body is then sent to a server endpoint using the getUserInteraction function. 
    // Upon successful interaction, the page is reloaded to reflect any updates, and the state variables are updated accordingly.

    async function handleAudio(base) {
        try {
            setLiveTranscript("");
            setresponseloader(true)
            let CurrentTimestamp = getCurrentTimestamp();
            var recorderaudioName = "audio_" + userId + "_" + CurrentTimestamp + ".mp3";
            let value = { "question": "", "date": CurrentTimestamp, "fileKey": 0, "audioKey": 1, "audioName": recorderaudioName };
            setUsermessage([...usermessage, value]);
            audioBase64.push(base)
            let reqBody = {
                "questionKey": 3,
                "regenerateKey": 0,
                "editKey": 0,
                "trainingPlannerId": trainingPlannerId,
                "userId": userId,
                "classId": classId,
                "trainingSessionId": trainingSessionId,
                "question": value,
                "topic": topic,
                "audioName": recorderaudioName,
                "baseValue": base
            }
            let result = await getUserInteraction(reqBody);
            { result && setresponseloader(false) }
            setBindAgain(!bindAgain)
            setAudioBind('')
            window.location.reload()
            setBotmessage(result.data);
            setKey(1);
            categorizeMessages(botmessage, usermessage);
        } catch (error) {
            setLoader(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            // insertErrorData(reqBody)
        }
    }

    // Pseudo code number PC_LFS3_1.26
    // The handleAudioFunction is an asynchronous function that awaits the completion of the stopRecording function. 
    // It's a simple utility function designed to ensure that the recording process is properly halted.
    const handleAudioFunction = async () => {
        let duration = getTimeDifferenceInSeconds(currentTimeOne, curentTimeTwo);
        setAudioStatus(prevState => ({
            ...prevState,
            duration: duration
        }));
        await stopRecording();
    };

    // Pseudo code number PC_LFS3_1.27
    // The fileButton function is used to render a file upload button or a link to a file depending on the message.Edit property. If message.Edit is false, indicating that the file is being uploaded, it displays a button with a link to download the file. If message.Edit is true, indicating that the file has already been uploaded, it displays a button with the file name.
    // Here's a breakdown of the function:
    // It takes message as a parameter, which contains information about the file.
    // It increments the filecount variable to keep track of the number of files rendered.
    // It renders a container with the file icon and file information.
    // If message.Edit is false, it renders a button with a link to download the file using the fileUrl and fileName properties.
    // If message.Edit is true, it renders a button with the file name from the message.fileName property.
    let filecount = -1;
    const fileButton = (message) => {
        filecount++;
        return (
            <div className="upload-container custom-border rounded-2 px-3 py-2">
                <div className="d-flex justify-content-between align-items-start gap-3">
                    <div className="d-flex gap-4">
                        <img src="images/file-icon.svg" alt="file-icon" />
                        <div className="d-inline-block text-start">
                            <p className="font-medium font-14 primary-color mb-1">
                                {!message['Edit'] ? (
                                    <>
                                        <a href={fileUrl[filecount]} download={fileName[filecount]}><button className="button p-0 filebutton bg-transparent border-0 primary-color">{fileName[filecount]}</button></a>
                                        <p className="font-regular font-12 secondary-color mb-0">{message['filesize']}KB</p>
                                    </>
                                ) : (
                                    <>
                                        <button className="filebutton bg-transparent border-0 primary-color">{message.fileName}</button>
                                        <p className="font-regular font-12 secondary-color mb-0">{(uploadfileSize / 1024).toFixed(1)}KB</p>
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>);
    };

    // Pseudo code number PC_LFS3_1.24
    // The categorizeMessages function organizes bot responses and user messages based on their respective dates. 
    // It creates arrays to store messages from today, yesterday, last week, and one month ago. 
    // Each message is categorized based on its date and formatted for display.
    // The function iterates through user and bot messages, compares their dates to determine their category, and adds them to the corresponding arrays. 
    // Afterward, it merges user messages and bot responses for each time frame, updating state variables to reflect the changes.
    const categorizeMessages = (bot, user) => {

        try {
            const currentDate = new Date();
            const today = new Date(currentDate.toISOString().split('T')[0]);
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7);
            const oneMonthAgo = new Date(today);
            oneMonthAgo.setMonth(today.getMonth() - 1);

            // Create empty array to store bot respone and user message based on the date and time
            const todayUserMessages = [];
            const todayBotMessages = [];
            const yesterdayUserMessages = [];
            const yesterdayBotMessages = [];
            const lastWeekUserMessages = [];
            const lastWeekBotMessages = [];
            const oneMonthAgoUserMessages = [];
            const oneMonthAgoBotMessages = [];

            // categorize Messages user message 
            user.forEach(message => {
                const messageDate = new Date(message.date);
                const formattedDate = formatDateTime(messageDate);
                if (messageDate.toISOString().split('T')[0] === today.toISOString().split('T')[0]) {
                    todayUserMessages.push({ ...message, date: formattedDate });
                } else if (messageDate.toISOString().split('T')[0] === yesterday.toISOString().split('T')[0]) {
                    yesterdayUserMessages.push({ ...message, date: formattedDate });
                } else if (messageDate >= lastWeek) {
                    lastWeekUserMessages.push({ ...message, date: formattedDate });
                } else if (messageDate >= oneMonthAgo) {
                    oneMonthAgoUserMessages.push({ ...message, date: formattedDate });
                }
            });

            // categorize Messages bot Response 
            bot.forEach(message => {
                const messageDate = new Date(message.date);
                const formattedDate = formatDateTime(messageDate);
                if (messageDate.toISOString().split('T')[0] === today.toISOString().split('T')[0]) {
                    todayBotMessages.push({ ...message, date: formattedDate });
                } else if (messageDate.toISOString().split('T')[0] === yesterday.toISOString().split('T')[0]) {
                    yesterdayBotMessages.push({ ...message, date: formattedDate });
                } else if (messageDate >= lastWeek) {
                    lastWeekBotMessages.push({ ...message, date: formattedDate });
                } else if (messageDate >= oneMonthAgo) {
                    oneMonthAgoBotMessages.push({ ...message, date: formattedDate });
                }
            });

            // merge the bot respone and user question based today date
            let todaymerge = [];
            let todaymaxLength = Math.max(todayUserMessages.length, todayBotMessages.length);
            let tempTodayMsgArr = []
            let todayIndex = 0
            for (let i = 0; i < todaymaxLength; i++) {
                if (i < todayUserMessages.length) {
                    let tempObj = {}
                    todaymerge.push(todayUserMessages[i]);
                    tempObj[`index_${todayIndex}`] = false
                    tempTodayMsgArr.push(tempObj)
                    todayIndex++
                }
                if (i < todayBotMessages.length) {
                    let tempObj = {}
                    todaymerge.push(todayBotMessages[i]);
                    tempObj[`index_${todayIndex}`] = false
                    tempTodayMsgArr.push(tempObj)
                    todayIndex++
                }
            }
            // After Merge the today user message and bot response, update the state Variable
            setTodayMergedUserMessages(todaymerge);

            // merge the bot respone and user question based yesterday date
            let yesterdayMerge = [];
            let YesterdaymaxLength = Math.max(yesterdayUserMessages.length, yesterdayBotMessages.length);
            let tempyesterdayMsgArr = []
            let yesterdayIndex = 0;
            for (let i = 0; i < YesterdaymaxLength; i++) {
                if (i < yesterdayUserMessages.length) {
                    let tempObj = {}
                    yesterdayMerge.push(yesterdayUserMessages[i]);
                    tempObj[`index_${yesterdayIndex}`] = false
                    tempyesterdayMsgArr.push(tempObj)
                    yesterdayIndex++
                }
                if (i < yesterdayBotMessages.length) {
                    let tempObj = {}
                    yesterdayMerge.push(yesterdayBotMessages[i]);
                    tempObj[`index_${yesterdayIndex}`] = false
                    tempyesterdayMsgArr.push(tempObj)
                    yesterdayIndex++
                }
            }

            // After Merge the yesterDay user message and bot response, update the state Variable
            setYesterdayMergeMessages(yesterdayMerge);

            // merge the bot respone and user question based last week date
            let lastWeekMerge = [];
            let lastweekmaxLength = Math.max(lastWeekUserMessages.length, lastWeekBotMessages.length);
            let templastWeekMsgArr = []
            let lastWeekIndex = 0
            for (let i = 0; i < lastweekmaxLength; i++) {
                if (i < lastWeekUserMessages.length) {
                    let tempObj = {}
                    lastWeekMerge.push(lastWeekUserMessages[i]);
                    tempObj[`index_${lastWeekIndex}`] = false
                    templastWeekMsgArr.push(tempObj)
                    lastWeekIndex++
                }
                if (i < lastWeekBotMessages.length) {
                    let tempObj = {}
                    lastWeekMerge.push(lastWeekBotMessages[i]);
                    tempObj[`index_${lastWeekIndex}`] = false
                    templastWeekMsgArr.push(tempObj)
                    lastWeekIndex++
                }
            }
            // After Merge the Last week user message and bot response, update the state Variable
            setlastWeekMergeMessages(lastWeekMerge);

            // merge the bot respone and user question based one month
            let OneMonthAgoMerge = [];
            let OneMonthAgomaxLength = Math.max(oneMonthAgoUserMessages.length, oneMonthAgoBotMessages.length);
            let tempOneMonthMsgArr = []
            let oneMonthIndex = 0
            for (let i = 0; i < OneMonthAgomaxLength; i++) {
                if (i < oneMonthAgoUserMessages.length) {
                    let tempObj = {}
                    OneMonthAgoMerge.push(oneMonthAgoUserMessages[i]);
                    tempObj[`index_${oneMonthIndex}`] = false
                    tempOneMonthMsgArr.push(tempObj)
                    oneMonthIndex++
                }
                if (i < oneMonthAgoBotMessages.length) {
                    let tempObj = {}
                    OneMonthAgoMerge.push(oneMonthAgoBotMessages[i]);
                    tempObj[`index_${oneMonthIndex}`] = false
                    tempOneMonthMsgArr.push(tempObj)
                    oneMonthIndex++
                }
            }

            setInlineEditObj([{
                lastWeek: templastWeekMsgArr
            }, {
                yesterday: tempyesterdayMsgArr
            }, {
                oneMonth: tempOneMonthMsgArr
            }, {
                today: tempTodayMsgArr
            }])

            // After Merge the month age user message and bot response, update the state Variable
            setoneMonthAgoMergeMessages(OneMonthAgoMerge);

        }
        catch (error) {
            let reqBody = {
                'errorDescription': error.message,
                "errorSource": "categorizeMessages Fucntion",
                "errorType": "Front End"
            }
            // insertErrorData(reqBody)
        }
    };

    // Pseudo code number PC_LFS3_1.25
    // This function returns a React component responsible for displaying a question template UI. 
    // It renders a set of questions along with their corresponding answers.
    // Within the question template section, each question from the question array is mapped to a button element. 
    // The button displays the question text and, upon clicking, triggers the handleQuestionTemplate function with the question text as an argument.
    // The question array is expected to contain objects with question and answer properties, which are used to populate the buttons and display additional information upon clicking.
    function DisplayQuestionTemplate() {
        return (
            <div className="row justify-content-center font-medium mb-5">
                <div className="col-md-8 col-lg-6 my-5 mt-0">
                    <div className="text-center">
                        <span className="chart-logo-container p-1 d-inline-block">
                            <img className="chart-logo" src="images/Logo.svg" alt="logo" />
                        </span>
                        <p className="font-20 mt-3 primary-color">How can I help you?</p>
                    </div>
                    <div className="row text-start my-0">

                        {/* Question Template */}
                        <div className="row">

                            {question?.map((faq, index) => (
                                <div key={index} className="col-md-6 p-2">
                                    <button onClick={() => { handleQuestionTemplate(faq.question); }} type='button' className="p-3 h-100 w-100 ques-box d-block text-decoration-none">
                                        <span className="primary-color">{faq.question}</span>
                                        <p className="font-12 secondary-color mt-1 mb-0">{faq.answer}</p>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    // Pseudo code number PC_LFS3_1.33
    // This function returns a React component responsible for displaying a heading with an optional back arrow and a topic title.
    function DisplayHeading() {
        return (
            <div className="p-4 d-flex align-items-center">
                <a href="/trainee">
                    <img
                        className="me-2 custom-arrow-color"
                        src="images/back-arrow-white.svg"
                        alt="backward-arrow" />
                </a>
                <h4 className="font-18 font-semibold mb-0 primary-color">{topic}</h4>
            </div>
        )
    }

    // Pseudo code number PC_LFS3_1.34
    // This function returns a React component responsible for rendering the top navigation bar of the application. 
    // It provides navigation links, user profile information, and options for changing the theme and logging out.
    function DisplaytopNavigationBar() {
        return <NavBarComponent></NavBarComponent>
    }


    // Pseudo code number PC_LFS3_1.35
    // This function returns a React component responsible for displaying an input field where users can enter their questions. 
    // It also provides options for users to upload files or record voice inputs.
    // Below the input field, there's an option to upload files. Clicking on the file upload icon triggers the file upload functionality, allowing users to select files from their device. The selected file name and size are displayed next to the upload icon.
    // Additionally, there's a voice recording option represented by a microphone icon. Clicking on the microphone icon toggles between starting and stopping voice recording. When recording is active, the microphone icon changes to indicate recording is in progress.
    // Overall, this function generates a user interface for entering questions, uploading files, and recording voice inputs.
    function DisplayInputField() {
        return (
            // need to update .............................
            // <div className="row justify-content-center mt-4 mb-4 ">
            <>
                <div className="row justify-content-center fixed-bottom pt-4 pb-4 cust-input-bg-color">
                    <div className="col-md-10">
                        <div className="d-flex align-items-center">
                            <div className="w-100  align-items-center ques-input-box-shadow me-3 p-2 textarea-container common-card-theme-bg custom-border" >
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex flex-column w-100'>
                                        <textarea
                                            className="w-100 custom-text-area ques-input-textarea textarea-box common-card-theme-bg border-0 "
                                            placeholder="Enter your questions here"
                                            defaultValue={""}
                                            value={liveTranscript ? liveTranscript : textInput}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            disabled={!hideInput}
                                        />
                                        {show ? <div className="upload-container w-min-content custom-border rounded-2 px-3 py-2">
                                            <div className="d-flex justify-content-between align-items-start gap-3">
                                                <div className="d-flex gap-4">
                                                    <img src="images/file-icon.svg" alt="file-icon" />
                                                    <div className="d-inline-block">
                                                        <p className="font-medium font-14 primary-color mb-1 text-truncate">
                                                            {uploadFileName}
                                                        </p>
                                                        <p className="font-regular font-12 secondary-color mb-0">{(uploadfileSize / 1024).toFixed(1)}KB</p>
                                                    </div>
                                                </div>
                                                <img
                                                    src="images/popup-close-icon.svg"
                                                    alt="delete-icon"
                                                    className="close-ico+n cursor-pointer"
                                                    onClick={(e) => { SetFiles([]); setShow(false); setErrorShow(false)}} />
                                            </div>
                                        </div>
                                            : <></>}
                                    </div>
                                    <button className="border-0 btn cursor-pointer">
                                        <label htmlFor="fileUpload" className="file-upload border-0">
                                            <img src="images/link.svg" alt="link-image" className="cursor-pointer" />
                                            <input id="fileUpload" type="file" className="d-none"
                                                accept=".csv,.json,.xlsx,.pdf,.html,.txt,.py,.css,.jsx,.js,.java,.cpp,.c,.sql,.word,.pptx,.ts,.tsx,.ipynb,.rs,.swift,.go,.rb,.php,.pl,.hs,.scala,.erl,.lisp,.ml,.scm,.cs,.m,.kt,.coffee,.dart,.f90,.tcl,.vhd,.lua,.png,.jpeg,.jpg"
                                                onChange={(e) => {
                                                    setShow(!show);
                                                    handleFileUpload(e);
                                                    setFileStatus(true)
                                                }} disabled={!hideInput} />

                                        </label>
                                    </button>
                                </div>
                            </div>

                            {audioStatus ? <button className="border-0 btn cursor-pointer voice-rec-btn" onClick={() => { handleAudioFunction(); setAudioStatus(false) }} >
                                <img src="images/voice-record-icon.svg"
                                    alt="record-hold-icon" />
                            </button> :
                                <button className="voice-icon custom-border ques-input-box-shadow" onClick={() => {
                                    setAudioStatus(true)
                                    !audioStatus ? startRecording() : handleAudioFunction()
                                }} disabled={!hideInput}>
                                    <img
                                        className="voice-icon-image"
                                        src="images/voice-icon-gradient.svg"
                                        alt="voiceicon"
                                    />
                                </button>
                            }


                        </div>
                        {errorShow &&
                            <span className="mt-2 font-regular font-14 mandatory d-flex align-items-center"><img src="images/error-msg.svg" alt="Error" className="me-2 mb-1" />It seems the file size exceeds 23MB</span>}
                    </div>

                </div> </>
        );
    }

    // Pseudo code number PC_LFS3_1.28
    // This function returns a React component responsible for displaying messages from one month ago. 
    // It includes a list of messages with options for inline editing, saving changes, cancelling edits, and audio playback.
    function DisplayoneMonthAgeMessage() {
        return (
            <div className="row flex-column align-items-center" >
                <div className="text-center secondary-color">
                    <p>One Month</p>
                </div>
                <div className="col-md-10">
                    {oneMonthAgoMergeMessages?.map((message, index) => (
                        <div key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="px-4 d-flex justify-content-end">
                                        <div className="pt-3 pe-2 chat-wrapper edit-options-wrapper edit-option-wrapper cust-audio-chat-width">
                                            <div className="p-3 chat-text common-card-theme-bg custom-border shadow-sm position-relative d-flex ">
                                                {message.fileKey === 1 ? (
                                                    <p className="font-13 primary-color m-0 font-regular">
                                                        {message.question}
                                                        {fileButton(message)}
                                                    </p>
                                                ) : (
                                                    <p className="font-13 primary-color m-0 font-regular">
                                                        {message.question}
                                                    </p>
                                                )}
                                                {message.audioKey === 1 && (
                                                    <>
                                                        <div className='cust-audio-width'>
                                                            <div id={message.audioName} className='w-100 cust-audio-div d-flex flex-row'>
                                                                {audioButton[message.audioName] ? (
                                                                    <img
                                                                        src="images/pause-icon.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="pause-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: false }) }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="images/play-icon-colored.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="play-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: true }) }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p id={message.audioName} className="secondary-color mb-0 font-14 d-flex align-items-center ms-2">
                                                            {audioDurations[message.audioName]}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-end">
                                                <span className="font-12 secondary-color text-end p-2">
                                                    {message.date}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        {userProfile ? <img
                                            src={userProfile}
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        /> :
                                            <img
                                            src="images/sample-profile.png"
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        />}
                                    </div>
                                    <p></p>
                                </>
                            ) : (
                                <>
                                    <div className="px-4 pt-1">
                                        <div className="d-flex flex-row chat-lfs-logo-parent">
                                            <span className="chat-lfs-logo chat-lfs-logo-position">
                                                <img
                                                    src="images/chat-lfs-logo.svg"
                                                    alt="Profile-picture"
                                                    className="profile-img"
                                                />
                                            </span>
                                            <div className="pt-3 ps-3 chat-wrapper-alt refresh-option-wrapper">
                                                {regeneratingIndex === index ? (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative">
                                                        <div className="w-50 shimmer-chat-loader shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                    </div>
                                                ) : (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative d-flex">
                                                        <span className="m-0 font-semibold font-14 primary-color">
                                                            <p className="font-13 primary-color m-0 font-regular">
                                                                {message.question}
                                                                {message.regerenate}
                                                            </p>
                                                        </span>
                                                        {message.regenerate < 3 &&
                                                            (message.question !== "Please, Ask the Question related to Topic" &&
                                                            message.question !== "Please upload the Image related to Topic." &&
                                                            message.question !== "Unsupported file format" &&
                                                            message.question !== "Please upload the file related to Topic" &&
                                                            message.question !== "Please record the valid audio" &&
                                                            message.question !== "Please record the audio related to the topic.") && (
                                                            <div className="position-absolute chat-refresh-option">
                                                                <img
                                                                    src="images/refresh-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 me-1 shadow-sm common-card-theme-bg custom-border cursor-pointer"
                                                                    alt="refresh-icon"
                                                                    onClick={() => { handleRegenerate(message.question, index); }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div className="p-2">
                                                    <span className="font-12 secondary-color">{message.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    // Pseudo code number PC_LFS3_1.29
    // This function returns a React component responsible for displaying messages from Last Week. 
    // It includes a list of messages with options for inline editing, saving changes, cancelling edits, and audio playback.
    function DisplayLastWeekMessage() {
        return (
            <div className="row flex-column align-items-center" >
                <div className="text-center secondary-color">
                    <p>Last Week</p>
                </div>
                <div className="col-md-10">
                    {lastWeekMergeMessages?.map((message, index) => (
                        <div key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="px-4 d-flex justify-content-end">
                                        <div className="pt-3 pe-2 chat-wrapper edit-options-wrapper edit-option-wrapper">
                                            <div className="p-3 chat-text common-card-theme-bg custom-border shadow-sm position-relative d-flex ">
                                                {message.fileKey === 1 ? (
                                                    <p className="font-13 primary-color m-0 font-regular">
                                                        {message.question}
                                                        {fileButton(message)}
                                                    </p>
                                                ) : (
                                                    <p className="font-13 primary-color m-0 font-regular">
                                                        {message.question}
                                                    </p>
                                                )}
                                                {message.audioKey === 1 && (
                                                    <>
                                                        <div className='cust-audio-width'>
                                                            <div id={message.audioName} className='w-100 cust-audio-div d-flex flex-row'>
                                                                {audioButton[message.audioName] ? (
                                                                    <img
                                                                        src="images/pause-icon.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="pause-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: false }) }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="images/play-icon-colored.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="play-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: true }) }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p id={message.audioName} className="secondary-color mb-0 font-14 d-flex align-items-center ms-2">
                                                            {audioDurations[message.audioName]}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-end">
                                                <span className="font-12 secondary-color text-end p-2">
                                                    {message.date}
                                                </span>
                                            </div>
                                        </div>
                                        {userProfile ? <img
                                            src={userProfile}
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        /> :
                                            <img
                                            src="images/sample-profile.png"
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        />}
                                    </div>
                                    <p></p>
                                </>
                            ) : (
                                <>
                                    <div className="px-4 pt-1">
                                        <div className="d-flex flex-row chat-lfs-logo-parent">
                                            <span className="chat-lfs-logo chat-lfs-logo-position">
                                                <img
                                                    src="images/chat-lfs-logo.svg"
                                                    alt="Profile-picture"
                                                    className="profile-img"
                                                />
                                            </span>
                                            <div className="pt-3 ps-3 chat-wrapper-alt refresh-option-wrapper">
                                                {regeneratingIndex === index ? (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative">
                                                        <div className="w-50 shimmer-chat-loader shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                    </div>
                                                ) : (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative d-flex">
                                                        <span className="m-0 font-semibold font-14 primary-color">
                                                            <p className="font-13 primary-color m-0 font-regular">
                                                                {message.question}
                                                                {message.regerenate}
                                                            </p>
                                                        </span>
                                                        {message.regenerate < 3 &&
                                                            (message.question !== "Please, Ask the Question related to Topic" &&
                                                            message.question !== "Please upload the Image related to Topic." &&
                                                            message.question !== "Unsupported file format" &&
                                                            message.question !== "Please upload the file related to Topic" &&
                                                            message.question !== "Please record the valid audio" &&
                                                            message.question !== "Please record the audio related to the topic.") && (
                                                            <div className="position-absolute chat-refresh-option">
                                                                <img
                                                                    src="images/refresh-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 me-1 shadow-sm common-card-theme-bg custom-border cursor-pointer"
                                                                    alt="refresh-icon"
                                                                    onClick={() => { handleRegenerate(message.question, index); }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div className="p-2">
                                                    <span className="font-12 secondary-color">{message.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    // Pseudo code number PC_LFS3_1.30
    // This function returns a React component responsible for displaying messages from Yesterday. 
    // It includes a list of messages with options for inline editing, saving changes, cancelling edits, and audio playback.

    function DisplayYesterdayMessage() {
        return (
            <div className="row flex-column align-items-center" >
                <div className="text-center secondary-color">
                    <p>Yesterday</p>
                </div>
                <div className="col-md-10">
                    {yesterdayMergeMessages?.map((message, index) => (
                        <div key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="px-4 d-flex justify-content-end">
                                        <div className="pt-3 pe-2 chat-wrapper edit-options-wrapper edit-option-wrapper">
                                            <div className="p-3 chat-text common-card-theme-bg custom-border shadow-sm position-relative d-flex color-change">
                                                {message.fileKey === 1 ? (
                                                    <p className="font-13 primary-color m-0 font-regular">
                                                        {message.question}
                                                        {fileButton(message)}
                                                    </p>
                                                ) : (
                                                    <p className="font-13 primary-color m-0 font-regular">
                                                        {message.question}
                                                    </p>
                                                )}
                                                {message.audioKey === 1 && (
                                                    <>
                                                        <div className='cust-audio-width'>
                                                            <div id={message.audioName} className='w-100 cust-audio-div d-flex flex-row'>
                                                                {audioButton[message.audioName] ? (
                                                                    <img
                                                                        src="images/pause-icon.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="pause-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: false }) }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="images/play-icon-colored.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="play-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: true }) }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p id={message.audioName} className="secondary-color mb-0 font-14 d-flex align-items-center ms-2">
                                                            {audioDurations[message.audioName]}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-end">
                                                <span className="font-12 secondary-color text-end p-2">
                                                    {message.date}
                                                </span>
                                            </div>
                                        </div>
                                        {userProfile ? <img
                                            src={userProfile}
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        /> :
                                            <img
                                            src="images/sample-profile.png"
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        />}
                                    </div>
                                    <p></p>
                                </>
                            ) : (
                                <>
                                    <div className="px-4 pt-1">
                                        <div className="d-flex flex-row chat-lfs-logo-parent">
                                            <span className="chat-lfs-logo chat-lfs-logo-position">
                                                <img
                                                    src="images/chat-lfs-logo.svg"
                                                    alt="Profile-picture"
                                                    className="profile-img"
                                                />
                                            </span>
                                            <div className="dark-theme pt-3 ps-3 chat-wrapper-alt refresh-option-wrapper">
                                                {regeneratingIndex === index ? (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative d-flex ">
                                                        <div className="w-50 shimmer-chat-loader shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                    </div>
                                                ) : (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative d-flex">
                                                        <span className="m-0 font-semibold font-14 primary-color">
                                                            <p className="font-13 primary-color m-0 font-regular">
                                                                {message.question}
                                                                {message.regerenate}
                                                            </p>
                                                        </span>
                                                        {message.regenerate < 3 &&
                                                            (message.question !== "Please, Ask the Question related to Topic" &&
                                                            message.question !== "Please upload the Image related to Topic." &&
                                                            message.question !== "Unsupported file format" &&
                                                            message.question !== "Please upload the file related to Topic" &&
                                                            message.question !== "Please record the valid audio" &&
                                                            message.question !== "Please record the audio related to the topic.") && (
                                                            <div className="position-absolute chat-refresh-option">
                                                                <img
                                                                    src="images/refresh-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 me-1 shadow-sm common-card-theme-bg custom-border cursor-pointer"
                                                                    alt="refresh-icon"
                                                                    onClick={() => { handleRegenerate(message.question, index); }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div className="p-2">
                                                    <span className="font-12 secondary-color">{message.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    // Pseudo code number PC_LFS3_1.31
    // This function returns a React component responsible for displaying messages from today. 
    // It includes a list of messages with options for inline editing, saving changes, cancelling edits, and audio playback.

    function DisplayTodayMessage() {
        
        // CHANGES MADE: Added this function to check if a message is one of the last two
        const isLastTwoMessages = (index, messages) => {
            return index >= messages.length - 4;
        };
    
        return (
            <div className="row flex-column align-items-center" >
                <div className="text-center secondary-color">
                    <p>Today</p>
                </div>
                <div className="col-md-10" >
                    {todayMergeMessages?.map((message, index) => (
                        <div key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="px-4 d-flex justify-content-end">
                                        <div className="pt-3 pe-2 chat-wrapper  edit-options-wrapper edit-option-wrapper">
                                            <div className="p-3 chat-text common-card-theme-bg custom-border shadow-sm position-relative d-flex ">
                                                {message.fileKey === 1 ? (
                                                    <>
                                                        {/* CHANGES MADE: Added isLastTwoMessages check */}
                                                        {inlineEditObj[3]?.today[index][`index_${index}`] && isLastTwoMessages(index, todayMergeMessages) ? (
                                                            <>
                                                                <div>
                                                                    <input className="font-13 primary-color m-0 font-regular" defaultValue={message.question} type='text'
                                                                        onChange={handleEditOnchange}></input>
                                                                    {fileButton(message)}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="font-13 primary-color m-0 font-regular ">
                                                                    {message.question}
                                                                    {fileButton(message)}
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* CHANGES MADE: Added isLastTwoMessages check */}
                                                        {inlineEditObj[3]?.today[index][`index_${index}`] && isLastTwoMessages(index, todayMergeMessages) ? (
                                                            <>
                                                                <div>
                                                                    <input defaultValue={message.question} type='text' className="cust-edit-input"
                                                                        onChange={handleEditOnchange} ></input>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="font-13 primary-color m-0 font-regular common-card-theme-bg cust-edit-input">
                                                                    {message.question}
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {message.audioKey === 1 ? (
                                                    <>
                                                        <div className='cust-audio-width'>
                                                            <div id={message.audioName} className='w-100 cust-audio-div d-flex flex-row'>
                                                                {audioButton[message.audioName] ? (
                                                                    <img
                                                                        src="images/pause-icon.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="pause-icon"
                                                                        onClick={() => {
                                                                            playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: false })
                                                                        }}
                                                                    />
                                                                ) :
                                                                    <img
                                                                        src="images/play-icon-colored.svg"
                                                                        className="cust-play-img cursor-pointer me-2 w-100"
                                                                        alt="play-icon"
                                                                        onClick={() => { playAudio(message.audioName); setAudioButton({ ...audioButton, [message.audioName]: true }) }} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <p id={message.audioName} className="secondary-color mb-0 font-14 d-flex align-items-center ms-2">
                                                            {audioDurations[message.audioName]}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* CHANGES MADE: Modified edit button rendering logic */}
                                                        {!inlineEditObj[3]?.today[index][`index_${index}`] && isLastTwoMessages(index, todayMergeMessages) ? (
                                                            <div className="position-absolute chat-edit-option ">
                                                                <img
                                                                    src="images/table-edit-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 me-1 shadow-sm cursor-pointer"
                                                                    alt={`index_${index}`}
                                                                    id={index + 'today'}
                                                                    onClick={(e) => {
                                                                        enableInlineEdit(inlineEditObj[3].today, index, 'today')
                                                                        setEditIndex(e.target.id)
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : inlineEditObj[3]?.today[index][`index_${index}`] && isLastTwoMessages(index, todayMergeMessages) ? (
                                                            <div className='position-absolute chat-edit-option'>
                                                                <img
                                                                    src="images/tick-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 me-1 shadow-sm cursor-pointer"
                                                                    alt="tick-icon"
                                                                    id={index + 'index'}
                                                                    onClick={() => { handleSave(); }}
                                                                />
                                                                <img
                                                                    src="images/close-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 shadow-sm cursor-pointer"
                                                                    alt="close-icon"
                                                                    onClick={handleCancel}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-end">
                                                <span className="font-12 secondary-color text-end p-2">
                                                    {message.date}
                                                </span>
                                            </div>
                                        </div>
                                        {userProfile ? <img
                                            src={userProfile}
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        /> :
                                            <img
                                            src="images/sample-profile.png"
                                            alt="Profile-picture"
                                            className="employee-img profile-img align-self-end"
                                        />}
                                    </div>
                                    <p></p>
                                </>
                            ) : (
                                <>
                                    <div className="px-4 pt-1">
                                        <div className="d-flex flex-row chat-lfs-logo-parent">
                                            <span className="chat-lfs-logo chat-lfs-logo-position">
                                                <img
                                                    src="images/chat-lfs-logo.svg"
                                                    alt="Profile-picture"
                                                    className="profile-img" />
                                            </span>
                                            <div className="pt-3 ps-3 chat-wrapper-alt refresh-option-wrapper">
                                                {regeneratingIndex === index ? (
    
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative">
                                                        <div className="w-50 shimmer-chat-loader shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                        <div className="w-100 shimmer-chat-loader loader-large shimmer" />
                                                    </div>
    
                                                ) : (
                                                    <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative d-flex">
                                                        <span className="m-0 font-semibold font-14 primary-color">
                                                            <p className="font-13 primary-color m-0 font-regular">
                                                                {message.question}
                                                                {message.regerenate}
                                                            </p>
                                                        </span>
                                                        {message.regenerate < 3 &&
                                                            (message.question !== "Please, Ask the Question related to Topic" &&
                                                                message.question !== "Please upload the Image related to Topic." &&
                                                                message.question !== "Unsupported file format" &&
                                                                message.question !== "Please upload the file related to Topic" &&
                                                                message.question !== "Please record the valid audio" &&
                                                                message.question !== "Please record the audio related to the topic.") ? (
                                                            <div className="position-absolute chat-refresh-option">
                                                                <img src="images/refresh-icon.svg"
                                                                    className="chat-wrapper-icon mb-2 me-1 shadow-sm common-card-theme-bg custom-border cursor-pointer"
                                                                    alt="refresh-icon"
                                                                    onClick={() => { handleRegenerate(message.question, index); }} />
                                                            </div>
                                                        ) : (null)}
                                                    </div>
                                                )}
                                                <div className="p-2">
                                                    <span className="font-12 secondary-color">{message.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    {responseLoader && (
                        <div className="d-flex flex-row align-items-end">
                            <span className="chat-lfs-logo">
                                <img
                                    src="images/chat-lfs-logo.svg"
                                    alt="Profile-picture"
                                    className="profile-img"
                                />
                            </span>
                            <div className="pt-3 ps-3 chat-wrapper-alt w-30 refresh-option-wrapper">
                                <div className="p-3 chat-box common-card-theme-bg custom-border shadow-sm position-relative">
                                    <div className="w-50 shimmer-chat-loader loader shimmer" />
                                    <div className="w-100 shimmer-chat-loader loader-large loader shimmer" />
                                    <div className="w-100 shimmer-chat-loader loader-large loader shimmer" />
                                    <div className="w-100 shimmer-chat-loader loader-large loader shimmer" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    
    // Pseudo code number PC_LFS3_1.32
    // This function is a conditional rendering function that displays different components based on the value of the key variable.
    // If key is equal to 0, it returns a layout consisting of a top navigation bar, a container with question templates, and an input field.
    // If key is equal to 1, it returns a layout with a top navigation bar, a container containing message components for different time periods (one month ago, last week, yesterday, and today), and an input field.
    // The displayOutput function is responsible for rendering different layouts based on the value of the key variable, facilitating the display of different sections of the user interface.
    function displayOutput() {
        if (key === 0) {
            return (
                <div>
                    {DisplaytopNavigationBar()}
                    <div className="container-xxxl ">
                        {DisplayHeading()}
                        {DisplayQuestionTemplate()}
                        {DisplayInputField()}
                    </div>
                </div>
            );
        }
        if (key === 1) {
            return (
                <>
                    {DisplaytopNavigationBar()}
                    <div className="container-fluid ">
                        <div className='row flex-column h-75vh'>
                            {DisplayHeading()}
                            <div className="chat-container" ref={innerChatContainerRef}>
                                {oneMonthAgoMergeMessages?.length > 0 && (
                                    DisplayoneMonthAgeMessage()
                                )}
                                {lastWeekMergeMessages?.length > 0 && (
                                    DisplayLastWeekMessage()
                                )}
                                {yesterdayMergeMessages?.length > 0 && (
                                    DisplayYesterdayMessage()
                                )}
                                {todayMergeMessages?.length > 0 && (
                                    DisplayTodayMessage())}
                            </div>
                            {DisplayInputField()}
                        </div>
                    </div>
                </>
            );
        }
    }

    // returns a conditional rendering of either a loading component (<Loading />) or the result of the displayOutput() function, based on the value of the loader variable.
    return (
        <>
            <div>
                {loader ? <Loader /> : (
                    <div>
                        {displayOutput()}
                    </div>
                )}

            </div>
            {hideToast ? <></> :
                <ToastComponent toastObj={toastProp} />
            }
        </>
    );
}
export default ChatBot; 