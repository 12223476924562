import React, { useEffect, useState } from 'react';

// PC_TC_03 - PC_TC_15 This ToastKb component is responsible for displaying a toast message. It uses state to manage the visibility of the toast The useEffect hook is used to handle these state changes. If isVisible is false, the component returns null, effectively hiding the toast.

const ToastComponent = ({ toastObj }) => {

    return (
        <>
            {toastObj.toastType === 'Negative' ?
                <div className="position-fixed top-0 start-50 translate-middle-x">
                    <div id="liveToast" className="toast warning-toast-bg border-0 show top-0" role="alert" aria-live="assertive"
                        aria-atomic="true">
                        <div className="toast-body border-0 d-flex align-items-center">
                            <img src="images/warning-icon.svg" className="me-3" alt="tick-icon" />
                            <p className="font-medium font-14 primary-color mb-0">{toastObj.toastBodyMessage}</p>
                        </div>
                    </div>
                </div> :
                <div className="position-fixed top-0 start-50 translate-middle-x">
                    <div id="liveToast" className="toast knowledge-uploaded-toast knowledge-uploaded-toast-bg border-0 show top-0" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-body border-0 d-flex align-items-center">
                            <img src="images/tick-icon-white.svg" className="me-3" alt="tick-icon" />
                            <p className="font-medium font-14 color-white mb-0">{toastObj.toastBodyMessage}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ToastComponent;