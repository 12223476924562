import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useLocation } from "react-router-dom";
import { getUpdateJwt } from '../../../Service/AdminApi/adminLoginApi';
import ToastComponent from '../KnowledgeBase/toast';
import { Modal, Button } from 'react-bootstrap';

const AdminNavbar = ({ activity, setActivity }) => {

  const tempState = localStorage.getItem('jwt')
  const [state, setState] = useState(jwtDecode(tempState))
  const [isChecked, setIsChecked] = useState(false);  // State to manage checkbox
  const [colorTheme, setColorTheme] = useState('light');  // State to manage theme
  const [showModal, setShowModal] = useState(false);

  let profilePicture = localStorage.getItem('profilePhotoUrl')
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 5000);
  }, [hideToast]);

  let userName = state.userName
  let userRole = state.accessLevel
  let userId = state.userId

  let routing = useNavigate()

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleCloseModal = () => setShowModal(false);

  const handleLogout = async () => {
    try {
      let reqBody = {
        "jwt": tempState
      }
      let response = await getUpdateJwt(reqBody)
      if (response.data.data == "Updated Jwt") {
        localStorage.removeItem('jwt')
        routing("/")
        localStorage.clear()
        sessionStorage.clear()
      } else {
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
      }
    } catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  }

  const toggleTheme = () => {
    setIsChecked(!isChecked);
    const newTheme = isChecked ? "light" : "dark";
    setColorTheme(newTheme);
    document.documentElement.setAttribute("data-theme", `${newTheme}-theme`);

    // Remove existing theme class
    const bodyClasses = document.body.classList;
    bodyClasses.remove('light-theme');
    bodyClasses.remove('dark-theme');

    // Add new theme class
    document.body.classList.add(`${newTheme}-theme`);
    localStorage.setItem("selectedTheme", newTheme);
  };

  useEffect(() => {
    const selectedTheme = localStorage.getItem("selectedTheme");
    if (selectedTheme) {
      setIsChecked(selectedTheme === "dark");
      setColorTheme(selectedTheme);
      document.documentElement.setAttribute("data-theme", `${selectedTheme}-theme`);
      const bodyClasses = document.body.classList;
      bodyClasses.remove('light-theme');
      bodyClasses.remove('dark-theme');
      document.body.classList.add(`${selectedTheme}-theme`);
    }
  }, []);


  const [theme, setTheme] = useState(false);

  return (
    <>
      <nav className="navbar navbar-expand-md body-bg py-3 pb-2 px-2 custom-border">
        <div className="container-fluid">
          <a
            className="navbar-brand font-bold font-16 d-block d-md-none primary-color"
          >
            <img src="images/Logo.svg" alt="brand-logo" className="me-2" />
            LFS
          </a>
          <button
            className="navbar-toggler collapsed cust-hamburger"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample04"
            aria-controls="navbarsExample04"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="navbar-collapse collapse d-md-flex justify-content-between"
            id="navbarsExample04"
          >
            <a
              className="navbar-brand font-bold font-16 d-none d-md-block me-0 primary-color"
              href="/adminLanding"
              style={{ cursor: "pointer" }}
            >
              <img src="images/Logo.svg" alt="brand-logo" className="me-2" />
              LFS
            </a>
            <ul className="navbar-nav mb-2 mb-md-0 gap-md-4 mt-3 mt-md-0 ms-3">
              <li className="nav-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActivity({
                    overview: 'active',
                    kb: '',
                    traingPlan: '',
                    userManagement: ''
                  })
                  sessionStorage.setItem('previousTab', 'overview')
                }}>
                <a
                  className={`nav-link font-14 top-nav-link ${activity['overview']} d-flex align-items-center`}

                >
                  <img
                    src="images/overview-active.svg"
                    alt="Overview"
                    className="me-2 header-icon-color"
                  />
                  <img
                    src="images/overview-icon.svg"
                    alt="Overview"
                    className="me-2"
                  />
                  Overview
                </a>
              </li>
              <li className="nav-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  sessionStorage.setItem('previousTab', 'kb')
                  setActivity({
                    overview: '',
                    kb: 'active',
                    traingPlan: '',
                    userManagement: ''
                  })
                }}>
                <a
                  className={`nav-link font-14 top-nav-link ${activity['kb']} d-flex align-items-center`}

                >
                  <img
                    src="images/knowledge-base-active.svg"
                    alt="Knowledge"
                    className="me-2 header-icon-color"
                  />
                  <img
                    src="images/knowledge-base-icon.svg"
                    alt="Knowledge"
                    className="me-2"
                  />
                  Knowledge Base
                </a>
              </li>
              <li className="nav-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  sessionStorage.setItem('previousTab', 'trainingPlan')
                  setActivity({
                    overview: '',
                    kb: '',
                    traingPlan: 'active',
                    userManagement: ''
                  })
                }}>
                <a
                  className={`nav-link font-14 top-nav-link ${activity['traingPlan']} d-flex align-items-center`}
                >
                  <img
                    src="images/trainingplan-active.svg"
                    alt="Training Plan"
                    className="me-2 header-icon-color"
                  />
                  <img
                    src="images/training-plan-icon.svg"
                    alt="Training Plan"
                    className="me-2"
                  />
                  Training Plan
                </a>
              </li>
              <li className="nav-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  sessionStorage.setItem('previousTab', 'userManagement')
                  setActivity({
                    overview: '',
                    kb: '',
                    traingPlan: '',
                    userManagement: 'active'
                  })
                }} >
                <a
                  className={`nav-link font-14 top-nav-link ${activity['userManagement']} d-flex align-items-center`}
                >
                  <img
                    src="images/user-management-active.svg"
                    alt="User Management"
                    className="me-2 header-icon-color"
                  />
                  <img
                    src="images/user-management-icon.svg"
                    alt="User Management"
                    className="me-2"
                  />
                  User Management
                </a>
              </li>
            </ul>
            <span className="border-bottom d-block w-100 d-block d-md-none mb-3" />
            <ul className="list-unstyled mb-0 d-md-flex gap-4 d-grid align-items-center">
              {/*Mobile Styles starts here*/}
              <li className=" d-md-none">
                <span className="d-flex align-items-center gap-2 p-3 pt-2 border-bottom">
                  {profilePicture ? (
                    <img src={profilePicture} alt="Profile Picture" className="me-2 circular-image" width="36" height="36" />
                  ) : (
                    <img src="images/Logo.svg" alt="brand-logo" className="me-2" />
                  )}
                  <span className="d-grid">
                    <span className="text-nowrap font-semibold font-14 primary-color">
                      {state?.userName ? state?.userName : 'user'}
                    </span>
                    <span className="text-nowrap font-regular font-10 secondary-color">
                      {state?.role ? state?.role : 'Software Engineer'}
                    </span>
                  </span>
                </span>
                <div className="p-3 border-bottom d-flex justify-content-between">
                  <div className="font-14 font-medium primary-color">Theme</div>
                  {/* <div>
                    <input type="checkbox" className="checkbox" id="chk" />
                    <label className="label me-2" htmlFor="chk">
                      <img src="images/moon.svg" alt="Moon" className="switch-img" onClick={() => {
                        setColorTheme()
                      }} />
                      <img src="images/sun.svg" alt="Sun" className="switch-img" onClick={() => {
                        setColorTheme()
                      }} />
                      <div className="ball" />
                    </label>
                  </div> */}
                  <div>
                    <input type="checkbox" className="checkbox" id="chk" />
                    <label className="label me-2" htmlFor="chk">
                      <img src="images/moon.svg" alt="Moon" className="switch-img" onClick={() => { setColorTheme() }} />
                      <img src="images/sun.svg" alt="Sun" className="switch-img" onClick={() => { setColorTheme() }} />
                      <div className="ball" />
                    </label>
                  </div>
                </div>
                <div className="p-3">
                  <button className="body-bg border-0 font-14 font-medium text-red text-decoration-none" >
                    Logout
                  </button>
                </div>

              </li>
              {/*Mobile Styles Ends here*/}
              <li>
                <span className="dropdown  d-none d-md-block">
                  <span className="nav-link p-0" data-bs-toggle="dropdown">
                    <span className="font-medium font-14 secondary-color profile-dropdown cursor-pointer">
                      {state.userName ? state.userName : 'user'}
                    </span>
                    <span className="ps-1 cursor-pointer">
                      <img
                        src="images/profile-dropdown-arrow-white.svg"
                        alt="dropdown-icon"
                        className="custom-arrow-color"
                      />
                    </span>
                  </span>
                  <span className="dropdown-menu body-bg w-200 dropdown-menu-end border-0 profile-pic-dropdown mt-2" onClick={stopPropagation}>
                    {/* Profile section omitted for brevity */}
                    <span className="d-flex align-items-center gap-2 p-3 pt-2 profile-border-bottom">
                      {profilePicture ? (
                        <img src={profilePicture} alt="Profile Picture" className="me-2 circular-image" width="36" height="36" />
                      ) : (
                        <img src="images/Logo.svg" alt="brand-logo" className="me-2" />
                      )}
                      <span className="d-grid">
                        <span className="text-nowrap font-semibold font-14 primary-color">
                          {userName}
                        </span>
                        <span className="text-nowrap font-regular font-10 secondary-color">
                          {userRole}
                        </span>
                      </span>
                    </span>
                    <div className="p-3 profile-border-bottom  d-flex justify-content-between">
                      <div className="font-14 font-medium primary-color">Theme</div>
                      <div>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="theme-switch"
                          checked={isChecked}
                          onChange={toggleTheme}  // Handle change
                        />
                        <label className="label me-2" htmlFor="theme-switch">
                          <img src="images/moon.svg" alt="Moon" className="switch-img" />
                          <img src="images/sun.svg" alt="Sun" className="switch-img" />
                          <div className="ball" />
                        </label>
                      </div>
                    </div>
                    <div className="p-3 pb-2">
                      <button
                        className="body-bg border-0 font-14 font-medium text-red text-decoration-none"
                        onClick={() => setShowModal(true)}
                      >
                        Logout
                      </button>
                    </div>
                    <Modal show={showModal} onHide={handleCloseModal} >
                      <Modal.Header closeButton>
                        <Modal.Title >
                          <img src="images/logout-icon.svg" alt="upload-icon" className="me-3" />
                          <span className="modal-title primary-color font-semibold font-22">Logout</span>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="modal-body border-0 p-0 ps-md-5 ms-md-4">
                          <p className="font-regular font-18 secondary-color mb-0">
                            Are you sure you want to logout?
                          </p>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="modal-footer border-0 mt-5 p-0 gap-2">
                          <button type="button" className="btn secondary-btn small-btn custom-border color-white font-20 font-semibold m-0" onClick={handleCloseModal}>
                            No
                          </button>
                          <button type="button"
                            className="btn delete-btn small-btn font-20 font-semibold m-0"
                            onClick={handleLogout}>
                            Yes
                          </button>

                        </div>
                      </Modal.Footer>
                    </Modal>

                    {/* Logout section omitted for brevity */}
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp} />
      }
    </>

  )
}

export default AdminNavbar;