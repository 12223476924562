import React, { useEffect, useState } from 'react';
import { getAssignBatch, getSubBatch, getEdit } from '../../../Service/AdminApi/Usermanagement';
import ToastComponent from './ToastComponent';
import { jwtDecode } from 'jwt-decode';

//PC-FPP-50 - PC-FPP-79
//Function to render the edit batch popup with required values and fields setRefreshGrid, setSelectedUsers, selectedUsers, show, togglePopup,selectedBatchName
export function EditBatchPopup({ setRefreshGrid, setSelectedUsers, selectedUsers, show, togglePopup, selectedBatchName }) {
  const [batchName, setBatchName] = useState(selectedBatchName || '');
  const [subBatchName, setSubBatchName] = useState('');
  const [batchNameError, setBatchNameError] = useState('');
  const [subBatchNameError, setSubBatchNameError] = useState('');
  const [subBatchOptions, setSubBatchOptions] = useState([]);
  const [loading, setLoading] = useState(true)

  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 5000);
  }, [hideToast]);
  useEffect(() => {
    fetchSubBatch();
  }, []);

  //PC-FPP-56 - PC-FPP-61
  //function to handle the edit operations
  const handleEdit = async () => {
    try {
      // Check if selectedUsers is defined and not empty
      if (!selectedUsers || selectedUsers.length === 0) {
        setBatchNameError('Please select at least one user');
        return;
      } else {
        setBatchNameError('');
      }

      // Validation for batch name
      if (!batchName.trim()) {
        setBatchNameError('Please enter Batch Name');
        return;
      } else {
        setBatchNameError('Batch Edited Successfully');
      }

      let jwt = localStorage.getItem('jwt')
      const payload = jwtDecode(jwt);
      const userName = payload.userName;

      // Request body for API call
      const requestBody = {
        batch_name: batchName,
        sub_batch_name: subBatchName,
        selected_users: [...selectedUsers],
        created_by: userName
      };

      // Call the API to assign batch
      const response = await getEdit(requestBody)
      if (response[1] === 200) {
        setBatchNameError('Batch Updated Successfully');
        setLoading(false)
        let toastData = {
          toastType: "Positive",
          toastHeaderMessage: "Positive",
          toastBodyMessage: "Batch Updated Successfully"
        }

        setLoading(true)
        // setTimeout(() => {
        //   window.location.reload();
        //   }, 1000);
        setToastProp(toastData)
        setHideToast(false);
      }
      else {
        let toastData = {
          toastType: "Positive",
          toastHeaderMessage: "Positive",
          toastBodyMessage: "Batch Edited Successfully !"
        }
        setToastProp(toastData)
        setHideToast(false);
        // setBatchNameError('Error occurred while assigning batch:');
      }

      // Refresh grid after batch assignment
      setRefreshGrid(true);

      // Reset input fields
      setBatchName('');
      setSubBatchName([]);

      // Update the selected user IDs using setSelectedUsers
      setSelectedUsers([]);
      setLoading(false)

      // setTimeout(() => {
      //   window.location.reload();
      //   }, 3000); 

      // Close the popup
      togglePopup();

    } catch (error) {
      // console.error('Error occurred while assigning batch:', error);
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
      // setSubBatchNameError('Error occurred while assigning batch. Please try again later.');
    }
  };
  //PC-FPP-137- PC-FPP-142
  //Function to handle the batch name change and to hold them in the text field

  const handleBatchNameChange = (e) => {
    const value = e.target.value;
    setBatchName(value);
    if (value.trim()) {
      setBatchNameError('');
    }
  };
  //PC-FPP-150 -PC-FPP-174
  //function to fetch sub batch data

  const fetchSubBatch = async () => {
    try {
      const response = await getSubBatch();
      setSubBatchOptions(response);
    } catch (error) {
    
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
      setSubBatchNameError('Error occurred while fetching sub-batch data. Please try again later.');
    }
  };
  //PC-FPP-173
  //Function to get the sub batch option which is clicked

  const handleSubBatchClick = (subBatchName) => {
    setSubBatchName(subBatchName);
  };
  //Function to handle the close operations
  const handleClose = () => {
    togglePopup();
  };

  return (
    <div>
      {/* {show && ( */}
      <div class="modal fade" id="editBatch" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div className="modal-dialog kb-upload-popup px-3 py-3">
          <div className="modal-content body-bg">
            <div className="modal-header bg-transparent border-0 pb-0 px-4 pt-4 mx-2 d-flex align-items-center">
              <span className="upload-icon-style me-3">
                <img src="images/assign-batch-icon-white.svg" alt="upload-icon" className="custom-arrow-color" />
              </span>
              <h5 className="modal-title primary-color font-semibold font-22" id="exampleModalLabel">
                Edit Batch
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                <img src="images/popup-close-icon.svg" alt="close-icon" />
              </button>
            </div>
            <div className="modal-body bg-transparent border-0 font-16">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label className="font-semibold font-16 mb-2 primary-color" htmlFor="batch-name">
                    Batch Name<span className="mandatory ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control custom-form-control font-medium font-16 common-card-theme-bg custom-border"
                    name="batch-name"
                    placeholder="Enter batch name"
                    value={batchName}
                    onChange={handleBatchNameChange}
                  />
                  {batchNameError && <span className="text-danger">{batchNameError}</span>}
                </div>
                <div className="col-md-12 mb-4">
                  <label className="font-semibold font-16 mb-2 primary-color" htmlFor="subgroup-name">
                    Subgroup Name
                  </label>
                  <div className="dropdown topics-dropdown">
                    <span className="action-drop-down d-flex">
                      <button
                        id="batches"
                        className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <span className="font-regular font-14 primary-color">{subBatchName || 'Select'}</span>
                        <span className="ps-1">
                          <img src="images/down-arrow.svg" alt="dropdown-icon" />
                        </span>
                      </button>
                      <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow">
                        {subBatchOptions?.map((option) => (
                          <span
                            key={option.sub_batch_id}
                            className={`d-flex list cursor-pointer p-2 primary-color ${subBatchName === option.sub_batch_name ? 'selected' : ''}`}
                            onClick={() => handleSubBatchClick(option.sub_batch_name)}
                          >
                            {option.sub_batch_name}
                          </span>
                        ))}
                      </span>
                    </span>
                  </div>
                  {subBatchNameError && <span className="text-danger">{subBatchNameError}</span>}
                </div>
              </div>
            </div>
            <div className="modal-footer bg-transparent border-0 mt-2 mb-3 px-4 gap-2">
              <button type="button" className="btn secondary-btn color-white font-20 custom-border font-semibold m-0" data-bs-dismiss="modal" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn primary-btn font-20 font-semibold m-0" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleEdit()}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp}  />
      }
      {/* )} */}
    </div>
  );
}
