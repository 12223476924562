const ToastComponent = ({ toastObj }) => {
    return (
        <>
            {toastObj.toastType === 'Negative' ?
                <div className="position-fixed top-0 start-50 translate-middle-x">
                    <div id="liveToast" className="toast warning-toast-bg border-0 show top-0" role="alert" aria-live="assertive"
                        aria-atomic="true">
                        <div className="toast-body border-0 d-flex align-items-center">
                            <img src="images/warning-icon.svg" className="me-3" alt="tick-icon" />
                            <p className="font-medium font-14 primary-color mb-0">{toastObj.toastBodyMessage}</p>
                        </div>
                    </div>
                </div> : <></>}
        </>
    )
}

export default ToastComponent;