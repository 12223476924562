import React, { useState, useEffect } from 'react';
import { getUsersDetails, getSubBatch, getBatch, getDomain, getInsert } from '../../../Service/AdminApi/Usermanagement'; // Import all APIs from a single file
import Toast from './Tooast';
import Popup from './Popup';
import { Modal, Button } from 'react-bootstrap';
import { EditBatchPopup } from './editPopup';
import { EditSubBatchPopup } from './editSubPopup';
import Loader from './loader';
import { UserProfile } from '../UserProfile/userProfile';
import ToastComponent from './ToastComponent';


//PC-FGC-08 -PC-FGC-230
//FUNCTION TO RENDER THE USERMANAGEMENT GRID WITH DATA
export default function UserManagement() {
  // State variables
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setPopup] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [filters, setFilters] = useState({
    grade: '',
    domain: '',
    joiningDate: '',
    employeeType: '',
    batchName: '',
    subBatch: '',
    joiningDateFrom: '',
    joiningDateTo: ''
  });
  const [grade, setGrade] = useState('');
  const [domain, setDomain] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [employeeType, setEmployeeType] = useState('');
  const [batchName, setBatchName] = useState('');
  const [selectedBatchName, setSelectedBatchName] = useState('');
  const [subBatch, setSubBatch] = useState('');
  const [joiningDateFrom, setJoiningDateFrom] = useState('');
  const [joiningDateTo, setJoiningDateTo] = useState('');
  const [subBatchData, setSubBatchData] = useState('');
  const [domainData, setDomainData] = useState('');
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [sortColumn, setSortColumn] = useState('user_id');
  const [sortDirection, setSortDirection] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [isAssignedToBatch, setIsAssignedToBatch] = useState(false);
  const [isAssignedToSubBatch, setIsAssignedToSubBatch] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showUserPreview, setshowUserPreview] = useState(false)
  const [sortOrder, setSortOrder] = useState('asc');
  const [UserpreviewId, setUserpreviewId] = useState(null)
  const [userPreviewBatch, setUserPreviewBatch] = useState("")
  const [previewgrade, setpreviewGrade] = useState("")
  const noRecordStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '250px', // Adjust height as needed
    margin: '50px'
  };
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  useEffect(() => {
    console.log("ToastValue ", showToast);
  }, [showToast])

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 5000);
  }, [hideToast]);

  //PC-FGC-15- PC-FGC-17
  // Fetch user data from backend api
  //searchQuery, filters, refreshGrid, sortColumn, sortDirection, showPopup these are passed as an dependency 
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const requestBody = {
          search: searchQuery,
          filterGrade: grade,
          filterDomain: domain,
          filterEmployeeType: employeeType,
          filterBatchName: batchName,
          filterSubBatchName: subBatch,
          filterJoiningFrom: joiningDateFrom,
          filterJoiningTo: joiningDateTo,
          sortBy: sortColumn,
          sortOrder: sortDirection
        };
        //Api call here///////////////////////////////////////////////////////////

        const response = await getUsersDetails(requestBody);
        setUsers(response); // Update users state
        { response && setLoading(false) }
        setRefreshGrid(false);
        setUserCount(response.length);
      } catch (error) {
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
        // Log error to database and console
      }
    };

    fetchUsersData();
  }, [searchQuery, filters, refreshGrid, sortColumn, sortDirection, showPopup]);


  const insertUsers = async () => {
    try {
      setLoading(true)
      let req_body = {
        "data": null
      }
      const response = await getInsert(req_body);
      { response && setLoading(false) }
      setRefreshGrid(false);
    } catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  };



  //PC-FGC-177 - PC-FGC-203
  // Function to fetch the subbatch data from the database
  useEffect(() => {
    const fetchSubBatchData = async () => {
      try {
        //Api call here///////////////////////////////////////////////////////////
        let req_body = {
          "data": null
        }
        const response = await getSubBatch(req_body);
        if (response != "No data found") {
          setSubBatchData(response);
        }
        // Update subBatchData state
        setRefreshGrid(false);
        // setLoading(false)
      } catch (error) {

        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
        // Log error to database and console
      }
    };

    fetchSubBatchData();
  }, []);


  //PC-FGC-150 - PC-FGC-176
  // Function to fetch the batch data from the database
  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        let req_body = {
          "data": null
        }
        const response = await getBatch(req_body);
        if (response != "No data found") { setBatchData(response); } // Update batchData state
        setRefreshGrid(false);
      } catch (error) {
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
      }
    };

    fetchBatchData();
  }, []);

  //PC-FGC-204 - PC-FGC-230
  // Function to fetch the domain data from the database
  useEffect(() => {
    const fetchDomainData = async () => {
      try {
        let req_body = {
          "data": null
        }
        const response = await getDomain(req_body);
        if (response != "No data found") {

          setDomainData(response);
        } // Update domainData state
        setRefreshGrid(false);
      } catch (error) {
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
      }
    };

    fetchDomainData();
  }, []);



  const handleCheckboxChange = (userId) => {
    try {
      setSelectedUsers((prevSelectedUsers) => {
        const updatedSelectedUsers = prevSelectedUsers.includes(userId)
          ? prevSelectedUsers.filter((id) => id !== userId)
          : [...prevSelectedUsers, userId];

        const assignedToBatch = updatedSelectedUsers.some((id) => {
          const user = users.find((user) => user.user_id === id);
          return user?.batch_name !== null && user?.batch_name !== '';
        });

        const assignedToSubBatch = updatedSelectedUsers.some((id) => {
          const user = users.find((user) => user.user_id === id);
          return user?.sub_batch_name !== null && user?.sub_batch_name !== '';
        });

        setIsAssignedToBatch(assignedToBatch);
        setIsAssignedToSubBatch(assignedToSubBatch);

        // Check if any selected user has a batch name
        const selectedUserWithBatchName = users.find(
          (user) => updatedSelectedUsers.includes(user.user_id) && user.batch_name
        );

        // If a user with batch name is found, set the selected batch name
        if (selectedUserWithBatchName) {
          setSelectedBatchName(selectedUserWithBatchName.batch_name);
        } else {
          // If no user with batch name is selected, clear the selected batch name
          setSelectedBatchName('');
        }

        // Determine whether to show the toast
        const showToast = updatedSelectedUsers?.length > 0;
        setShowToast(showToast);

        return updatedSelectedUsers;
      });

      setPopup(true);
    }
    catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  };

  //PC-FGC-44 -PC-FGC-54
  // Function to handle the select all functionalities for checkboxes
  const handleSelectAll = () => {
    try {
      if (!selectAll) {
        const allUserIds = users?.map(user => user.user_id);
        setSelectedUsers(allUserIds);
        setSelectAll(true);
        setShowToast(true)

        setPopup(users?.length > 0);
      } else {
        setSelectedUsers([]);
        setSelectAll(false);
        setPopup(false);
        setShowToast(false)
      }
    } catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  }
  //PC-FGC-44 -PC-FGC-54
  // Handle unselect all checkboxes and hide popup
  const handleUnselectAll = () => {
    try {
      setSelectedUsers([]);
      setSelectAll(false);
      if (selectedUsers.length === 0) {
        setPopup(false); // Hide popup when all checkboxes are unselected
      }
    }
    catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  };
  //PC-FGC-64-PC-FGC-88
  // Function to handle the search functionalities
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  //Function to handle the popup visibilitiy
  const togglePopup = () => {
    setPopup(!showPopup);
  };
  //PC-FGC-88 - PC-FGC-114
  // Function to handle the filter value change 
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };
  //PC-FGC-92
  //Function to reset all the filter values to the initial values and cancel the selected values
  const handleCancel = () => {
    setGrade("");
    setDomain("");
    setEmployeeType("");
    setBatchName("");
    setSubBatch("");
    setJoiningDateFrom("");
    setJoiningDateTo("");
    handleApplyFilter()
  };
  //PC-FGC-92
  //Function to apply the filter values and make a call to api by passing them as an request body
  const handleApplyFilter = async () => {
    const requestBody = {
      batchName: batchName,
      subBatchName: subBatch,
      grade: grade,
      domain: domain,
      employeeType: employeeType,
      joiningDateFrom: joiningDateFrom,
      joiningDateTo: joiningDateTo
    };
    setLoading(true)
    setFilters(requestBody);
    setShowModal(false); // Close the modal after applying filters
    handleClose();
  };
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Define selectedUsersCount
  const selectedUsersCount = selectedUsers.length;
  //   setTimeout(() => {
  //     setLoading(false);
  //     window.location.reload();
  // }, 2000);

  //PC-FGC-126 - PC-FGC-149
  // Handle column header click for sorting
  const handleSortClick = (columnName) => {
    // Toggle sort direction if the same column is clicked
    const newSortDirection = sortColumn === columnName ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(columnName);
    setSortDirection(newSortDirection);
    setSortOrder(newSortOrder);
  };
  const callUserPreview = (userID, batch, grade) => {
    // debugger
    setshowUserPreview(true)
    setUserpreviewId(userID)
    setUserPreviewBatch(batch)
    setpreviewGrade(grade)

  }
  const getBadgeColor = (grade) => {
    switch (grade) {
      case 'A+':
        return 'custom-grade-badge custom-badge-green';
      case 'A-':
        return 'custom-grade-badge custom-badge-green';
      case 'B+':
        return 'custom-grade-badge custom-badge-orange';
      case 'B-':
        return 'custom-grade-badge custom-badge-orange';
      case 'C+':
        return 'custom-grade-badge custom-badge-red';
      case 'C-':
        return 'custom-grade-badge custom-badge-red';
      default:
        return 'custom-grade-badge custom-badge-gray';
    }
  }
  const getBatchBadgeClass = (name) => {
    return name ? 'custom-badge text-nowrap custom-badge-violet' : 'custom-grade-badge text-nowrap custom-badge-gray';
  };
  const getSubBatchBadgeClass = (name) => {
    return name ? 'custom-badge text-nowrap custom-badge-green' : 'custom-grade-badge text-nowrap custom-badge-gray';
  };
  let currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  return (
    <div>
      <div className="container-xxxl">
        <div className="row">
          {loading ? <Loader /> : showUserPreview == false ? (
            <div className="col-md-12">
              <div className=" common-bg px-4 py-4 mt-3 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-3 pb-3 flex-wrap gap-3 ">
                  <h2 className="font-semibold mb-0 font-20 primary-color">
                    User Management (<span>{userCount}</span>)
                  </h2>
                  <div className="d-flex gap-3 small-device-100 flex-wrap">
                    <div className="d-flex gap-3 flex-md-row small-device-100">
                      <div className="input-group input-search">
                        <input
                          type="search"
                          value={searchQuery}
                          onChange={handleSearch}
                          className="form-control cust-search-input common-bg border-end-0 font-14 font-regular custom-search"
                          placeholder="Search"
                        />
                        <button
                          className="btn border-start-0 custom-search"
                          type="button"
                        >
                          <img src="images/search-icon.svg" alt="search" />
                        </button>
                      </div>
                      <button
                        className="btn secondary-outline-btn shadow-sm custom-filter custom-border"
                        onClick={handleShow}
                      >
                        <img src="images/filter-icon.svg" alt="filter-icon" />
                      </button>
                      <Modal show={showModal} onHide={handleClose} className="custom-right-modal-usm" backdrop={false}>
                        <Modal.Header closeButton>
                          <Modal.Title className='font-semibold'>Advanced Filter</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row d-flex flex-wrap">
                            {/*  */}
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="environment"
                              >
                                Grade
                              </label>
                              <div className="dropdown topics-dropdown">
                                <button
                                  id="batches"
                                  className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <span className="font-regular font-14 secondary-color">
                                    {grade || 'Select'}
                                  </span>
                                  <span className="ps-1">
                                    <img
                                      src="images/down-arrow.svg"
                                      alt="dropdown-icon"
                                    />
                                  </span>
                                </button>
                                <div className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow cust-drpdn-scroll">
                                  <span className="d-flex list cursor-pointer p-2 primary-color" data-name="grade" key='A+' onClick={() => setGrade('A+')}>
                                    A+
                                  </span>
                                  <span className="d-flex list cursor-pointer p-2 primary-color" data-name="grade" key='A-' onClick={() => setGrade('A-')}>
                                    A-
                                  </span>
                                  <span className="d-flex list cursor-pointer p-2 primary-color" data-name="grade" key='B+' onClick={() => setGrade('B+')}>
                                    B+
                                  </span>
                                  <span className="d-flex list cursor-pointer p-2 primary-color" data-name="grade" key='B-' onClick={() => setGrade('B-')}>
                                    B-
                                  </span>
                                  <span className="d-flex list cursor-pointer p-2 primary-color" data-name="grade" key='C+' onClick={() => setGrade('C+')}>
                                    C+
                                  </span>
                                  <span className="d-flex list cursor-pointer p-2 primary-color" data-name="grade" key='C-' onClick={() => setGrade('C-')}>
                                    C-
                                  </span>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="status"
                              >
                                Domain
                              </label>
                              <div className="dropdown topics-dropdown">
                                <span className="action-drop-down d-flex">
                                  <button
                                    id="batches"
                                    className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <span className="font-regular font-14 secondary-color">
                                      {domain || 'Select'}

                                    </span>
                                    <span className="ps-1">
                                      <img
                                        src="images/down-arrow.svg"
                                        alt="dropdown-icon"
                                      />
                                    </span>
                                  </button>
                                  <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow cust-drpdn-scroll">
                                    {domainData && domainData?.map((value) => (
                                      <span className="d-flex list cursor-pointer p-2 primary-color" onClick={() => setDomain(value.domain_name)}>
                                        {value.domain_name}
                                      </span>
                                    ))}

                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="status"
                              >
                                Employee Type
                              </label>
                              <div className="dropdown topics-dropdown">
                                <span className="action-drop-down d-flex">
                                  <button
                                    id="batches"
                                    className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <span className="font-regular font-14 secondary-color">
                                      {employeeType || 'Select'}
                                    </span>
                                    <span className="ps-1">
                                      <img
                                        src="images/down-arrow.svg"
                                        alt="dropdown-icon"
                                      />
                                    </span>
                                  </button>
                                  <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow cust-drpdn-scroll">
                                    <span className="d-flex list cursor-pointer p-2 primary-color" key='Full-Time' onClick={() => setEmployeeType('Full-Time')}>
                                      Full-Time
                                    </span>
                                    <span className="d-flex list cursor-pointer p-2 primary-color" key='Intern' onClick={() => setEmployeeType('Intern')}>
                                      Intern
                                    </span>

                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="status"
                              >
                                Batch Name
                              </label>
                              <div className="dropdown topics-dropdown">
                                <span className="action-drop-down d-flex">
                                  <button
                                    id="batches"
                                    className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <span className="font-regular font-14 secondary-color">
                                      {batchName || 'Select'}
                                    </span>
                                    <span className="ps-1">
                                      <img
                                        src="images/down-arrow.svg"
                                        alt="dropdown-icon"
                                      />
                                    </span>
                                  </button>
                                  <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow cust-drpdn-scroll">
                                    {batchData && batchData?.map((value) => (
                                      <span key={value.batch_id} className="d-flex list cursor-pointer p-2 primary-color" onClick={() => setBatchName(value.batch_name)}>
                                        {value.batch_name}
                                      </span>
                                    ))}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="status"
                              >
                                Subgroup
                              </label>

                              <div className="dropdown topics-dropdown">
                                <span className="action-drop-down d-flex">
                                  <button
                                    id="batches"
                                    className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"

                                  >
                                    <span className="font-regular font-14 secondary-color">
                                      {subBatch || 'Select'}
                                    </span>

                                    <span className="ps-1">
                                      <img
                                        src="images/down-arrow.svg"
                                        alt="dropdown-icon"
                                      />
                                    </span>
                                  </button>
                                  <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow cust-drpdn-scroll">
                                    {subBatchData ? (
                                      subBatchData.map((value) => (
                                        <span
                                          
                                          className="d-flex list cursor-pointer p-2 primary-color"
                                          onClick={() => setSubBatch(value.sub_batch_name)}
                                        >
                                          {value.sub_batch_name}
                                        </span>
                                      ))
                                    ) : (
                                      <span>No Sub Group found !</span>
                                    )}
                                  </span>
                                </span>
                              </div>
                            </div>
                            {/*  */}
                            <div className="d-block">
                              <h5 className="form-label mb-3 font-semibold primary-color">
                                Date
                              </h5>
                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="from"
                              >
                                From
                              </label>
                              {/* <input
                                className="form-control custom-form dropdown-text-color custom-date common-card-theme-bg custom-border"
                                type="date"
                                id="from"
                                placeholder="MM/DD/YYYY"
                                name="joiningDateFrom"
                                value={joiningDateFrom}
                                onChange={(event) => setJoiningDateFrom(event.target.value)}
                              /> */}

                              <input
                                className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                type="date"
                                id="from"
                                placeholder="MM/DD/YYYY"
                                value={joiningDateFrom}
                                onChange={(event) => { setJoiningDateFrom(event.target.value); setJoiningDateTo("") }}
                              // max={filtertodate}
                              />

                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color custom-header-color"
                                htmlFor="to"
                              >
                                To
                              </label>
                              {/* <input
                                className="form-control custom-form dropdown-text-color custom-date common-card-theme-bg custom-border"
                                type="date"
                                id="to"
                                placeholder="MM/DD/YYYY"
                                value={joiningDateTo}
                                //onChange={(event) => setJoiningDateTo(event.target.value)}
                                onChange={(event) => {
                                  // Check if the selected 'to' date is before the 'from' date
                                  if (joiningDateFrom && new Date(event.target.value) < new Date(joiningDateFrom)) {
                                    setJoiningDateTo(''); // Reset the 'from' date
                                  }
                                  else {
                                    setJoiningDateTo(event.target.value)
                                  }
                                }}
                              /> */}

                              <input
                                className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                type="date"
                                id="to"
                                placeholder="MM/DD/YYYY"
                                value={joiningDateTo}
                                min={joiningDateFrom}
                                onChange={(event) => setJoiningDateTo(event.target.value)}
                              />
                            </div>
                            <div className="col-md-12">
                              <div className="d-flex justify-content-end align-items-center">
                                <button className="btn secondary-btn text-nowrap w-md-100 font-semibold d-flex gap-2 justify-content-center align-items-center custom-border  me-3   custom-filter-button" onClick={handleCancel}>
                                  Cancel
                                </button>
                                <button className="btn primary-btn text-nowrap  w-md-100 font-semibold  d-flex gap-2 justify-content-center align-items-center  custom-filter-button" onClick={handleApplyFilter}>
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>

                        </Modal.Body>
                      </Modal>                  </div>

                    <button className="btn secondary-outline-btn shadow-sm custom-filter custom-border" onClick={() => { insertUsers() }}>
                      <img src="images/refresh-grey-icon.svg" alt="refresh-icon" />
                    </button>
                  </div>


                </div>
                <div className="table-responsive theme-table bg-white ">
                  <table className="table custom-table align-middle mb-0">
                    <thead>

                      <tr>
                        <th className="text-center px-2" >
                          <input
                            className="form-check-input user-management-table-checkbox "
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className="text-nowrap ">
                          Employee Info
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => handleSortClick('employee_name')}
                            />
                          </span>


                        </th>
                        <th className="text-nowrap text-center">
                          Grade
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => handleSortClick('grade')}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Domain
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => handleSortClick('domain')}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap " >
                          Joining Date
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => handleSortClick('joining_date')}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Employee Type
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => handleSortClick('employee_type')}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap text-center">Batch Name</th>
                        <th className="text-nowrap text-center">Sub Group</th>
                      </tr>
                    </thead>


                    {users?.length !== 0 ?
                      users && users?.map(user => (
                        <tbody>
                          <tr key={user.user_id}>
                            <td className="text-center px-2">
                              <input
                                type="checkbox"
                                className="form-check-input user-management-table-checkbox"

                                id={user.user_id}
                                checked={selectedUsers.includes(user.user_id)} // Ensure the correct checkbox is checked
                                onChange={() => handleCheckboxChange(user.user_id)}
                              />
                            </td>
                            <td>
                              <span className="d-flex align-items-center">
                                <img
                                  className="me-3 employee-img"
                                  src={user.profile_url ? user.profile_url : "images/user_default_image.png"}
                                />
                                <span className="d-grid">
                                  <span className="font-14 font-medium" style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      callUserPreview(user.user_id, user.batch_name, user.grade);
                                      if (showToast) {
                                        setShowToast(false)
                                      }
                                    }}>{user.employee_name}</span>
                                  <span className="secondary-color mb-0 font-11">
                                    {user.employee_id}
                                  </span>
                                </span>
                              </span>
                            </td>
                            <td className="text-center secondary-color">
                              <span className={getBadgeColor(user.grade)}>
                                {user.grade}
                              </span>
                            </td>
                            <td className="custom-table-text "><span className='cust-domain-wrap d-inline-block'>{user.domain}</span></td>
                            <td className="text-center custom-table-text">
                              {/* {(user.joining_date != "None" ? (new Date(user.joining_date)).toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric'
                              }) : "-")} */}
                              {user.joining_date}
                            </td>


                            <td className="custom-table-text">{user.employee_type}</td>
                            <td className="text-center">
                              <span className={getBatchBadgeClass(user.batch_name)} >
                                {user.batch_name || '-'}
                                {/* {user.batch_name} */}
                              </span>
                            </td>
                            <td className="text-center ">
                              <span className={getSubBatchBadgeClass(user.sub_batch_name)}>{user.sub_batch_name || '-'}</span>
                            </td>
                          </tr>
                        </tbody>))
                      : <tbody>
                        <tr>
                          <td colSpan={8} className="primary-color">
                            <div className="p-5 text-center">
                              <img
                                src="images/search-icon-lfs.svg"
                                alt="three-lines-icon"
                                className="pb-4"
                              />
                              <p className="font-14 font-medium primary-color mb-1">
                                No matches found !
                              </p>
                              <p className="font-12 font-medium secondary-color mb-2">
                                It seems we couldn't find any matches for your request.
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>}

                  </table>
                </div>
              </div>
            </div>

          ) : <></>}
        </div>

      </div>
      <div style={{ minHeight: '5px' }}>
        {showToast && (
          <Toast
            setSelectedUsers={setSelectedUsers}
            setSelectAll={setSelectAll}
            selectedUsers={selectedUsers}
            isAssignedToBatch={isAssignedToBatch}
            isAssignedToSubBatch={isAssignedToSubBatch}
            selectedBatchName={selectedBatchName}
            setRefreshGrid={setRefreshGrid}
            setShowToast={setShowToast}
            setPopup={setPopup}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
        {showUserPreview && <UserProfile userId={UserpreviewId} batch={userPreviewBatch} grade={previewgrade} setshowUserPreview={setshowUserPreview} />}

      </div>
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp} />
      }

    </div>
  );
}