import React, { useEffect, useState } from "react";
import {
  fetchTrainingList,
  getClass,
  setUserSessionMapping
} from "../../../Service/InternApi/internApi";
import ToastComponent from '../Toast/ToasatComponent';
import { Modal } from "react-bootstrap";
import NavBarComponent from "../NavBar/NavBarComponent";
import ReviewComponent from "../Review/ReviewComponent";
import { useNavigate, useLocation, UNSAFE_ErrorResponseImpl } from "react-router-dom";
import Loader from "../Review/Loader";
import { jwtDecode } from "jwt-decode";

const TraineeComponent = () => {
  const navigate = useNavigate();
  // Ps_tl_1.7
  // declare the state variable for Traininglist Component

  const tempState = localStorage.getItem("jwt");
  const payload = jwtDecode(tempState);
  const userName = payload.userName;
  const [state1, setState] = useState(jwtDecode(tempState));
  const userId = state1.userId;
  const [upcomingData, setUpcomingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);

  const [filter_fromdate, setFilterFromDate] = useState("");
  const [filter_todate, setFilterToDate] = useState("");

  const [search_value, setSearchValue] = useState("");
  const [sortOrderTopic, setSortOrderTopic] = useState("desc");
  const [sortOrderClass, setSortOrdeClass] = useState("desc");
  const [sortOrderDate, setSortOderDate] = useState("desc");
  const [sortOrderGrade, setSortOderGrade] = useState("desc");
  const [save, setSave] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dropclass, setdropdownclass] = useState([]);
  const [selectedFilterClass, setSelectedFilterClass] = useState("");
  const [selectedFilterClassID, setSelectedFilterClassID] = useState("");
  const [selectGradevalue, setSelectGradevalue] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState("training_planner_id");
  const location = useLocation();
  const { state } = location;

  const [theme, setTheme] = useState(false);
  const setColorTheme = () => {
    if (!theme) {
      document.documentElement.setAttribute("data-theme", "dark-theme");
    } else {
      document.documentElement.setAttribute("data-theme", "light-theme");
    }
  };
  // Toast
  const toastData = {
    toastType: "",
    toastHeaderMessage: "",
    toastBodyMessage: "",
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 15000);
  }, [hideToast]);


  // Ps_tl_1.8-Ps_tl_1.9
  //  On loading the Traininglist Component the useEffect get triggered on the empty dependency
  useEffect(() => {
    dropdownclass();
  }, []);



  // Ps_tl_1.26-Ps_tl_1.47
  // This function is used to get the Training Topic From the backend
  // The Upcoming session will be updated to upcomingData state variable
  // the Completed session will be updated to completedData state variable
  // set the loading false which after getting the result the loading component stop render
  useEffect(() => {
    const fetchData = async () => {
      const body = {
        user_id: userId,
        searchValue: "",
        fromDate: filter_fromdate,
        toDate: filter_todate,
        Classname: "",
        Grade: "",
        sortTopic: sortOrderTopic,
        sortClass: sortOrderClass,
        sortDate: sortOrderDate,
        sortGrade: sortOrderGrade,
        sortKey: sortKey,
      };

      try {
        const result = await fetchTrainingList(body);
        // console.log("result", result);
        setUpcomingData(result["data"][0]);
        setCompletedData(result["data"][1])
        setLoading(false);
      } catch (error) {
        setLoading(false);
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !!",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const filterbody = {
        user_id: userId,
        searchValue: search_value,
        fromDate: filter_fromdate,
        toDate: filter_todate,
        Classname: selectedFilterClassID,
        Grade: selectGradevalue,
        sortTopic: sortOrderTopic,
        sortClass: sortOrderClass,
        sortDate: sortOrderDate,
        sortGrade: sortOrderGrade,
        sortKey: sortKey,
      };

      try {
        const result = await fetchTrainingList(filterbody);
        setUpcomingData(result["data"][0]);
        setCompletedData(result["data"][1])
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    search_value,
    sortOrderTopic,
    sortOrderClass,
    sortOrderDate,
    sortOrderGrade,
    showModal
  ]);

  // Ps_tl_1.144-Ps_tl_1.145
  // based on the grade received from the backend the className Property in the span tag get updated
  const getGradeColor = (grade) => {
    switch (grade) {
      case "A+":
        return "green";
      case "A-":
        return "green";
      case "A":
        return "green";
      case "B+":
        return "orange";
      case "B-":
        return "orange";
      case "B":
        return "orange";
      case "C+":
        return "red";
      case "C-":
        return "red";
      default:
        return "gray";
    }
  };

  // Ps_tl_1.61-Ps_tl_1.65
  // this function is used to sort the Topic Column
  function sortTopic() {
    setSortKey("topic");
    if (sortOrderTopic === "asc") {
      setSortOrderTopic("desc");
    } else if (sortOrderTopic === "desc") {
      setSortOrderTopic("asc");
    }
  }

  // Ps_tl_1.68-Ps_tl_1.72
  // this function is  used to sort the Class column
  function sortClass() {
    setSortKey("class_name");
    if (sortOrderClass === "asc") {
      setSortOrdeClass("desc");
    } else if (sortOrderClass === "desc") {
      setSortOrdeClass("asc");
    }
  }
  // Ps_tl_1.74-Ps_tl_1.76
  // this function is used to Sort the Date column
  function sortDate() {
    setSortKey("start_date");
    if (sortOrderDate === "asc") {
      setSortOderDate("desc");
    } else if (sortOrderDate === "desc") {
      setSortOderDate("asc");
    }
  }

  // Ps_tl_1.8-Ps_tl_1.25
  // this function is used to get the values of Class drop down from the Backend By calling the getClass() function
  // and update the dropclass state variable
  async function dropdownclass() {
    try {
      const body = {
        data: null,
      };
      const result = await getClass(body);
      setdropdownclass(result["data"]);
    } catch (error) {
      setLoading(false);
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !!",
      };
      setToastProp(toastData);
      setHideToast(false);
    }
  }

  // Ps_tl_1.91-Ps_tl_1.93
  // this function is used to show the filter Modal
  const handleShow = () => setShowModal(true);

  // Ps_tl_1.98-Ps_tl_1.102
  // when user select value in className drop down the selectedFilterClass,selectedFilterClassID state variable get updated
  const handleClassSelection = (class_name, class_id) => {
    setSelectedFilterClass(class_name);
    setSelectedFilterClassID(class_id);
  };

  // Ps_tl_1.104-Ps_tl_1.106
  // when user select the Grade the selectGradevalue state variable get updated
  const handleGradeSelection = (grade) => {
    // console.log(grade);
    setSelectGradevalue(grade);
    // console.log("Grade triggered");
  };

  // Ps_tl_1.107-Ps_tl_1.110
  // when user select the from Date in the filter component the filter_fromdate state variable get updated
  const handleFromDateSelection = (event) => {
    // console.log(event.target.value);
    setFilterFromDate(event.target.value);
    // console.log("From date triggered");
    setFilterToDate("")
  };

  // Ps_tl_1.111-Ps_tl_1.113
  // when user select the To date in the filter modal the filter_todate state variable get updatted
  const handleToDateSelection = (event) => {
    // console.log(event.target.value);
    setFilterToDate(event.target.value);
    // console.log("To date triggered");
  };

  // Ps_tl_1.146-Ps_tl_1.148
  // this close the filter component and update the state variable to its default vaule
  const handleClose = () => {
    setSelectedFilterClass("");
    setSelectedFilterClassID("");
    setSelectGradevalue("");
    setFilterFromDate("");
    setFilterToDate("");
    setShowModal(false);
  };

  // Ps_tl_1.118-Ps_tl_1.145
  // when user click on apply button in filter Component the define the body with the selected values
  // and call the  fetchTrainingList function and update the result in the setUpcomingData,setCompletedData which update the upcomingData,completedData state variable

  const handleApply = () => {
    try {
      setLoading(true)
      setSave(true);
      const fetchData = async () => {
        const filterbody = {
          user_id: userId,
          searchValue: search_value,
          fromDate: filter_fromdate,
          toDate: filter_todate,
          Classname: selectedFilterClassID,
          Grade: selectGradevalue,
          sortTopic: sortOrderTopic,
          sortClass: sortOrderClass,
          sortDate: sortOrderDate,
          sortGrade: sortOrderGrade,
          sortKey: sortKey,
        };
        try {
          // console.log(filterbody, "filterbodyyyyyy");
          const result = await fetchTrainingList(filterbody);
          // console.log("result", result);
          { result && setLoading(false) }
          setShowModal(false);
          setUpcomingData(result["data"][0]);
          setCompletedData(result["data"][1])
          setSave(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
      // handleClose();
    } catch (error) {
      setLoading(false);
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !!",
      };
      setToastProp(toastData);
      setHideToast(false);
      // insertErrorData(reqBody)
    }
  };

  // Ps_tl_1.37-Ps_tl_1.43
  // this function is used to change the date format to achieve the date format in the visuals
  // the month displayed in the visuals is defined in the months list
  // and date instance is created using the new keyword and using the method of Date instance the format can be achieved
  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  // Ps_tl_1.150-Ps_tl_1.160
  // based on the start date on the session the Review button and question button get enabled
  // which was handled by this function
  // when the user click on the Review button the Start date from the backend and the current date is compared
  // if it return true the user is navigate to review Component
  // when the user click on the Question  button the Start date from the backend and the current date is compared
  // if it return true the user is navigate to Chatbot Component;
  function handleButtonState(startDate) {
    // console.log("function is called");
    const currentDate = new Date();
    const formattedStartDate = new Date(startDate); // No need to convert to a string
    const canNavigate = currentDate < formattedStartDate;
    return canNavigate
    // const date = new Date(startDate); // Convert startDate to a Date object
    // return !isToday(date);
  }

  function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  const noRecordStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15px", // Adjust height as needed
    margin: "50px",
  };

  const messageStyle = {
    fontSize: "18px",
    color: "#555",
  };

  function sortGrade() {
    setSortKey("review_grade");
    if (sortOrderGrade === "asc") {
      setSortOderGrade("desc");
    } else if (sortOrderGrade === "desc") {
      setSortOderGrade("asc");
    }
  }

  let currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];


  return (
    <>
      <NavBarComponent></NavBarComponent>
      <div className="container-xxxl common-bg">
        {loading ? (
          <Loader />
        ) : (
          <div className="row ">
            <div className="col-md-12 ">
              <div className="px-4 py-4">
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                  <h5 className="font-20 font-semibold pt-2 primary-color">
                    Training Topics
                  </h5>
                  <div className="d-flex gap-3 small-device-100 flex-wrap">
                    <div className="d-flex gap-3 flex-md-row small-device-100">
                      <div className="input-group input-search">
                        {/* Ps_tl_1.49-Ps_tl_1.50\
                    when user search on  the input field the search_value state variable get updated and the useeffect get triggered                     */}
                        <input
                          type="search"
                          className="form-control cust-search-input  common-bg border-end-0 font-14 font-regular custom-search"
                          placeholder="Search"
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button
                          className="btn border-start-0 custom-search"
                          type="button"
                        >
                          <img src="images/search-icon.svg" alt="search" />
                        </button>
                      </div>
                      <>
                        <button
                          className="btn secondary-outline-btn shadow-sm custom-filter custom-border"
                          onClick={handleShow}
                        >
                          <img src="images/filter-icon.svg" alt="filter-icon" />
                        </button>

                        <Modal show={showModal} onHide={handleClose} className="custom-right-modal-usm">
                          <Modal.Header closeButton>
                            <Modal.Title className='font-semibold'>Advanced Filter
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <label
                                  className="form-label font-14 font-semibold label-color primary-color "
                                  htmlFor="className"
                                >
                                  Class
                                </label>
                                <div className="dropdown topics-dropdown">
                                  <span className="action-drop-down d-flex">
                                    <button
                                      id="batches"
                                      className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                    >
                                      <span className="font-regular font-14 secondary-color">
                                        {selectedFilterClass || "Select"}
                                      </span>
                                      <span className="ps-1">
                                        <img
                                          src="images/down-arrow.svg"
                                          alt="dropdown-icon" />
                                      </span>
                                    </button>
                                    <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow">
                                      {dropclass?.map((item, index) => (
                                        <span
                                          key={index}
                                          className="d-flex list cursor-pointer p-2 primary-color"
                                          onClick={() =>
                                            handleClassSelection(
                                              item.class_name,
                                              item.class_id
                                            )
                                          }
                                        >
                                          {item.class_name}
                                        </span>
                                      ))}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  className="form-label font-14 font-semibold label-color primary-color "
                                  htmlFor="className"
                                >
                                  Grade
                                </label>
                                <div className="dropdown topics-dropdown">
                                  <span className="action-drop-down d-flex">
                                    <button
                                      id="batches"
                                      className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                    >
                                      <span className="font-regular font-14 secondary-color">
                                        {selectGradevalue || "Select"}
                                      </span>
                                      <span className="ps-1">
                                        <img
                                          src="images/down-arrow.svg"
                                          alt="dropdown-icon"
                                        />
                                      </span>
                                    </button>
                                    <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow">
                                      <span
                                        className="d-flex list cursor-pointer p-2 primary-color"
                                        onClick={() =>
                                          handleGradeSelection("A+")
                                        }
                                      >
                                        A+
                                      </span>
                                      <span
                                        className="d-flex list cursor-pointer p-2 primary-color"
                                        onClick={() =>
                                          handleGradeSelection("A-")
                                        }
                                      >
                                        A-
                                      </span>
                                      <span
                                        className="d-flex list cursor-pointer p-2 primary-color"
                                        onClick={() =>
                                          handleGradeSelection("B+")
                                        }
                                      >
                                        B+
                                      </span>
                                      <span
                                        className="d-flex list cursor-pointer p-2 primary-color"
                                        onClick={() =>
                                          handleGradeSelection("B-")
                                        }
                                      >
                                        B-
                                      </span>
                                      <span
                                        className="d-flex list cursor-pointer p-2 primary-color"
                                        onClick={() =>
                                          handleGradeSelection("C+")
                                        }
                                      >
                                        C+
                                      </span>
                                      <span
                                        className="d-flex list cursor-pointer p-2 primary-color"
                                        onClick={() =>
                                          handleGradeSelection("C-")
                                        }
                                      >
                                        C-
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 mb-2">
                                <label className="font-16 font-semibold  primary-color">
                                  Date
                                </label>
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  className="form-label font-14 font-semibold label-color primary-color"
                                  htmlFor="from">
                                  From
                                </label>
                                <input
                                  className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                  type="date"
                                  id="from"
                                  placeholder="MM/DD/YYYY"
                                  value={filter_fromdate}
                                  onChange={(e) => {
                                    handleFromDateSelection(e);
                                  }}

                                // max={filter_todate ? filter_todate : formattedDate}

                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  className="form-label font-14 font-semibold label-color primary-color"
                                  htmlFor="to">
                                  To
                                </label>
                                <input
                                  className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                  type="date"
                                  id="to"
                                  placeholder="MM/DD/YYYY"
                                  value={filter_todate}
                                  onChange={(e) => { handleToDateSelection(e) }}
                                  min={filter_fromdate}
                                // max={formattedDate}
                                // onChange={(event) => {
                                //   setFilterToDate(event.target.value)
                                // }}
                                />
                              </div>
                              <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center pt-3">
                                  <button
                                    className="btn secondary-btn custom-border text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 custom_filter_button"
                                    onClick={() => { handleClose(); }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center custom_filter_button"
                                    onClick={handleApply}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                    </div>
                  </div>
                </div>
                <div className="mb-0 align-middle">
                  <div
                    className="accordion-table-row font-semibold font-14 text-nowrap table-accordion-head collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#completed"
                    aria-expanded="true"
                  >
                    <div className="d-flex align-items-center gap-3 mt-4 pt-2 mb-3">
                      <h6 className="mt-2 primary-color">Upcoming</h6>
                      <span className="record-count px-3 py-1 font-medium custom-border">
                        {upcomingData.length}
                      </span>
                      <img
                        src="images/table-accordion-arrow.svg"
                        alt="table-accordion-arrow"
                        className="cursor-pointer table-accordion-arrow"
                      />
                    </div>
                  </div>

                  <div className="collapse show" id="completed">
                    <div className="table-responsive theme-table bg-white d-inline-block w-100">

                      <table className="table mb-0 align-middle theme-table text-nowrap">
                        <thead>
                          <tr>
                            <th className="text-nowrap">
                              Topic
                              <span className="ms-1 cursor-pointer">
                                <img
                                  src="images/down-arrow-white.svg"
                                  className="custom-arrow-color"
                                  alt="sort-arrow-down"
                                  onClick={sortTopic}
                                />
                              </span>
                            </th>
                            <th className="text-nowrap">
                              Class
                              <span className="ms-1 cursor-pointer">
                                <img
                                  src="images/down-arrow-white.svg"
                                  className="custom-arrow-color"
                                  alt="sort-arrow-down"
                                  onClick={sortClass}
                                />
                              </span>
                            </th>
                            <th className="text-nowrap">
                              Date
                              <span className="ms-1 cursor-pointer">
                                <img
                                  src="images/down-arrow-white.svg"
                                  className="custom-arrow-color"
                                  alt="sort-arrow-down"
                                  onClick={sortDate}
                                />
                              </span>
                            </th>
                            <th className="text-nowrap">Grade</th>
                            <th className="text-nowrap text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="font-semibold">
                          {/* Ps_tl_1.35-Ps_tl_1.44
    map the upcomingData data using .map() method 
    the Date column is formatted using formatDate() function  */}
                          {upcomingData.length !== 0 ? (
                            upcomingData.map((item, index) => (
                              <tr key={index}>
                                <td className="">{item.topic}</td>
                                <td className="secondary-color">{item.classname}</td>
                                <td className="secondary-color">{formatDate(item.startdate)}</td>
                                <td>
                                  {item.reviewgrade ? (
                                    <span className="badge-primary badge-sm">{item.reviewgrade}</span>
                                  ) : (
                                    <span className="badge-secondary badge-sm"></span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <span className="d-flex gap-2 justify-content-center">
                                    <button
                                      disabled={handleButtonState(item.startdate)}
                                      className="outline-button d-flex primary-color common-card-theme-bg custom-border"
                                      onClick={() => {
                                        navigate("/chatbot", {
                                          state: {
                                            training_session_id: item.sessionid,
                                            training_session_name: item.topic,
                                          },
                                        });

                                      }}
                                    >
                                      <img
                                        className="me-2 py-1"
                                        src="images/question-icon.svg"
                                        alt="Question"
                                      />
                                      Question
                                    </button>
                                    <button
                                      disabled={handleButtonState(item.startdate)}
                                      className="outline-button d-flex primary-color common-card-theme-bg custom-border" onClick={() => {
                                        navigate("/practice", {
                                          state: {
                                            training_session_id: item.sessionid,
                                            training_session_name: item.topic,
                                          },
                                        });
                                      }}><img
                                        class="me-2 py-1" src="images/practice-icon.svg"
                                        alt="Practice" />Practice</button>
                                    <button
                                      disabled={handleButtonState(item.startdate)}
                                      className="outline-button d-flex primary-color common-card-theme-bg custom-border"
                                      onClick={() => {
                                        navigate("/review", {
                                          state: {
                                            training_session_id: item.sessionid,
                                            training_session_name: item.topic,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        className="me-2 py-1"
                                        src="images/review-icon.svg"
                                        alt="Review"
                                      />
                                      Review
                                    </button>

                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="5" className="primary-color">
                                <div className="p-5 text-center">
                                  <img
                                    src="images/search-icon-lfs.svg"
                                    alt="three-lines-icon"
                                    className="pb-4"
                                  />
                                  <p className="font-14 font-medium primary-color mb-1">
                                    No search results found!
                                  </p>
                                  <p className="font-12 font-medium secondary-color mb-2">
                                    It seems we couldn't find any matches for your search.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mb-0 align-middle">
                  <div
                    className="accordion-table-row font-semibold font-14 text-nowrap table-accordion-head collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#completed"

                  >
                    <div className="text-nowrap table-accordion-head ">
                      <div className="d-flex align-items-center gap-3 mt-4 pt-2 mb-3">
                        <h6 className="mt-2 primary-color">Completed</h6>
                        <span className="record-count px-3 py-1 font-medium custom-border">
                          {completedData.length}
                        </span>
                        <img
                          src="images/table-accordion-arrow.svg"
                          alt="table-accordion-arrow"
                          className="cursor-pointer table-accordion-arrow"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="collapse table-accordion-body"
                    id="completed"
                  >
                    <div colSpan={2}>
                      <div className="table-responsive theme-table bg-white d-inline-block w-100">
                        <table className="table mb-0 align-middle theme-table text-nowrap">
                          <thead>
                            <tr>
                              <th className="text-nowrap">
                                Topic
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src="images/down-arrow-white.svg"
                                    className="custom-arrow-color"
                                    alt="sort-arrow-down"
                                    onClick={sortTopic}
                                  />
                                </span>
                              </th>
                              <th className="text-nowrap">
                                Class
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src="images/down-arrow-white.svg"
                                    className="custom-arrow-color"
                                    alt="sort-arrow-down"
                                    onClick={sortClass}
                                  />
                                </span>
                              </th>
                              <th className="text-nowrap">
                                Date
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src="images/down-arrow-white.svg"
                                    className="custom-arrow-color"
                                    alt="sort-arrow-down"
                                    onClick={sortDate}
                                  />
                                </span>
                              </th>
                              <th className="text-nowrap text-center">
                                Grade
                                <span className="ms-1 cursor-pointer">
                                  <img
                                    src="images/down-arrow-white.svg"
                                    className="custom-arrow-color"
                                    alt="sort-arrow-down"
                                    onClick={sortGrade}
                                  />
                                </span>
                              </th>
                              <th className="text-nowrap text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="font-semibold">
                            {completedData.length !== 0 ? (
                              completedData.map((data) => (

                                <tr key={data.id}>
                                  <td>{data.topic}</td>
                                  <td className="secondary-color">
                                    {data.classname}
                                  </td>
                                  <td className="secondary-color">
                                    {formatDate(data.startdate)}
                                  </td>
                                  <td className="text-center">
                                    {data.reviewgrade && (
                                      <span
                                        className={`custom-grade-badge custom-badge-${getGradeColor(
                                          data.reviewgrade
                                        )} font-medium`}
                                      >
                                        {data.reviewgrade}
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <span className="d-flex gap-2 justify-content-center">
                                      <button
                                        className="outline-button d-flex primary-color common-card-theme-bg custom-border"
                                        onClick={() => {
                                          navigate("/chatbot", {
                                            state: {
                                              training_session_id:
                                                data.sessionid,
                                              training_session_name: data.topic,
                                            },
                                          });
                                        }}
                                      >
                                        <img
                                          className="me-2 py-1"
                                          src="images/question-icon.svg"
                                          alt="Question"
                                        />
                                        Question
                                      </button>
                                      <button
                                        className="outline-button d-flex primary-color common-card-theme-bg custom-border" onClick={() => {
                                          navigate("/practice", {
                                            state: {
                                              training_session_id: data.sessionid,
                                              training_session_name: data.topic,
                                            },
                                          });
                                        }}><img
                                          class="me-2 py-1" src="images/practice-icon.svg"
                                          alt="Practice" />Practice</button>
                                      <button
                                        disabled
                                        className=" outline-button px-3 d-flex primary-color common-card-theme-bg custom-border"
                                      >
                                        <img
                                          className="me-2 py-1"
                                          src="images/review-icon.svg"
                                          alt="Review"
                                        />
                                        Review
                                      </button>
                                    </span>
                                  </td>
                                </tr>

                              ))


                            ) : (
                              <tr>
                                <td colspan="5" className="primary-color">
                                  <div className="p-5 text-center">
                                    <img
                                      src="images/search-icon-lfs.svg"
                                      alt="three-lines-icon"
                                      className="pb-4"
                                    />
                                    <p className="font-14 font-medium primary-color mb-1">
                                      No search results found!
                                    </p>
                                    <p className="font-12 font-medium secondary-color mb-2">
                                      It seems we couldn't find any matches for your search.
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {hideToast ? <></> : <ToastComponent toastObj={toastProp} />}
    </>
  );
};

export default TraineeComponent;
