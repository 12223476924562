import React, { useEffect, useRef } from 'react';

const LiveStreamVideo = ({ stream, className }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      if (stream instanceof MediaStream) {
        try {
          videoRef.current.srcObject = stream;
        } catch (error) {
          console.error("Error setting srcObject:", error);
        }
      } else {
        console.error("Invalid stream object:", stream);
      }
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [stream]);

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      muted
      className={className}
    />
  );
};

export default LiveStreamVideo;