import React, { useState, useEffect } from 'react'
import { GetUserProfiledata } from '../../../Service/AdminApi/userProfileApi';
import UserPreview from './userPreview';
import Loader from '../TrainingPlan/loader';
import ToastComponent from '../KnowledgeBase/toast';


// PC_LFS_UPF_4 - PC_LFS_UPF_41 - The UserProfile component is responsible for displaying user profile data, including completed sessions.bottomGrid: Stores table content for completed sessions.topContent: Stores top user information.TrainingSessionId: Stores the ID of the training session.UserId: Stores the ID of the user.sort and sortOrder: Store sorting criteria and order for table columns.preview: Tracks whether the user preview modal is open.employeeName: Stores the name of the employee.
export const UserProfile = ({ userId, batch, grade, setshowUserPreview }) => {
  // create a state variable to fetch the table content and store it in it
  const [bottomGrid, setUserbottomGrid] = useState([]);
  const [topContent, setUserTopContent] = useState([]);
  const [TrainingSessionId, setTrainingSessionId] = useState("0");
  const [sort, setSort] = useState('')
  const [sortOrder, setsortOrder] = useState('DESC')
  const [preview, setPreview] = useState(false)
  const [employeeName, setEmployeeName] = useState('')
  const [modalCss, setModalCss] = useState('show')
  const [showModal, setShowModal] = useState(false);
  const [loading, setloader] = useState(true)
  const [closePreview, setClosePreview] = useState(false)

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // Toast 
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);
  const [profile,setProfile] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
      { closePreview && setshowUserPreview(false) }
    }, 5000);
  }, [hideToast]);

  // Useeffect() is called for userProfile() and 
  useEffect(() => {
    userProfile()
    PreviewClick()
  }, [sort, sortOrder])

  useEffect(
    () => {
      userProfile()
    }, [TrainingSessionId, userId]
  )

  // const loaderFalse =() =>{
  //   setloader(false)
  // }

  const getBadgeColor = (grade) => {
    switch (grade) {
      case 'A+':
        return 'custom-grade-badge custom-badge-green';
      case 'A-':
        return 'custom-grade-badge custom-badge-green';
      case 'B+':
        return 'custom-grade-badge custom-badge-orange';
      case 'B-':
        return 'custom-grade-badge custom-badge-orange';
      case 'C+':
        return 'custom-grade-badge custom-badge-red';
      case 'C-':
        return 'custom-grade-badge custom-badge-red';
      default:
        return 'custom-grade-badge custom-badge-gray';
    }
  }


  // PC_LFS_UPF_47 - PC_LFS_UPF_51 The PreviewClick function generates table rows for completed sessions.PreviewClick dynamically generates table rows with session details and preview buttons for completed sessions
  const PreviewClick = () => {
    return (
      <>
        {
          bottomGrid?.map((element, index) => (
            <tr key={index}>
              <td className="px-4 ps-3">{element.topic}</td>
              <td className="secondary-color">{element.class_name}</td>
              {/* <td className="secondary-color">{element.start_date}</td> */}
              <td className="secondary-color">
                {new Date(element.start_date).toLocaleDateString('en-US', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric'
                })}
              </td>
              <td>
                <span className={getBadgeColor(element.review_grade)}>{element.review_grade}</span>
              </td>
              <td className="text-center">
                <button
                  id={element.training_session_id}
                  data-bs-toggle="modal"
                  data-bs-target="#upload"
                  className="outline-button primary-color common-card-theme-bg custom-border d-inline-flex align-items-center" onClick={(e) => {
                    setPreview(true)
                    setShowModal(true)
                    setTrainingSessionId(e.target.id)
                    // setloader(true)
                  }}>
                  <img
                    className="me-2"
                    src="images/preview-icon.svg"
                    alt="preview"
                  />
                  Preview
                </button>
              </td>
            </tr>

          ))}
      </>

    )
  }


  // PC_LFS_UPF_52 - PC_LFS_UPF_53 The topUser component is used to map the table content in the topContent , kt is used to map employee_name ,employee_id,overAll_grade,user_domain,joining_date,employee_type to the frontend using map() function
  const topUser = () => {
    
    return (
      <>
        {topContent?.map((element) =>
          <ul className="breadcrumb-item mb-3 ps-0 mt-2">
            <li>
              <a
                href="#"
                className="pe-0 text-decoration-none secondary-color font-medium font-14"
                onClick={() => {
                  setshowUserPreview(false)
                }}
              >
                User Management
              </a>
            </li>
            <li className="pe-2 primary-color font-medium font-14">{element.employee_name}</li>
          </ul>)}
        {/* bread crumb ends here */}
        {topContent?.map((element) => (
          <div className="row m-0 mb-5 g-3">
            <div className="col-12 col-lg-2 col-md-4 px-0">
              <div className="d-flex align-items-center">
                <img
                  className="user-profile-image"
                  src={element.user_profile_url
                    ? element.user_profile_url
                    : "images/user_default_image.png"}
                  alt="profile image"
                />
                <div className="ms-2">
                  <p className="primary-color font-bold font-16 m-0 pb-1 pb-lg-3">
                    {element.employee_name}
                  </p>
                  <p className="secondary-color font-14 m-0">{element.employee_id}</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 px-0 pt-1">
              <p className="primary-color font-bold font-16 m-0 pb-1 pb-lg-3">
                Grade
              </p>
              <p className="font-14 m-0">
                <span className={getBadgeColor(grade)}>{grade}</span>
              </p>
            </div>
            <div className="col-6 col-md-4 col-lg-2 px-0 pt-1">
              <p className="primary-color font-bold font-16 m-0 pb-1 pb-lg-3">
                Domain
              </p>
              <p className="secondary-color font-14 m-0 cust-domain-up">{element.user_domain}</p>
            </div>
            <div className="col-6 col-md-4 col-lg-2 px-0 pt-1">
              <p className="primary-color font-bold font-16 m-0 pb-1 pb-lg-3">
                Joining Date
              </p>
              <p className="secondary-color font-14 m-0">
                {element.joining_date !== "None" ? (
                  new Date(element.joining_date).toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  })
                ) : "-"}
              </p>

            </div>
            <div className="col-6 col-md-4 col-lg-2 px-0 pt-1">
              <p className="primary-color font-bold font-16 m-0 pb-1 pb-lg-3">
                Employee Type
              </p>
              <p className="secondary-color font-14 m-0">{element.employee_type}</p>
            </div>
            <div className="col-6 col-md-4 col-lg-2 px-0 pt-1">
              <p className="primary-color font-bold font-16 m-0 pb-1 pb-lg-3">
                Batch
              </p>
              <p className="font-14 m-0">
                <span className="custom-badge custom-badge-violet">{batch || "-"}</span>
              </p>
            </div>
          </div>))}
      </>
    )
  }

  // PC_LFS_UPF_4 - PC_LFS_UPF_46 This userProfile function is an asynchronous function responsible for fetching user profile data. It constructs a request body containing the user ID (UserId), sorting criteria (sort), and sort order (sortOrder). It then calls the GetUserProfiledata function with this request body to fetch the user profile data.
  const userProfile = async () => {
    try {
      // debugger
      let reqBody = {
        "userId": userId,
        "sort": sort,
        "sortOrder": sortOrder
      }
      let result = await GetUserProfiledata(reqBody)
      setUserbottomGrid(result.message.bottom_grid)
      setUserTopContent(result.message.top_content)
      setEmployeeName(result.message.top_content[0].employee_name)
      setProfile(result.message.top_content[0].user_profile_url)
      setloader(false)
    }
    catch (error) {
      setClosePreview(true)
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "No data found !!"
      }
      setToastProp(toastData)
      setHideToast(false);
      console.error(error)
    }
  }

  return (<>
    {/* start of the user profile */}
    {loading ? <Loader /> : (
      <div className="container-xxxl px-5 py-3">
        <div className="col-md-12">

          {/* bread crumb starts here */}
          {topUser()}
          <h3 className="font-20 font-bold mb-4 primary-color">
            Completed Sessions ({bottomGrid.length || 0})
          </h3>
          <div className="table-responsive theme-table">
            <table className="table custom-table mb-0 align-middle text-nowrap">
              <thead>
                <tr>
                  <th>
                    Topic Name
                    <span className="ms-1 cursor-pointer">
                      <img
                        src="images/down-arrow-white.svg"
                        className="custom-arrow-color"
                        alt="sort-arrow-down"
                        onClick={() => {
                          if (sortOrder === "ASC") {
                            setSort("topic")
                            setsortOrder("DESC")
                          }
                          else {
                            setsortOrder("ASC")
                            setSort("topic")
                          }
                        }}
                      />
                    </span>
                  </th>
                  <th>
                    Class
                    <span className="ms-1 cursor-pointer">
                      <img
                        src="images/down-arrow-white.svg"
                        className="custom-arrow-color"
                        alt="sort-arrow-down"
                        onClick={() => {
                          if (sortOrder === "ASC") {
                            setSort("class_name")
                            setsortOrder("DESC")
                          }
                          else {
                            setsortOrder("ASC")
                            setSort("class_name")
                          }
                        }}
                      />
                    </span>
                  </th>
                  <th>
                    Date
                    <span className="ms-1 cursor-pointer">
                      <img
                        src="images/down-arrow-white.svg"
                        className="custom-arrow-color"
                        alt="sort-arrow-down"
                        onClick={() => {
                          if (sortOrder === "ASC") {
                            setSort("start_date")
                            setsortOrder("DESC")
                          }
                          else {
                            setsortOrder("ASC")
                            setSort("start_date")
                          }
                        }}
                      />
                    </span>
                  </th>
                  <th>
                    Grade
                    <span className="ms-1 cursor-pointer">
                      <img
                        src="images/down-arrow-white.svg"
                        className="custom-arrow-color"
                        alt="sort-arrow-down"
                        onClick={() => {
                          if (sortOrder === "ASC") {
                            setSort("review_grade")
                            setsortOrder("DESC")
                          }
                          else {
                            setsortOrder("ASC")
                            setSort("review_grade")
                          }
                        }}
                      />
                    </span>
                  </th>
                  <th className="text-center">Action</th>
                </tr>

              </thead>

              {bottomGrid != 0 ?
                <tbody>
                  {PreviewClick()}
                </tbody> :
                <tbody>
                  <tr>
                    <td colSpan={5} className="primary-color">
                      <div className="p-5 text-center">
                        <img
                          src="images/search-icon-lfs.svg"
                          alt="three-lines-icon"
                          className="pb-4"
                        />
                        <p className="font-14 font-medium primary-color mb-1">
                          No Completed Sessions found !
                        </p>
                        <p className="font-12 font-medium secondary-color mb-2">
                          It seems no review has been completed.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              }
            </table>
          </div>

        </div>
      </div>
    )}
    {/* end of the user profile */}
    <div>
      <UserPreview trainingSessionId={TrainingSessionId} userId={userId} display={preview} employee={employeeName} showModal={showModal} onClose={() => {
        setModalCss('')
        setShowModal(false)
      }}
        modalCss={modalCss} profile={profile} ></UserPreview>
    </div>
    {hideToast ? <></> :
      <ToastComponent toastObj={toastProp} />
    }
  </>)
}