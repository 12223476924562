import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ErrorPage = ({ isAdmin }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const isLogged = () => {
    try {
      const token = localStorage.getItem('jwt');
      if (!token) {return false} else {return true};
      
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  }

  const handleClick = () => {
    if (loggedIn) {
      if (isAdmin) {
        goToAdminLanding();
      } else {
        goToTrainee();
      }
    } else {
      goToLogin();
    }
  };

  useEffect(() => {
    setLoggedIn(isLogged());
  }, []);

  const goToAdminLanding = () => {
    navigate('/adminlanding');
  };

  const goToTrainee = () => {
    navigate('/Trainee');
  };

  const goToLogin = () => {
    navigate('/');
  };

  return (
    <>
      <div className="err-bg common-bg">
        <div className="text-center pt-5">
          <h3 className="font-80 fw-bold primary-color">404</h3>
          <p className="font-20 fw-bold primary-color">
            Hmm, this page doesn’t exist
          </p>
          <p className="font-18 pb-3 primary-color">
            The page you are looking for might have been removed.
          </p>
          <button className="btn primary-btn text-nowrap small-device-100 font-semibold font-14 text-center px-4"
            onClick={()=>handleClick() }
            
          >{loggedIn ? "Go to Homepage" : "Go to Loginpage"}
          </button>
        </div>
      </div>
    </>
  )
}

export default ErrorPage