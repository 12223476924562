import { adminEndpoints } from "../../Constant/constant"
import { client } from "../../Client/client"

// const jwt = localStorage.getItem('jwt') 
export const GetUserProfiledata = async (body = {}) => {
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userProfile.GetUserProfiledata,
        "request_data":body
      }
    let response = await client(adminEndpoints.userProfile.GetUserProfiledata, requestBody, 'POST', 'admin')
    return response.data
}

export const getUserPreviewData = async (body = {}) => {
  const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":adminEndpoints.userProfile.getUserPreviewData,
        "request_data":body
      }
    let response = await client(adminEndpoints.userProfile.getUserPreviewData, requestBody, 'POST', 'admin')
    console.log(response.data);
    return response.data
}