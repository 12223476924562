import React, { useEffect, useState } from 'react';
import { getTrainingPlan, getBatch, getClass, getSubBatch } from "../../../Service/AdminApi/trainingPlanApi";
import TrainingGrid from './TrainingGrid';
import EmptyGrid from './emptygrid';
import Loader from './loader';
import Upload from './uploadTP';
import { Modal, Button } from 'react-bootstrap';
import ToastComponent from './ToastComponent';


function TrainingPlan() {
    // PS_Tp_15 declaring the state variable 
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [segregrateBatch, setSegregateBatch] = useState(false);
    const [uploadTraining, setUploadTraining] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [dropdownbatch, setdropdownbatch] = useState([])
    const [dropclass, setdropdownclass] = useState([])
    const [selectedfilterClass, setSelectedfilterClass] = useState("");
    const [selectedbatch, setSelectedfilterbatch] = useState("");
    const [dropdownSubBatch, setDropdownSubBatch] = useState([]);
    const [selectedSubBatch, setSelectedSubBatch] = useState("");
    const [selectedBatch, setSelectedBatch] = useState("");
    const [filterfromdate, setFilterFromDate] = useState('');
    const [filtertodate, setFilterToDate] = useState('');
    const [sortfileOrder, setfileSortOrder] = useState('asc');
    const [sortclassOrder, setclassSortOrder] = useState('asc');
    const [sortuploadOrder, setuploadSortOrder] = useState('asc');
    const [filter, setFilter] = useState('');
    const [showUpload, setShowUpload] = useState(false);
    const [uploadSuccessMessage, setUploadSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [totalLengthValue, setTotalLengthValue] = useState(0);
    const [SelectedfilterClassName, setSelectedfilterClassName] = useState('')
    const [SelectedBatchName, setSelectedBatchName] = useState('')
    const [SubBatchName, setSelectedSubBatchName] = useState('')
    const [uploadsuccess, setUploadSuccess] = useState('')
    const [sortKey, setSortKey] = useState('training_planner_id')

    // toast 
    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState(toastData);

    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 5000);
    }, [hideToast]);

    // PS_Tp_16-PS_Tp_41
    // this useEffect is used to get the Drop down value of Class drop down and Batch drop down from the Backend on loading 
    useEffect(() => {
        dropdownclass();
        dropbatch();
    }, []);

    // PS_Tp_50-PS_Tp_103
    // this function is used to get the the Training PLan from the Backend and 
    // if the response is not equal to the batch was not assigned" the TrainingplanGrid component is render 
    // if the response is the batch was not assigned or the Upload Training plan the TrainingPlanEmptyGrid Component is render based on the props from the TrainingPlan Component the TrainingPlanEmptyGrid is rendered 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    fileName: '',
                    batchName: '',
                    subBatchName: '',
                    Classname: '',
                    fromDate: '',
                    toDate: '',
                    sortClassorder: '',
                    sortFileorder: '',
                    sortUploadedorder: '',
                    sortKey: sortKey
                };
                const result = await getTrainingPlan(body);

                if (result !== "the batch was not assigned" && result !== "the Upload Training plan") {
                    setTrainingPlans(result);
                    setTotalLengthValue(result.length);
                }
                if (result === "the batch was not assigned") {
                    setSegregateBatch(true);
                } if (result === "the Upload Training plan") {
                    setUploadTraining(true);
                }
                setLoading(false);
            }
            catch (error) {
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: "Something Went Wrong !"
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    fileName: '',
                    batchName: '',
                    subBatchName: '',
                    Classname: '',
                    fromDate: '',
                    toDate: '',
                    sortClassorder: '',
                    sortFileorder: '',
                    sortUploadedorder: '',
                    sortKey: sortKey
                };
                const result = await getTrainingPlan(body);

                if (result !== "the batch was not assigned" && result !== "the Upload Training plan") {
                    setTrainingPlans(result);
                    setTotalLengthValue(result.length);
                } if (result === "the batch was not assigned") {
                    setSegregateBatch(true);
                } if (result === "the Upload Training plan") {
                    setUploadTraining(true);
                }
                setLoading(false);
            }
            catch (error) {
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: "Something Went Wrong !"
                }
                setToastProp(toastData)
                setHideToast(false);
               
            }
        };
        fetchData();
    }, [uploadsuccess]);

    // PS_Tp_38-PS_Tp_49
    // this function is used to get the Batch value for Batch drop down from the backend 
    // the result is updated to the dropdownbatch state variable and mapped in the batch drop down in the filter modal 
    async function dropbatch() {
        try {
            const body = {
                "Batchid": ""
            };
            const result = await getBatch(body);
            setdropdownbatch(result)
        } catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
              }
              setToastProp(toastData)
              setHideToast(false);
        }
    }
    // PS_Tp_149-PS_Tp_161
    // the selected Batch id is set as value to the Batchid key and the getSubBatch() is called and pass the body 
    // which get the sub batch based on the batch selected 
    async function dropSubBatch(selectedBatchId) {
        try {
            const body = {
                Batchid: selectedBatchId
            };
            const result = await getSubBatch(body);
            setDropdownSubBatch(result);
        } catch (error) {
            console.error('Error fetching subbatch:', error);
        }
    }

    // PS_Tp_143-PS_Tp_148
    // when user select the Batch the handleBatchChange function is called and update the selectedBatch,SelectedBatchName state variable 
    // and call the dropSubBatch function which get the sub batch value based on the selected batch from the Backend 
    const handleBatchChange = (batch) => {
        setSelectedBatch(batch.batch_id);
        dropSubBatch(batch.batch_id);
        setSelectedBatchName(batch.batch_name)
        setSelectedSubBatch("")
        setSelectedSubBatchName("")
    };

    // PS_Tp_17-PS_Tp_37
    // this function is used to get the Class values from the backend and the result is updated to the dropclass state variable 
    // and map the Class Drop down values in the filter modal 
    async function dropdownclass() {
        try {
            const body = {
                data: null
            }
            const result = await getClass(body);
            if (result.data != "No Class found")
            {setdropdownclass(result.data)}
        }
        catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
              }
              setToastProp(toastData)
              setHideToast(false);
        }
    }

    // PS_Tp_196-PS_Tp_207
    // when user click on cancel button in the filter modal the state variable is set to its default value 
    // and the Filter Modal get closed 
    const handleCancel = () => {
        setSelectedBatchName('')
        setSelectedfilterClassName('')
        setSelectedfilterClass("");
        setSelectedfilterbatch("");
        setSelectedSubBatch("");
        setSelectedSubBatchName('')
        setSelectedBatch("");
        setFilterFromDate('');
        setFilterToDate('');
        setShowModal(false)
    };
    // PS_Tp_172-PS_Tp_178
    // when user click on Aplly button in the filter Modal the handleApplyFilter function is called 
    // and define a body with the selected values inside the filter modal and update the filter state variable which was send as props to the TrainingGrid Component 
    const handleApplyFilter = async () => {
        // setLoading(true)
        const body = {
            fileName: '',
            batchName: selectedBatch,
            subBatchName: selectedSubBatch,
            Classname: selectedfilterClass,
            fromDate: filterfromdate,
            toDate: filtertodate,
            sortClassorder: sortclassOrder,
            sortFileorder: sortfileOrder,
            sortUploadedorder: sortuploadOrder,
        };
        setFilter(body);
        setShowModal(false);
    };
    {/* PS_Tp_123-PS_Tp_130
        when user click on the upload button the handleUploadClick function get triggered 
        and update the showUpload state variable which render the TrainingPlanUpload Component  */}
    const handleUploadClick = () => {
        setShowUpload(true);
    };

    // PS_Tp_131-PS_Tp_138
    // when user click on the filter button the handleShow function is called which update the showModal state variable which display the filter modal 
    const handleShow = () => setShowModal(true);

    const calculateTotalLength = (length) => {
        setTotalLengthValue(length)

    };

    // PS_TU_123-PS_TU_124
    // when the file is Uploaded successfully from The TrainingPlanUpload component the 
    // handleUploadSuccess() is called back which update the uploadsuccess and render the TrainingPLan using on dependency of uploadsuccess in useeffect
    const handleUploadSuccess = () => {
        setUploadSuccess("success")
        let toastData = {
            toastType: 'Positive',
            toastHeaderMessage: 'Positive',
            toastBodyMessage: 'Successfully Uploaded the excel !'
        }
        setToastProp(toastData)
        setHideToast(false);
    }

    const loadingFalse = () =>{
        setLoading(false)
    }

    const loadingTrue = () => {
        setLoading(true)
    }

    return (
        <>
            <div className="common-bg">
                <div className="container-xxxl">
                    {loading ? <Loader /> : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common-bg p-4 px-lg-5 my-3 rounded-3">
                                    <div className="d-flex align-items-center justify-content-between mb-3 pb-3 flex-wrap gap-3 ">
                                        <h5 className="font-20 font-semibold mb-0 primary-color">
                                            {/* Training Plan (2) */}
                                            Training Plan ({totalLengthValue})
                                        </h5>
                                        <div className='d-flex'>
                                            <div className="d-flex gap-3 small-device-100 flex-wrap">
                                                <div className="d-flex gap-3 flex-md-row small-device-100">
                                                    <div className="input-group input-search">

                                                        {/* PS_Tp_104-PS_Tp_107
                                        when user search on the input field the on change event get triggered and update the searchValue state variable 
                                        which send as props to the TrainingGrid component  */}

                                                        <input
                                                            type="search"
                                                            className="form-control cust-search-input common-bg border-end-0 font-14 font-regular custom-search dropdown-text-color"
                                                            placeholder="Search" onChange={(event) => setSearchValue(event.target.value)}
                                                        />
                                                        <button
                                                            className="btn border-start-0 custom-search"
                                                            type="button"
                                                        >
                                                            <img src="images/search-icon.svg" alt="search" />
                                                        </button>
                                                    </div>

                                                    <button
                                                        className="btn secondary-outline-btn shadow-sm custom-filter custom-border me-3"
                                                        onClick={handleShow}
                                                    >
                                                        <img src="images/filter-icon.svg" alt="filter-icon" />
                                                    </button>
                                                </div>
                                            </div>

                                            <Modal show={showModal} onHide={handleCancel} className="custom-right-modal" backdrop={false}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title className='font-semibold'>Advanced Filter</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>

                                                    <div className="row">
                                                        <div className="col-md-12 mb-4">
                                                            <label className="font-semibold font-16 mb-2 primary-color">Class</label>
                                                            <div className="dropdown topics-dropdown w-100">
                                                                <span className="action-drop-down ">
                                                                    <button id="teams"
                                                                        className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                                                        type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                                        <span className="font-medium font-14 primary-color">{SelectedfilterClassName || 'Select'} </span>
                                                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                                                    </button>
                                                                    <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                                                        {/* PS_Tp_140-PS_Tp_142
                                                        when user select on the Class drop down the onClick event triggered which update  the selectedfilterClass and SelectedfilterClassName state variable  */}
                                                                        {dropclass?.map((value) => (
                                                                            <span key={value.class_id} className="d-flex list cursor-pointer p-2 primary-color" onClick={() => { setSelectedfilterClass(value.class_id); setSelectedfilterClassName(value.class_name) }}>
                                                                                {value.class_name}
                                                                            </span>
                                                                        ))}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label font-14 font-semibold label-color primary-color" htmlFor="class">Batch Name</label>
                                                            <div className="dropdown topics-dropdown">
                                                                <span className="action-drop-down d-flex">
                                                                    <button id="batches" className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                                        <span className="font-regular font-14 secondary-color">{SelectedBatchName || 'Select'} </span>
                                                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                                                    </button>
                                                                    <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow" >

                                                                        {dropdownbatch?.map((batch) => (
                                                                            <span
                                                                                key={batch.batch_id}
                                                                                className="d-flex list cursor-pointer p-2 primary-color"
                                                                                onClick={() => handleBatchChange(batch)}
                                                                            >

                                                                                {batch.batch_name}
                                                                            </span>
                                                                        ))}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label font-14 font-semibold label-color primary-color" htmlFor="class">
                                                                Subgroup
                                                            </label>
                                                            <div className="dropdown topics-dropdown">
                                                                <span className="action-drop-down d-flex">
                                                                    <button
                                                                        id="batches"
                                                                        className="btn topics-dropdown-btn d-flex py-2 common-card-theme-bg custom-border font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                                                        type="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="true" disabled={!selectedBatch}
                                                                    >
                                                                        <span className="font-regular font-14 secondary-color">{SubBatchName || "Select"} </span>
                                                                        <span className="ps-1">
                                                                            <img src="images/down-arrow.svg" alt="dropdown-icon" />
                                                                        </span>
                                                                    </button>
                                                                    <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border font-semibold font-16 w-100 p-3 shadow">
                                                                        {/* PS_Tp_162-PS_Tp_165
                                                        when user select the value in the Sub batch drop down the selectedSubBatch,SubBatchName state variable get updated  */}
                                                                        {dropdownSubBatch?.map((subBatch) => (
                                                                            <span
                                                                                key={subBatch.sub_batch_id}
                                                                                className="d-flex list cursor-pointer p-2 primary-color"
                                                                                onClick={() => { setSelectedSubBatch(subBatch.sub_batch_id); setSelectedSubBatchName(subBatch.sub_batch_name) }}
                                                                            >
                                                                                {subBatch.sub_batch_name}
                                                                            </span>
                                                                        ))}

                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mb-2">
                                                            <label className="font-16 font-semibold custom-header-color primary-color">
                                                                Date
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-4">

                                                            <label
                                                                className="form-label font-14 font-semibold label-color primary-color"
                                                                htmlFor="from"
                                                            >
                                                                From
                                                            </label>
                                                            {/* PS_Tp_166-PS_Tp_167
                                                when user select from date the filterfromdate state variable get updated  */}
                                                            {/* <input
                                                                className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                                                type="date"
                                                                id="from"
                                                                placeholder="MM/DD/YYYY"
                                                                value={filterfromdate}
                                                                onChange={(event) => setFilterFromDate(event.target.value)}
                                                            /> */}
                                                           <input
                                                                className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                                                type="date"
                                                                id="from"
                                                                placeholder="MM/DD/YYYY"
                                                                value={filterfromdate}
                                                                onChange={(event) => {setFilterFromDate(event.target.value);setFilterToDate("")}}
                                                                // max={filtertodate}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <label
                                                                className="form-label font-14 font-semibold label-color primary-color"
                                                                htmlFor="to"
                                                            >
                                                                To
                                                            </label>
                                                            {/* PS_Tp_168-PS_Tp_169
                                                when user select to date filtertodate state variable get updated  */}
                                                            <input
                                                                className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                                                                type="date"
                                                                id="to"
                                                                placeholder="MM/DD/YYYY"
                                                                value={filtertodate}
                                                                min={filterfromdate}
                                                                onChange={(event) => setFilterToDate(event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="d-flex justify-content-end align-items-center py-3">
                                                                <button className="btn secondary-btn custom-border text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 custom-filter-button" onClick={handleCancel}>
                                                                    Cancel
                                                                </button>
                                                                <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center custom-filter-button" onClick={handleApplyFilter}>
                                                                    Apply
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>



                                                </Modal.Body>
                                            </Modal>
                                            <button className="btn primary-btn text-nowrap small-device-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={handleUploadClick}>
                                                <img src="images/upload-icon.svg" alt="upload-icon" />
                                                Upload
                                            </button>
                                        </div>
                                        {showUpload && <Upload setShowUpload={setShowUpload} dropclass={dropclass} dropdownbatch={dropdownbatch} dropSubBatch={dropSubBatch} onUploadSuccess={handleUploadSuccess} />}
                                        <div class="w-100">
                                            {trainingPlans.length > 0 && <TrainingGrid data={trainingPlans} searchValue={searchValue} filtervalue={filter} onLengthUpdate={calculateTotalLength} loadingFalse = {loadingFalse}  loadingTrue ={loadingTrue}/>}
                                            {segregrateBatch && <EmptyGrid message="Segregate Batch. Please try to assign batches before uploading the training plan." />}
                                            {uploadTraining && <EmptyGrid message="No training plan base uploaded! No knowledge base found at the moment." />}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>
            {hideToast ? <></> :
                <ToastComponent toastObj={toastProp} />
            }
        </>

    );
}

export default TrainingPlan;

