export const blobToAudioBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    } catch (e) {
      const errorData = {
        error_type: "review component",
        error_source: "blobToAudioBase64",
        error_description: e
      };
      // errorApi(errorData, '/insertErrorData');
    }
  });
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve({ base64Data, fileName: file.name, fileType: file.type });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      const errorData = {
        error_type: "review component",
        error_source: "fileToBase64",
        error_description: e
      };
      // errorApi(errorData, "/insertErrorData");
    }
  });
};

// Pseudo code number PC_LFS3_1.2
// Function to get Current Timestamp
export function getCurrentTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const date = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const currentTime = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
  return currentTime;
}

// Pseudo code number PC_LFS3_1.3
// Function to format date and time
export const formatDateTime = (dateTime) => {
  if (!dateTime) return "";
  const dateObj = new Date(dateTime);
  const options = {
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return dateObj.toLocaleString("en-US", options);
};


export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      resolve({ base64Data, fileName: file.name, fileType: file.type });
    };
    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};



export function extractNumberFromString(str) {
  var regex = /\d+/;
  var match = str.match(regex);
  return match ? Number(match[0]) : null;
}


export function extractString(inputString) {
  return inputString.replace(/[^a-zA-Z]/g, '');
}

export function getTimeDifferenceInSeconds(datetime1, datetime2) {
  const time1 = new Date(datetime1).getTime();
  const time2 = new Date(datetime2).getTime();

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(time1 - time2);

  // Ensure a minimum time difference of 6 seconds
  const minTimeDifference = 6 * 1000; // 6 seconds in milliseconds
  const adjustedTimeDifference = Math.max(timeDifference, minTimeDifference);

  // Convert milliseconds to seconds and return
  return Math.floor(adjustedTimeDifference / 1000);
}