import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getClass, getSubBatch, UploadTrainingPLan, validateUploadfile } from "../../../Service/AdminApi/trainingPlanApi";
import { useState,useEffect } from 'react';
import TrainingGrid from './TrainingGrid';
import { jwtDecode } from 'jwt-decode';



const Upload = ({ setShowUpload, dropclass, dropdownbatch, onUploadSuccess }) => {
    // PS_TU_11 define the state variable for TrainingPlanUpload component 
    const [selectedfilterClass, setSelectedfilterClass] = useState('Select');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [dropdownSubBatch, setDropdownSubBatch] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedsubBatch, setSelectedSubBatch] = useState('');
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(true);
    const [SelectSubBatchName, setSelectSubBatchName] = useState("");
    const [SelectedBatchName, setSelectedBatchName] = useState("");
    const [SelectedfilterClassName, SetSelectedfilterClassName] = useState("Select")
    const [uploadSuccess, setuploadSucess] = useState(false)
    const tempState = localStorage.getItem('jwt')
    const [state, setState] = useState(jwtDecode(tempState))
    // const[changeToast,]

    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
      };
    
      const [hideToast, setHideToast] = useState(true);
      const [toastProp, setToastProp] = useState(toastData);
    
      useEffect(() => {
        setTimeout(() => {
          setHideToast(true);
        }, 15000);
      }, [hideToast]);

    // PS_TU_22-PS_TU_25
    // when user select batch in the batch drop down selectedBatch,SelectedBatchName state variable updated 
    // call the dropSubBatch function and pass the selected batch id which fetch the subbatch based on the selected Batch 
    const handleBatchChange = (batch) => {
        setSelectedBatch(batch.batch_id);
        dropSubBatch(batch.batch_id);
        setSelectedBatchName(batch.batch_name)
    };

    // PS_TU_26-PS_TU_47
    // when user select the batch the dropSubBatch() is called
    // define the selected batch id and pass the body to the  getSubBatch() function and 
    // update the result to the dropdownSubBatch state variable 
    const dropSubBatch = async (selectedBatchId) => {
        try {
            const body = {
                Batchid: selectedBatchId,
            };
            const result = await getSubBatch(body);

            setDropdownSubBatch(result);
        } catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            console.error('Error fetching subbatch:', error);
        }
    };

    // PS_TU_50-PS_TU_81
    // check the file type is in xlsx format and call the readFileAsBase64() function and change the file in the Base64 format 
    // and define the body with value as base64,filename and filetype and send to the validateUploadfile(body) function 
    // from the backend it verify whether the xlsx file is the file for TrainingPLan upload 
    // if the response is  The file is not a Training Plan xlsx file the error message is show else the file is shown 
    const handleFileInputChange = async (event) => {
        try {
            const file = event.target.files[0];
            if (file) {
                if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.name.endsWith(".xlsx")) {
                    const fileData = await readFileAsBase64(file);
                    const body = {
                        xlsxfile: fileData.base64Data,
                        filetype: fileData.fileType,
                        fileName: fileData.fileName,
                    };
                    const result = await validateUploadfile(body);
                    if (result.message === 'The file is a Training Plan xlsx file.') {
                        setIsFileUploadOpen(false);
                        setSelectedFile(file);
                        setShowError(false)
                    }
                    else {
                        let errorMessage = " Please Upload the Valid Template";
                        setShowError(true);
                        setErrorMessage(errorMessage);
                    }

                }
                else {
                    let errorMessage = "Please Upload the Xlsx file only.";
                    setShowError(true);
                    setErrorMessage(errorMessage);
                }

            } else {
                console.error("No file selected");
            }
        } catch(error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage:  "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    };


    // PS_TU_87-PS_TU_137
    // when user click on the save Button in upload component 
    // check whether the user select the class batch and subbatch else show the error message 
    // the select file is Converted into base64 using the readFileAsBase64
    // and define the object and pass to UploadTrainingPLan() function if the response it Successful upload
    // the upload component get closed and the onUploadSuccess() function  is called back which render the TrainingPlan component 
    async function handleSave() {
        try {
            if (selectedBatch && selectedfilterClass !== 'Select' && selectedFile && selectedsubBatch) {
                setShowUpload(false);
                const fileData = await readFileAsBase64(selectedFile);
                const body = {
                    Base64file: fileData.base64Data,
                    filetype: fileData.fileType,
                    fileName: fileData.fileName,
                    batchId: selectedBatch,
                    subbatchId: selectedsubBatch,
                    classId: selectedfilterClass,
                    Createdby: state.userName
                };
                if (body.filetype !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    let errorMessage = "Please Upload the Excel file only.";
                    setShowError(true);
                    setErrorMessage(errorMessage);
                } else {
                    const result = await UploadTrainingPLan(body);
                    if (result.message === "Successful upload") {
                        setuploadSucess(true)
                        setShowUpload(false);
                        onUploadSuccess()
                    }
                }

            }

            else if (selectedBatch && selectedfilterClass !== 'Select' && selectedFile) {
                setShowUpload(false);
                const fileData = await readFileAsBase64(selectedFile);
                const body = {
                    Base64file: fileData.base64Data,
                    filetype: fileData.fileType,
                    fileName: fileData.fileName,
                    batchId: selectedBatch,
                    classId: selectedfilterClass,
                    Createdby: state.userName
                };

                if (body.filetype !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    let errorMessage = "Please Upload the Xlsx file only.";
                    setShowError(true);
                    setErrorMessage(errorMessage);
                } else {
                    //api upload success 
                    const result = await UploadTrainingPLan(body);

                    if (result.message === "Successful upload") {
                        setuploadSucess(true)
                        onUploadSuccess()
                    }
                }
            } 
            else {

                let errorMessage = 'Please select ';
                if (!selectedBatch) {
                    errorMessage += 'Batch, ';
                }
                if (selectedfilterClass === 'Select') {
                    errorMessage += 'Class, ';
                }
                if (!selectedFile) {
                    errorMessage += 'File';
                }
                if (!selectedBatch || selectedfilterClass === 'Select' || !selectedFile) {
                    errorMessage += ' before saving.';
                }
                setShowError(true);
                setErrorMessage(errorMessage);
            }
        } catch (error) {

            console.error("An error occurred:", error);
            setShowUpload(false);
        }
    }

    const changeToast = () => {
        setuploadSucess(false)
    }

    // PS_TU_89-PS_TU_100
    // this function is used to change the seleted file in the base64 format 
    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Define the onload event handler
            reader.onload = () => {
                const base64Data = reader.result.split(",")[1];
                resolve({ base64Data, fileName: file.name, fileType: file.type }); // Include file name and type in the resolved object
            };

            // Define the onerror event handler
            reader.onerror = (error) => {
                reject(error);
            };

            // Read the file as data URL
            reader.readAsDataURL(file);
        });
    };

    // PS_TU_126-PS_TU_136
    // this function is used to show the error message when the drop down value is not selected  
    const ErrorMessage = ({ errorMessage, showError }) => {
        if (!showError) {
            return null;
        }

        return (
            <span className="mt-2 font-regular font-14 mandatory d-flex align-items-center"><img src="images/error-msg.svg" alt="Error" className="me-2 mb-1" />{errorMessage}</span>
        );
    };

    // PS_TU_145-PS_TU_152
    // when user click on the download template the xlsx get downloaded 
    const downloadExcelTemplate = () => {
        const excelTemplateUrl = 'https://avaeuslfdblob.blob.core.windows.net/admin-upload/Training Plan Template.xlsx?sp=r&st=2024-04-30T12:04:12Z&se=2029-08-23T20:04:12Z&sv=2022-11-02&sr=b&sig=1t4L0v9hQrRRoDV%2FLpohfPNfFYpLFvbIBSbq8NwMr6g%3D';
        const link = document.createElement('a');
        link.href = excelTemplateUrl;
        link.setAttribute('download', 'excel-template.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // PS_TU_138-PS_TU_145
    // when user click on the cancel button the the handleClose function is called the the state variable is set into its default value
    const handleClose = () => {
        setSelectedfilterClass('Select'); // Reset Class dropdown
        SetSelectedfilterClassName('Select')
        setSelectedBatch(''); // Reset Batch dropdown
        setSelectedBatchName('')
        setDropdownSubBatch([]); // Reset Sub Batch dropdown
        setSelectSubBatchName("")
        setSelectedFile(null); // Reset selected file
        setIsFileUploadOpen(true)
    };

    return (
        <div className="modal-content body-bg custom-padding">
            <Modal show onHide={() => { setShowUpload(false) }} centered>
                <Modal.Header closeButton>
                <Modal.Title>
                            <h5 className='mb-0 d-flex align-items-center'>
                            <span className="upload-icon-style me-3">
                                <img src="images/upload-white-icon.svg" alt="upload-icon" className="custom-arrow-color"/></span>
                                <span className='font-22 font-semibold'>Upload</span></h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Class</label>
                            <div className="dropdown topics-dropdown w-100">
                                <span className="action-drop-down ">
                                    <button id="teams"
                                        className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                        <span className="font-medium font-14 primary-color">{SelectedfilterClassName} </span>
                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                    </button>
                                    <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                        {/* PS_TU_12-PS_TU_14
                                    map the Dropdown class and drop down Batch which was send as prop from the TrainingPlan component  */}
                                        {dropclass?.map((value) => (
                                            // PS_TU_17-PS_TU_19
                                            // when user select in the class drop down selectedfilterClass state variable get updated 
                                            <span key={value.class_id} className="d-flex list cursor-pointer p-2 primary-color" onClick={() => { setSelectedfilterClass(value.class_id); SetSelectedfilterClassName(value.class_name) }}>
                                                {value.class_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Batch name</label>
                            <div className="dropdown topics-dropdown w-100">
                                <span className="action-drop-down ">
                                    <button
                                        className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="true"
                                    >
                                        <span className="font-medium font-14 primary-color">{SelectedBatchName || 'Select'}</span>
                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                    </button>
                                    <span className="dropdown-menu common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">

                                        {dropdownbatch.map((batch) => (
                                            <span
                                                key={batch.batch_id}
                                                className="d-flex list cursor-pointer p-2 primary-color"
                                                onClick={() => handleBatchChange(batch)}  // Call handleBatchChange here
                                            >

                                                {batch.batch_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Subgroup</label>
                            <div className="dropdown topics-dropdown w-100">
                                <span className="action-drop-down ">
                                    <button
                                        className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="true"
                                    >
                                        <span className="font-medium font-14 primary-color">{SelectSubBatchName || "Select"}</span>
                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                    </button>
                                    <span className="dropdown-menu common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                        {dropdownSubBatch.map((subBatch) => (
                                            <span
                                                key={subBatch.sub_batch_id}
                                                className="d-flex list cursor-pointer p-2 primary-color"
                                                onClick={() => { setSelectedSubBatch(subBatch.sub_batch_id); setSelectSubBatchName(subBatch.sub_batch_name) }}
                                            >
                                                {subBatch.sub_batch_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                        </div>
                        {/* Other components */}
                    </div>

                    <div className="col-md-12 mb-4">
                        <label className="font-semibold font-16 mb-2 primary-color">Upload</label>

                        {isFileUploadOpen && (
                            <button className="upload-files-container p-4 w-100 border-0 common-bg  mb-2" onChange={handleFileInputChange} >
                                <label
                                    htmlFor="fileUpload"
                                    className="upload-btn-wrapper cursor-pointer upload-wrapper d-block text-center"
                                >
                                    <img
                                        src="images/upload-icon-black.svg"
                                        alt="dropfiles"
                                        className="drop-file text-center mb-3"
                                    />
                                    <span className="font-medium font-14 d-block text-center primary-color mb-1">
                                        Upload files from computer
                                    </span>
                                    <span className="font-regular font-12 d-block text-center position-relative secondary-color mb-2">
                                        or drag and drop here
                                    </span>
                                    <div className="file-input-wrapper cursor-pointer">
                                        <label
                                            htmlFor="fileToUpload"
                                            className="btn-upload link-text font-12 font-regular cursor-pointer text-underline"
                                            onClick={downloadExcelTemplate}
                                        >
                                            Download template
                                        </label>
                                    </div>
                                    <input id="fileUpload" type="file" className=" d-none "accept=".xls,.xlsx,application/vnd.ms-excel" />
                                </label>
                            </button>)}
                        {selectedFile && (
                            <>
                                <div className="upload-container common-card-theme-bg custom-border">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex gap-4">
                                            <img src="images/folder-icon-white.svg" alt="folder-icon" className="custom-arrow-color" />
                                            <div>
                                                <p className="font-medium font-14 primary-color mb-1">
                                                    {selectedFile.name}
                                                </p>
                                                <p className="font-regular font-12 secondary-color mb-0">
                                                    {(selectedFile.size / 1024).toFixed(1) + "KB"}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="images/popup-close-icon.svg" alt="delete-icon" className="close-icon cursor-pointer" onClick={() => { setSelectedFile(null); setIsFileUploadOpen(true) }} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <ErrorMessage errorMessage={errorMessage} showError={showError} />

                    <div className="modal-footer border-0 mt-2 mb-3 px-4 gap-2">
                        <button type="button" className="btn secondary-btn color-white custom-border font-20 font-semibold m-0" onClick={handleClose}>Cancel</button>
                        <button type="button" className="btn primary-btn font-20 font-semibold m-0" onClick={handleSave}>Save</button>
                    </div>
                    

                </Modal.Body>

            </Modal>
        </div>
    );
};

export default Upload;



