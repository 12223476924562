import {adminEndpoints} from '../../Constant/constant'
import {client} from '../../Client/client'

let knowledgeBaseEndpoints = adminEndpoints.knowledgeBaseEndpoints;


export const getComponent = async (body={}) =>{
    const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":knowledgeBaseEndpoints.getComponent,
        "request_data":body
      }
    let response = await client(knowledgeBaseEndpoints.getComponent,requestBody ,'POST','admin')
    return response
}

export const getClass = async (body={}) =>{
    const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":knowledgeBaseEndpoints.getClass,
        "request_data":body
    }
    let response = await client(knowledgeBaseEndpoints.getClass,requestBody ,'POST','admin')
    return response
}

export const deleteFiles = async (body={}) =>{
    const jwt = localStorage.getItem('jwt')
    const requestBody={
        "jwt":jwt,
        "endpoint":knowledgeBaseEndpoints.deleteFiles,
        "request_data":body
      }
    let response = await client(knowledgeBaseEndpoints.deleteFiles,requestBody ,'POST','admin')
    return response
}

export const getUpload = async (body ={}) => {
    const jwt = localStorage.getItem('jwt')
    console.log("upload API get upload ");
    const requestBody={
        "jwt":jwt,
        "endpoint":knowledgeBaseEndpoints.getUpload,
        "request_data":body
      }
    let response = await client(knowledgeBaseEndpoints.getUpload,requestBody ,'POST','admin')
    return response
}