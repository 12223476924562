import React, { useEffect, useState } from 'react'
import { getUserPreviewData } from '../../../Service/AdminApi/userProfileApi';
import 'react-h5-audio-player/lib/styles.css';
import VideoPlayer from './CustomAudio';
import { Modal } from 'react-bootstrap';
import { type } from '@testing-library/user-event/dist/type';
import Loader from '../TrainingPlan/loader';


const UserPreview = ({ trainingSessionId, userId, display, employee, showModal, setShowModal, onClose, profile }) => {
    const [UserReviewProfileData, setUserReviewProfileData] = useState([]);
    const [transcript, setTranscript] = useState([])
    const [search, setSearch] = useState("");
    const [audio, setaudio] = useState("")
    const [loader, setloader] = useState(false)

    const [playbackRate, setPlaybackRate] = useState(1);
    const [audiocurrentTime, setAudioCurrenttime] = useState(0)
    const [fileName, setFileName] = useState([])
    const [fileUrl, setFileUrl] = useState([])
    const [filesize, setFilesize] = useState([])
    const [audioPause, setAudioPause] = useState(false)
    const [review, setReview] = useState([]);
    const [video, setVideo] = useState("")
    const [duration, setDuration] = useState("")


    const [closeModalLocal, setCloseModalLocal] = useState(false)

    // Toast 
    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState(toastData);

    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 15000);
    }, [hideToast]);

    // PC_LFS_URF_4 - PC_LFS_URF_71 The fetchData function asynchronously retrieves user preview data from the server and updates state variables with the fetched information.
    const fetchData = async () => {
        setloader(true)
        try {
            let reqBody = {
                "training_session_id": trainingSessionId,
                "user_id": userId
            }
            const result = await getUserPreviewData(reqBody);
            // {result && setloader(false)}
            setUserReviewProfileData(result.data);
            setaudio(result.data[0].audio)
            setTranscript(result.data[0].transcript)
            setFileName(result.data[0].fileName)
            setFileUrl(result.data[0].file_url)
            setFilesize(result.data[0].fileSize)
            setVideo(result.data[0].video)
            setDuration(result.data[0].screen_duration)
            setReview(result.review[0]);
            setloader(false)
        } catch (error) {
            console.error(error);
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong!"
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    }

    useEffect(() => {
        if (display) {
            fetchData();
            setAudioPause(false)
        }
    }, [userId, trainingSessionId, audioPause]);

    // PC_LFS_URF_94 - PC_LFS_URF_96 The handlePlaybackRateChange function updates the playback rate based on the value provided by the user.This function takes an event object (e) as a parameter, extracts the playback rate value from it, and updates the playback rate state variable accordingly. It's commonly used in React applications to handle user input for adjusting playback speed. 
    const handlePlaybackRateChange = (e) => {
        const rate = parseFloat(e.target.value);
        setPlaybackRate(rate);
    };

    // PC_LFS_URF_78 - PC_LFS_URF_87 The fileButton function is used to render a file upload button or a link to a file depending on the message.Edit property. If message.Edit is false, indicating that the file is being uploaded, it displays a button with a link to download the file. If message.Edit is true, indicating that the file has already been uploaded, it displays a button with the file name.
    // Here's a breakdown of the function:
    // It takes message as a parameter, which contains information about the file.
    // It increments the filecount variable to keep track of the number of files rendered.
    // It renders a container with the file icon and file information.
    // If message.Edit is false, it renders a button with a link to download the file using the fileUrl and fileName properties.
    // If message.Edit is true, it renders a button with the file name from the message.fileName property.
    let filecount = -1;
    const fileButton = () => {
        filecount++;
        let parsedArray = JSON.parse(fileName);
        let urlArray = JSON.parse(fileUrl)
        let filesizeArray;
        if (filesize !== null || filesize !== undefined) {
            filesizeArray = JSON.parse(filesize);
        }
        return (
            <div className="upload-container custom-border rounded-2 px-3 py-2">
                <div className="d-flex justify-content-between align-items-start gap-3">
                    <div className="d-flex gap-4">
                        <img src="images/file-icon.svg" alt="file-icon" />
                        <div className="d-inline-block text-start">
                            <p className="font-medium font-14 primary-color mb-1 text-truncate">
                                <a href={urlArray[filecount]} download={parsedArray[filecount]}>
                                    <button className="button p-0 filebutton bg-transparent border-0 primary-color">{parsedArray[filecount]}</button>
                                </a>
                            </p>
                            <p className="font-regular font-12 secondary-color mb-0">{filesizeArray[filecount]}</p>
                        </div>
                    </div>
                </div>
            </div>);
    };

    //PC_LFS_URF_88 - PC_LFS_URF_93 The showTranscript function renders a transcript based on filtering criteria and user search input. It maps through the transcript array, filtering items based on search criteria (question or answer containing the search term).For each filtered item, it renders a chat bubble displaying the sender's name, timestamp, and either a question or an answer.If the index is even, it displays a default logo; otherwise, it shows a sample profile picture.Timestamps are displayed with a custom badge.Answers containing a placeholder <%sampleFile.text%> trigger a file button.This function is suitable for rendering chat transcripts with dynamic filtering and content display.

    const showTranscript = () => {
        const filteredTranscript = transcript?.filter((item) => {
            if (
                search === "" ||
                (item.question && item.question.toLowerCase().includes(search.toLowerCase())) ||
                (item.answer && item.answer.toLowerCase().includes(search.toLowerCase()))
            ) {
                return true;
            }
            return false;
        });

        return (
            <>
                {filteredTranscript?.map((element, index) => (
                    <div key={index} className="d-flex align-items-start mb-4">
                        {element.question ? (
                            <>
                                <span className="chat-lfs-big-icon">
                                    <img className="voice-icon-image" src="images/Sid.svg" alt="voice-icon" />
                                </span>
                                <div className="ms-3 w-100">
                                    <p className="mb-0 d-flex justify-content-between align-items-center">
                                        <span className="font-regular font-16 secondary-color mb-2">Sid</span>
                                        <span className="custom-badge custom-badge-violet py-0 px-1 font-12 font-regular">
                                            {element.time}
                                        </span>
                                    </p>
                                    <p className="mb-0 font-regular font-16 chat-content primary-color">
                                        {element.question}
                                    </p>
                                </div>
                            </>
                        ) : (
                            element.answer && (
                                <>
                                    {profile ? <img
                                        src={profile}
                                        alt="Profile-picture"
                                        className="profile-img circular-image"
                                        width="42" height="42"
                                    /> : <img
                                        src="images/sample-profile-chat.svg"
                                        alt="Profile-picture"
                                        className="profile-img"
                                    />}
                                    <div className="ms-3 w-100">
                                        <p className="mb-0 d-flex w-100 justify-content-between align-items-center">
                                            <span className="font-regular font-16 secondary-color mb-2">{employee}</span>
                                            <span className="custom-badge custom-badge-violet py-0 px-1 font-12 font-regular">
                                                {element.time}
                                            </span>
                                        </p>
                                        <p className="mb-0 font-regular font-16 chat-content primary-color">
                                            {element.answer === "<%sampleFile.text%>" ? fileButton() : element.answer}
                                        </p>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                ))}
            </>
        );
    };

    // The showAudio function renders an AudioPlayer component with customized controls and playback options.It utilizes the AudioPlayer component to render an audio player.The src prop is set to the audio state variable.Custom icons for forward and rewind controls are provided.progressJumpSteps define the seek duration when using forward and backward controls.Custom additional controls include a playback rate selector.The playback rate is managed by the playbackRate state variable, and changes trigger the handlePlaybackRateChange function.An onPlay event handler is included for logging play events.

    const getBadgeColor = (grade) => {
        switch (grade) {
            case 'A+':
                return 'custom-grade-badge custom-badge-green';
            case 'A-':
                return 'custom-grade-badge custom-badge-green';
            case 'B+':
                return 'custom-grade-badge custom-badge-orange';
            case 'B-':
                return 'custom-grade-badge custom-badge-orange';
            case 'C+':
                return 'custom-grade-badge custom-badge-red';
            case 'C-':
                return 'custom-grade-badge custom-badge-red';
            default:
                return 'custom-grade-badge custom-badge-gray';
        }
    }

    return (
        <>
            <div className={`modal fade ${showModal ? 'show' : ''}`} id="upload" hidden={!showModal} tabIndex={-1} style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog kb-upload-popup modal-xl px-3 py-3"> {/* // Changed modal-lg to modal-xl */}
                    <div className="modal-content body-bg border-0">
                        {/* Modal content */}
                        {loader ? <Loader /> :
                            <div>
                                <div className="modal-header border-0 px-4 pt-4 d-flex align-items-center">
                                    <h5 className="modal-title primary-color font-semibold font-22">Preview</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { onClose(); setAudioPause(true) }} aria-label="Close">
                                        <img src="images/popup-close-icon.svg" alt="close-icon" />
                                    </button>
                                </div>
                                <div className="modal-body border-0 px-4 pt-3 pb-4 font-16"> {/* // Added pb-4 */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-12"> {/* // Added column layout */}
                                                    {UserReviewProfileData?.map((item, index) => (
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 mb-4">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <span className="font-16 font-regular secondary-color pe-4">
                                                                            Topic
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-auto" key={index}>
                                                                        <span className="font-16 font-regular primary-color">
                                                                            {item.training_topic}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-4">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <span className="font-16 font-regular secondary-color pe-4">
                                                                            Class
                                                                        </span>
                                                                    </div>

                                                                    <div className="col-auto" key={index}>
                                                                        <span className="font-16 font-regular primary-color">
                                                                            {item.class}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-4">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <span className="font-16 font-regular secondary-color pe-4">
                                                                            Date
                                                                        </span>
                                                                    </div>

                                                                    <div className="col-auto" key={index}>
                                                                        <span className="font-16 font-regular primary-color">
                                                                            {new Date(item.review_date).toLocaleDateString('en-US', {
                                                                                day: '2-digit',
                                                                                month: 'short',
                                                                                year: 'numeric'
                                                                            })}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-4">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <span className="font-16 font-regular secondary-color pe-4">
                                                                            Grade
                                                                        </span>
                                                                    </div>


                                                                    <div className="col-auto" key={index}>
                                                                        <span className={getBadgeColor(item.review_grade)}>
                                                                            {item.review_grade}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div className="col-md-12">
                                                        <h5 className="modal-title primary-color font-semibold font-18 mb-4 pb-2">Recording</h5>
                                                        <div className="video-player-container position-relative">
                                                            <VideoPlayer className="w-100 h-100" blobUrl={video} screenDuration={duration}></VideoPlayer>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-12">
                                                    <div className="custom-tabs d-none d-md-block mt-3 ">
                                                        <ul
                                                            className="nav nav-pills d-flex gap-5  mb-4 custom-tab-border-bottom"
                                                            id="pills-tab"
                                                            role="tablist"
                                                        >
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="m-0 font-16 primary-text-v2 font-medium cus-tab-link cus-tab-border  border-0 shadow-none px-0 active"
                                                                    id="Transcription-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#Transcription"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="Transcription"
                                                                    aria-selected="false"
                                                                    tabIndex={-1}
                                                                >
                                                                    Transcription
                                                                </button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className=" m-0 font-16 primary-text-v2 font-medium cus-tab-link cus-tab-border  border-0 shadow-none px-0 color-grey"
                                                                    id="Feedback-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#Feedback"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="Feedback"
                                                                    aria-selected="false"
                                                                    tabIndex={-1}
                                                                >
                                                                    Feedback
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-content" id="pills-tabContent">
                                                        {/*  */}
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="Transcription"
                                                            role="tabpanel"
                                                            aria-labelledby="Transcription-tab"
                                                        >
                                                            <div className="input-group input-search rounded-3">
                                                                <button
                                                                    className="btn border-end-0 custom-form custom-search "
                                                                    type="button"
                                                                >
                                                                    <img className="mb-1 " src="images/search-icon.svg" alt="search" />
                                                                </button>
                                                                <input
                                                                    type="search"
                                                                    className="form-control cust-search-input  border-end-0 font-14 font-regular custom-search dropdown-text-color body-bg"
                                                                    placeholder="Search"
                                                                    onChange={(event) => setSearch(event.target.value)}
                                                                />
                                                            </div>
                                                            <div className="chat-container-scroll-2 mt-4">
                                                                {showTranscript()}
                                                            </div>
                                                        </div>
                                                        {/* feedback */}
                                                        <div
                                                            className="tab-pane fade"
                                                            id="Feedback"
                                                            role="tabpanel"
                                                            aria-labelledby="Feedback-tab"
                                                        >
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="Feedback"
                                                                role="tabpanel"
                                                                aria-labelledby="Feedback-tab"
                                                            >
                                                                {review && (
                                                                    <div className="chat-container-scroll-2 mt-4">
                                                                        <p className="mb-3 font-regular font-16 primary-color">
                                                                            <span className="font-medium primary-text-v2">Attitude :</span>{" "}
                                                                            {review.attitude_feedback}
                                                                        </p>
                                                                        <p className="mb-3 font-regular font-16 primary-color">
                                                                            <span className="font-medium primary-text-v2">Communication :</span>
                                                                            {review.communication_feedback}
                                                                        </p>
                                                                        <p className="mb-3 font-regular font-16 primary-color">
                                                                            <span className="font-medium primary-text-v2">Business :</span>{" "}
                                                                            {review.business_feedback}
                                                                        </p>
                                                                        <p className="mb-0 font-regular font-16 primary-color">
                                                                            <span className="font-medium primary-text-v2">Technology :</span>{" "}
                                                                            {review.technology_feedback}
                                                                        </p>
                                                                    </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserPreview;