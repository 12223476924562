import React from 'react';
const Loader = () => (
    <div className="container-fluid common-bg">
        <div className="d-flex flex-column align-items-center justify-content-center loader-screen-height common-bg">
            <div className="container mb-4 common-bg">
                <div className="half"></div>
                <div className="half"></div>
            </div>
            <p className="font-medium font-18 text-center primary-color">Loading...</p>
        </div>
    </div>
);
export default Loader;