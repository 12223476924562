import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Player,
  ControlBar,
  PlayToggle,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
  ProgressControl,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  LoadingSpinner
} from 'video-react';
import 'video-react/dist/video-react.css';

const VideoPlayer = React.memo(({ blobUrl, duration }) => {
  const [player, setPlayer] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const playerRef = useRef(null);

  const handleStateChange = useCallback((state) => {
    // Check if the video is loaded and ready
    if (state.readyState >= 4 && state.buffered.length > 0) {
      setIsLoaded(true);
    }
  }, []);

  const handleReady = useCallback((player) => {
    setPlayer(player);
    player.load();
  }, []);

  useEffect(() => {
    if (player) {
      player.subscribeToStateChange(handleStateChange);
    }
    return () => {
      if (player) {
        player.unsubscribeToStateChange(handleStateChange);
      }
    };
  }, [player, handleStateChange]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .video-react .video-react-load-progress {
        background: rgba(150, 75, 200, 0.5);
      }
      .video-react .video-react-play-progress {
        background: #964BC8;
      }
      .video-react .video-react-big-play-button {
        display: none;
      }
      .video-react .video-react-play-progress:after {
        color: #964BC8;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleCanPlay = () => {
    setIsLoaded(true)
  };

  if (!blobUrl) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      {!isLoaded && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1
        }}>
          <div className="container-fluid">
            <div className="d-flex flex-column align-items-center justify-content-center loader-screen-height">
              <div className="container mb-4">
                <div className="half"></div>
                <div className="half"></div>
              </div>
              <p style={{ color: 'white', fontSize: '14px', textAlign: 'center' }} className="font-medium">Loading...</p>
            </div>
          </div>
        </div>
      )}
      <Player
        ref={playerRef}
        onReady={handleReady}
        onCanPlay={handleCanPlay}
        src={blobUrl}
        fluid={true}
        preload="auto"
      >
        <LoadingSpinner />
        <ControlBar autoHide={false}>
          <PlayToggle />
          <ReplayControl seconds={10} order={1.1} />
          <ForwardControl seconds={10} order={1.2} />
          <ProgressControl order={5.0} />
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <DurationDisplay order={4.3} />
          <PlaybackRateMenuButton rates={[1, 1.5, 2]} order={7.1} />
          <VolumeMenuButton />
        </ControlBar>
      </Player>
    </div>
  );
});

export default VideoPlayer;